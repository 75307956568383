import React from 'react';

import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { LoyaltyPointsButton } from 'components/loyalty-points-button';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
export const MobileHeaderCta = () => {
  const { formatMessage } = useIntl();

  const enableSignUp = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
  const { openSignInModal, isAuthenticated } = useAuthContext();
  const onPress = () => openSignInModal();

  if (isAuthenticated) {
    return (
      <>
        <LoyaltyPointsButton />
      </>
    );
  }

  if (!enableSignUp) {
    return null;
  }

  return (
    <ActionButton
      variant={ActionButtonVariants.PRIMARY}
      size={ActionButtonSizes.SMALL}
      onPress={onPress}
      testID="mobile-nav-signup-link"
      alignSelf="center"
    >
      {formatMessage({ id: 'signInUp' })}
    </ActionButton>
  );
};
