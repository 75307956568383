import { useCallback, useEffect, useMemo, useState } from 'react';

import Braze from 'utils/braze';
import noop from 'utils/noop';

import { IBrazeContext } from '../types';

const useBrazeContext = () => {
  const [isInAppMessageOpen, setIsInAppMessageOpen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleContentCards = useCallback<IBrazeContext['toggleContentCards']>(() => {
    Braze.launchContentCards();
  }, []);

  const setUserId = useCallback<IBrazeContext['setUserId']>(userId => {
    if (userId) {
      Braze.changeUser(userId);
    }
    return Braze.requestContentCardsRefresh();
  }, []);

  const getCachedContentCards = useCallback<IBrazeContext['getCachedContentCards']>(async () => {
    return Braze.getContentCards();
  }, []);

  useEffect(() => {
    return () => {
      // braze requests are batched and flushed.
      // if the app is unmounting we should immediately
      // send anything in the queue
      Braze.requestImmediateDataFlush();
    };
  }, []);

  const ctxValue: IBrazeContext = useMemo(
    () => ({
      initBraze: noop,
      getCachedContentCards,
      setUserId,
      toggleContentCards,
      isInAppMessageOpen,
      setIsInAppMessageOpen,
    }),
    [getCachedContentCards, isInAppMessageOpen, setUserId, toggleContentCards]
  );

  return ctxValue;
};

export default useBrazeContext;
