import CardValidator from 'card-validator';
import { differenceInCalendarMonths } from 'date-fns';
import { IntlFormatters } from 'react-intl';

import { isCreditCardPaymentMethod } from 'components/payment-method-option/utils';
import { CartPaymentCardType, LoyaltyPaymentMethod } from 'generated/graphql-gateway';
import {
  PaymentFieldVariations,
  defaultPaymentFieldVariation,
} from 'state/launchdarkly/variations';
import { IUseCartTip, TipAmounts } from 'state/order/hooks/use-cart-tip';
import { CardType, ICredit, IPaymentMethod, IRevaultInformation } from 'state/payment/types';
import { ISOs } from 'utils/form/constants';
import { IPlaceAddress } from 'utils/geolocation/types';
import { isApplePay, isCash, isGooglePay, isPayPal, isVenmo } from 'utils/payment/native-payment';

import {
  ALL_COUNTRIES_ZIP_CODE_REGEX,
  COUNTRY_BASED_ZIP_CODE_REGEX,
  sanitizeNumber,
} from '../form';

import { DEFAULT_BILLING_COUNTRY } from './default-billing-country';
import { getBillingCountryError } from './get-billing-country-error';

type CCFormAddress = Pick<
  IPaymentState,
  'billingStreetAddress' | 'billingApt' | 'billingCity' | 'billingState' | 'billingZip'
>;

export interface IPaymentErrors {
  nameOnCard: string;
  cardNumber: string;
  cardType: string;
  cvv: string;
  expiry: string;
  billingStreetAddress: string;
  billingApt: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
  billingCountry?: string;
  giftCardNumber?: string;
  didAttemptSubmit?: boolean;
}

export interface IPaymentState {
  nameOnCard: string;
  cardNumber: string;
  isCardNumberValid: boolean;
  isExpiryValid: boolean;
  cardType: CartPaymentCardType | null;
  cvv: string | null;
  expiry: string | null;
  billingStreetAddress: string | null;
  billingApt: string | null;
  billingCity: string | null;
  billingState: string | null;
  billingZip: string;
  billingCountry: ISOs | null;
  billingAddressSameAsDelivery: boolean;
  saveCard: boolean;
}

export interface IGiftCardPaymentErrors {
  cardNumber: string;
}

export interface IGiftCardPaymentState {
  cardNumber: string;
  isCardNumberValid: boolean;
  saveCard: boolean;
}

/**
 * "Payment Processor" billing address.
 *
 * Note: There is no such thing as a payment processor billing
 * address. Check the FirstData and payment gateway
 * spec to ensure format is compliant.
 *
 * https://firstdatanp-ucomgateway.apigee.io/apis/ucomaccountservices/index
 *
 */
export type IPaymentProcessorBillingAddress = {
  unitNumber: string | null;
  locality: string | null;
  postalCode: string;
  region: string | null;
  streetAddress: string | null;
  country: ISOs | null;
};

export interface ICCExpiry {
  month: string;
  year: string;
}

export interface IPaymentPayload {
  billingAddress: IPaymentProcessorBillingAddress;
  cardNumber: string;
  cardType: string;
  expiryDate: ICCExpiry | null;
  nameOnCard: string;
  securityCode: string | null;
  accountToDelete?: string;
}

export interface IFraudPreventionValues {
  billingAddress: IPaymentProcessorBillingAddress;
  fullName: string;
  ccBin?: string | null;
  ccLast4?: string | null;
  expiry: string | null;
}

enum FormFields {
  CARD_NUMBER = 'cardNumber',
  EXPIRY = 'expiry',
  BILLING_ZIP = 'billingZip',
  ADDRESS_SAME_AS_DELIVERY = 'billingAddressSameAsDelivery',
}

const visaRegEx: RegExp = /^4[0-9]{12}(?:[0-9]{3})?$/;
const mastercardRegEx: RegExp = /^(5[1-5][0-9]{14}|2[2-7][0-9]{14})$/;
export const amexpRegEx: RegExp = /^3[47][0-9]{13}$/;
const discoverRegEx: RegExp = /^6(?:(011|5[0-9]{2}))[0-9]{12}|62[0-9]{14}$/;
const jcbRegex: RegExp = /^(?:2131|2100|1800|35\d{3})\d{11}$/;
const dinersclubRegex: RegExp = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;

//per: https://github.com/braintree/credit-card-type#api
const mapValidatorResultToAppCardTypes = {
  'american-express': CartPaymentCardType.AMEX,
  'diners-club': CartPaymentCardType.DINERS_CLUB,
  discover: CartPaymentCardType.DISCOVER,
  jcb: CartPaymentCardType.JCB,
  mastercard: CartPaymentCardType.MASTERCARD,
  visa: CartPaymentCardType.VISA,
  // our app and LD flags for accepted payment types seems to consider discover and union pay the same thing so do this mapping
  unionpay: CartPaymentCardType.DISCOVER,
};

const isValidAppCardType = (
  key: string | undefined
): key is keyof typeof mapValidatorResultToAppCardTypes =>
  !!key && Object.keys(mapValidatorResultToAppCardTypes).includes(key);

export function validateCreditCardNumber(
  cardNumber: string,
  supportedCardTypes: CardType[],
  validateCCInClientExperiment?: boolean
): { isValid: boolean; cardType?: CartPaymentCardType | null; niceCardType?: string } {
  if (validateCCInClientExperiment) {
    const result = CardValidator.number(cardNumber);
    const cardType = isValidAppCardType(result?.card?.type)
      ? mapValidatorResultToAppCardTypes[result.card.type] ?? null
      : null;

    const resp = {
      isValid: Boolean(cardType && supportedCardTypes.includes(cardType) && result.isValid),
      cardType,
      niceCardType: result?.card?.niceType,
    };

    return resp;
  }

  let cardType;

  if (visaRegEx.test(cardNumber)) {
    cardType = CartPaymentCardType.VISA;
  } else if (mastercardRegEx.test(cardNumber)) {
    cardType = CartPaymentCardType.MASTERCARD;
  } else if (amexpRegEx.test(cardNumber)) {
    cardType = CartPaymentCardType.AMEX;
  } else if (discoverRegEx.test(cardNumber)) {
    cardType = CartPaymentCardType.DISCOVER;
  } else if (jcbRegex.test(cardNumber.replace(/\s/g, ''))) {
    cardType = CartPaymentCardType.JCB;
  } else if (dinersclubRegex.test(cardNumber.replace(/\s/g, ''))) {
    cardType = CartPaymentCardType.DINERS_CLUB;
  }

  return { isValid: Boolean(cardType && supportedCardTypes.includes(cardType)), cardType };
}

export function isGiftCardNumberValid(cardNumber: string): boolean {
  return /^[0-9]{16}$/.test(cardNumber);
}

export const splitExpiry = (expiry: string) => {
  const [expiryMonth = '', expiryYear = '']: string[] = expiry.split('/');

  return {
    expiryMonth,
    expiryYear,
  };
};

export function validateExpiry(expiryMonthAndYear: string): boolean {
  const { expiryMonth, expiryYear } = splitExpiry(expiryMonthAndYear);
  const monthNumber = Number(expiryMonth);
  if (monthNumber < 1 || monthNumber > 12) {
    return false;
  }
  const now: Date = new Date();
  const expiry: Date = new Date(Number(`20${expiryYear}`), monthNumber - 1);
  return differenceInCalendarMonths(expiry, now) >= 0;
}

export function excludeNumeric(string: string): string {
  return string.replace(/^\d$/g, '').trim();
}

export const onGiftCardFormChange = (
  name: string,
  value: string,
  state: IGiftCardPaymentState,
  formErrors: IPaymentErrors
): { state: IGiftCardPaymentState; formErrors: IPaymentErrors } => {
  if (name === FormFields.CARD_NUMBER) {
    const isValid: boolean = isGiftCardNumberValid(sanitizeNumber(value));

    formErrors.cardNumber = '';

    state.cardNumber = sanitizeNumber(value);
    state.isCardNumberValid = isValid;
  }

  return { state, formErrors };
};

export const getGiftCardFormErrors = (
  state: IGiftCardPaymentState,
  formErrors: IGiftCardPaymentErrors,
  formatMessage: IntlFormatters['formatMessage']
): { hasErrors: boolean; formErrors: IGiftCardPaymentErrors } => {
  if (!state.isCardNumberValid) {
    formErrors.cardNumber = formatMessage({ id: 'giftCardNumberIsNotValid' });
  }
  if (!state.cardNumber.trim()) {
    formErrors.cardNumber = formatMessage({ id: 'giftCardNumberIsRequired' });
  }

  const hasErrors: boolean = Object.values(formErrors).some(err => err);

  return { hasErrors, formErrors };
};

export const onCCFormChange = (
  name: string,
  value: string,
  incomingState: IPaymentState,
  formErrors: IPaymentErrors,
  formatMessage: IntlFormatters['formatMessage'],
  supportedCardTypes: CardType[],
  validateCCInClientExperiment?: boolean
): { state: IPaymentState; formErrors: IPaymentErrors } => {
  // Make a copy to avoid mutating react state / the original argument
  const state = { ...incomingState };

  if (name === FormFields.CARD_NUMBER) {
    const sanitizedNumber = sanitizeNumber(value);

    const { cardType, isValid, niceCardType } = validateCreditCardNumber(
      sanitizedNumber,
      supportedCardTypes,
      validateCCInClientExperiment
    );

    formErrors.cardType = '';

    if (validateCCInClientExperiment) {
      if (!cardType && niceCardType) {
        // support saying invalid card type based on libraries knowledge of many unsupported card types
        formErrors.cardType = formatMessage(
          { id: 'unsupportedCardType' },
          { cardType: niceCardType }
        );
      }
    } else {
      if (cardType && !isValid) {
        // keep existing functionality the same
        formErrors.cardType = formatMessage({ id: 'unsupportedCardType' }, { cardType });
      }
    }

    if (isValid) {
      formErrors.cardNumber = '';
    }

    state.cardNumber = sanitizedNumber;
    state.cardType = cardType ?? null;
    state.isCardNumberValid = isValid;
  } else if (name === FormFields.EXPIRY) {
    formErrors.expiry = '';
    state.expiry = value;
    state.isExpiryValid = validateExpiry(value);
  } else if (name === FormFields.BILLING_ZIP) {
    formErrors.billingZip = '';
    state.billingZip = value.toUpperCase();
  } else if (name === FormFields.ADDRESS_SAME_AS_DELIVERY) {
    state.billingAddressSameAsDelivery = !state.billingAddressSameAsDelivery;
    if (!state.billingAddressSameAsDelivery) {
      state.billingStreetAddress = '';
      state.billingApt = '';
      state.billingCity = '';
      state.billingState = '';
      state.billingZip = '';
    } else {
      formErrors.billingZip = '';
    }
  } else {
    if (typeof state[name] === 'boolean') {
      state[name] = !state[name];
    } else {
      formErrors[name] = '';
      state[name] = value;
    }
  }
  return { state, formErrors };
};

const validateCardZipCode = (
  zipCode: string,
  country: string,
  formatMessage: IntlFormatters['formatMessage'],
  countryFieldEnabled: boolean
) => {
  let cardZipCodeError;
  const regex = countryFieldEnabled
    ? COUNTRY_BASED_ZIP_CODE_REGEX[country]
    : ALL_COUNTRIES_ZIP_CODE_REGEX;

  if (zipCode && country && !regex.test(zipCode)) {
    cardZipCodeError = formatMessage({
      id: country === ISOs.USA ? 'zipCodeInvalid' : 'postalCodeInvalid',
    });
  }

  return cardZipCodeError;
};

export const getCCFormErrors = (
  state: IPaymentState,
  formErrors: IPaymentErrors,
  formatMessage: IntlFormatters['formatMessage'],
  paymentFieldVariations: PaymentFieldVariations = defaultPaymentFieldVariation,
  country: ISOs
): { hasErrors: boolean; formErrors: IPaymentErrors } => {
  if (!state.isCardNumberValid) {
    formErrors.cardNumber = formatMessage({ id: 'ccNumberIsNotValid' });
  }
  if (!state.cvv || state.cvv.trim().length < 3) {
    formErrors.cvv = formatMessage({ id: 'cvvMustBeAtLeast3Digits' });
  }
  if (!state.isExpiryValid) {
    formErrors.expiry = formatMessage({ id: 'creditCardExpired' });
  }
  if (!state.expiry?.trim()) {
    formErrors.expiry = formatMessage({ id: 'expirationDateIsRequired' });
  }
  if (paymentFieldVariations.name && !state.nameOnCard?.trim()) {
    formErrors.nameOnCard = formatMessage({ id: 'nameOnCardIsRequired' });
  }
  if (!state.cardNumber.trim()) {
    formErrors.cardNumber = formatMessage({ id: 'cardNumberIsRequired' });
  }
  if (paymentFieldVariations.cvv && !state.cvv?.trim()) {
    formErrors.cvv = formatMessage({ id: 'cvvIsRequired' });
  }
  if (paymentFieldVariations.addressLine1 && !state.billingStreetAddress?.trim()) {
    formErrors.billingStreetAddress = formatMessage({ id: 'addressRequiredError' });
  }
  if (paymentFieldVariations.city && !state.billingCity?.trim()) {
    formErrors.billingCity = formatMessage({ id: 'cityRequiredError' });
  }
  if (paymentFieldVariations.state && !state.billingState?.trim()) {
    formErrors.billingState = formatMessage({ id: 'stateIsARequiredField' });
  }
  if (paymentFieldVariations.zip) {
    const billingZip = state.billingZip.trim();
    const countryToValidateBy = paymentFieldVariations.country ? state.billingCountry : country;
    // Validate zip code based on country if country input is displayed
    if (billingZip && countryToValidateBy) {
      const billingZipError = validateCardZipCode(
        state.billingZip,
        countryToValidateBy,
        formatMessage,
        paymentFieldVariations.country
      );
      if (billingZipError) {
        formErrors.billingZip = billingZipError;
      }
    }

    if (!billingZip) {
      formErrors.billingZip = formatMessage({
        id: countryToValidateBy === ISOs.USA ? 'zipCodeRequiredError' : 'postalCodeRequiredError',
      });
    }
  }
  if (paymentFieldVariations.country && !state.billingCountry) {
    formErrors.billingCountry = getBillingCountryError(formatMessage);
  }
  // set didAttemptSubmit as false since we are using errors for submit error tracking too
  const hasErrors: boolean = Object.values({ ...formErrors, didAttemptSubmit: false }).some(
    err => err
  );

  return { hasErrors, formErrors };
};

export const initialPaymentState = ({
  isDelivery = false,
  deliveryAddress = null,
  billingCountry = DEFAULT_BILLING_COUNTRY,
  userDetailsName = '',
}: {
  isDelivery?: boolean;
  billingCountry?: ISOs;
  deliveryAddress?: IPlaceAddress | null;
  userDetailsName?: string;
} = {}): IPaymentState => ({
  nameOnCard: userDetailsName ?? '',
  cardNumber: '',
  isCardNumberValid: false,
  isExpiryValid: false,
  cardType: null,
  cvv: '',
  expiry: '',
  ...mapDeliveryToCCFormAddress(isDelivery ? deliveryAddress : null),
  billingAddressSameAsDelivery: isDelivery,
  saveCard: true,
  billingCountry,
});

export const initialGiftCardPaymentState = (): IGiftCardPaymentState => ({
  cardNumber: '',
  isCardNumberValid: false,
  saveCard: true,
});

export const testCardPaymentState = ({
  isDelivery = false,
  paymentFieldVariations = defaultPaymentFieldVariation,
}: {
  isDelivery?: boolean;
  paymentFieldVariations?: PaymentFieldVariations;
  paymentProcessor?: string | null;
} = {}): IPaymentState => {
  return {
    nameOnCard: 'John Smith',
    cardNumber: '4111111111111111',
    isCardNumberValid: true,
    isExpiryValid: true,
    ...mapDeliveryToCCFormAddress({
      addressLine1: '100 Universal City Plaza',
      addressLine2: '',
      city: 'Hollywood',
      state: 'CA',
      zip: '11747',
    }),
    cardType: CartPaymentCardType.VISA,
    cvv: paymentFieldVariations.cvv ? '123' : '',
    expiry: paymentFieldVariations.expiration ? '12/25' : '',
    billingCountry: paymentFieldVariations.country ? ISOs.US : null,
    billingAddressSameAsDelivery: isDelivery,
    saveCard: true,
  };
};

export const initialErrorState = (): IPaymentErrors => ({
  nameOnCard: '',
  cardNumber: '',
  cardType: '',
  cvv: '',
  expiry: '',
  billingStreetAddress: '',
  billingCity: '',
  billingState: '',
  billingZip: '',
  billingCountry: '',
  billingApt: '',
  giftCardNumber: '',
  didAttemptSubmit: false,
});

export const getFraudPreventionValues = ({
  cardNumber,
  nameOnCard,
  billingStreetAddress,
  billingApt,
  billingCity,
  billingState,
  billingZip,
  billingCountry,
  expiry,
}: IPaymentState) => {
  const ccBin: string | undefined = cardNumber ? cardNumber.slice(0, 6) : undefined;
  const ccLast4: string | undefined = cardNumber
    ? cardNumber.slice(-4, cardNumber.length)
    : undefined;

  return {
    billingAddress: {
      country: billingCountry,
      unitNumber: billingApt,
      locality: billingCity,
      postalCode: billingZip,
      region: billingState,
      streetAddress: billingStreetAddress,
    },
    fullName: nameOnCard,
    ccBin,
    ccLast4,
    expiry,
  };
};

export const mapDeliveryToCCFormAddress = (
  deliveryAddress: Partial<IPlaceAddress> | null
): CCFormAddress => ({
  billingStreetAddress: deliveryAddress?.addressLine1 || '',
  billingApt: deliveryAddress?.addressLine2 || '',
  billingCity: deliveryAddress?.city || '',
  billingState: deliveryAddress?.state || '',
  billingZip: deliveryAddress?.zip || '',
});

/**
 * This function check whether the selected payment method needs to be re-vaulted (default: false)
 */
export const shouldReVault = ({
  accountIdentifier,
  isOrbitalPaymentEnabled,
  methods,
}: {
  accountIdentifier: string;
  isOrbitalPaymentEnabled: boolean;
  methods: IPaymentMethod[];
}): IRevaultInformation => {
  // Get the clicked payment method object
  const clickedPaymentMethod = methods.find(method => {
    const selectedAccountIdentifier = method.accountIdentifier ?? method.fdAccountId ?? '';
    return selectedAccountIdentifier === accountIdentifier;
  });
  const isDigitalPayment =
    isApplePay(accountIdentifier) ||
    isGooglePay(accountIdentifier) ||
    isPayPal(accountIdentifier) ||
    isVenmo(accountIdentifier);

  const isCashPayment = isCash(accountIdentifier);

  const isPaymentMethodValid = validatePaymentMethod({
    isDigitalPayment,
    isCashPayment,
    isOrbitalPaymentEnabled,
    clickedPaymentMethod,
  });

  const isCreditCardExpirationValid =
    clickedPaymentMethod && isCreditCardPaymentMethod(clickedPaymentMethod)
      ? isCreditCardCurrent(clickedPaymentMethod.credit)
      : true;

  return { isInvalid: !isPaymentMethodValid, isExpired: !isCreditCardExpirationValid };
};

/**
 * This function check if the tip enter by the user is valid
 */
export const checkIfValidTip = ({
  tipAmount,
  subTotalCents,
}: {
  tipAmount?: IUseCartTip['tipAmount'];
  subTotalCents?: number;
}) => {
  const isTipLessThanSubTotal = Number(tipAmount) <= Number(subTotalCents);
  const isTipLessThanMax = Number(tipAmount) <= TipAmounts.MAX_AMOUNT;
  return !!(isTipLessThanSubTotal && isTipLessThanMax);
};
/**
 * useful for excluding certain payment methods from being used to reload prepaid cards or quick pay
 * @param {IPaymentMethod} paymentMethod
 * @returns {boolean} whether this can be used for reloading a prepaid card or quick pay
 */
export const isAllowedReloadMethod = (paymentMethod: IPaymentMethod) =>
  !paymentMethod.prepaid && !paymentMethod.cash && !paymentMethod.paypal && !paymentMethod.venmo;

/**
 * useful for excluding certain payment methods from being used to quick pay
 * @param {IPaymentMethod} paymentMethod
 * @returns {boolean} whether this can be used for quick pay
 */
export const isAllowedQuickPayMethod = (paymentMethod: IPaymentMethod) =>
  !(
    paymentMethod.cash ||
    paymentMethod.paypal ||
    paymentMethod.venmo ||
    paymentMethod.prepaid ||
    paymentMethod?.fdAccountId === CartPaymentCardType.APPLE_PAY ||
    paymentMethod?.fdAccountId === CartPaymentCardType.GOOGLE_PAY
  );

export const extractPaymentTypeForLD = (paymentMethod: IPaymentMethod | undefined) => {
  const isApplePayPaymentType = paymentMethod?.fdAccountId === CartPaymentCardType.APPLE_PAY;
  const isGooglePayPaymentType = paymentMethod?.fdAccountId === CartPaymentCardType.GOOGLE_PAY;
  const isCreditCardPaymentType =
    !!paymentMethod?.credit && !isApplePayPaymentType && !isGooglePayPaymentType;
  const isCashPaymentPaymentType = paymentMethod?.accountIdentifier === 'CASH';
  let ldPaymentType;
  if (isCashPaymentPaymentType) {
    ldPaymentType = CartPaymentCardType.CASH;
  } else if (isCreditCardPaymentType) {
    ldPaymentType = CartPaymentCardType.CREDIT;
  } else if (isApplePayPaymentType) {
    ldPaymentType = CartPaymentCardType.APPLE_PAY;
  } else if (isGooglePayPaymentType) {
    ldPaymentType = CartPaymentCardType.GOOGLE_PAY;
  }
  return ldPaymentType;
};

export const mapPaymentMethodToLoyaltyPaymentMethods = (
  paymentMethod?: IPaymentMethod | null
): LoyaltyPaymentMethod | null => {
  if (!paymentMethod) {
    return null;
  }

  let backendPaymentMethod = null;
  const paymentType = extractPaymentTypeForLD(paymentMethod);

  if (paymentType) {
    if (paymentType === CartPaymentCardType.CREDIT && paymentMethod.credit) {
      backendPaymentMethod = LoyaltyPaymentMethod[paymentMethod.credit.cardType];
    } else {
      backendPaymentMethod = LoyaltyPaymentMethod[paymentType];
    }
  }

  return backendPaymentMethod ?? null;
};

/**
 * Validates credit cart expiration date (year and month)
 * @param cardInformation ICredit, object with card information
 * @returns boolean
 */
export const isCreditCardCurrent = (cardInformation?: ICredit | null): boolean => {
  const { expiryYear: expYr, expiryMonth: expMon } = cardInformation || {};
  if (!expYr || !expMon) {
    return false;
  }

  const currentDate = new Date();
  const expirationDate = new Date(2000 + parseInt(expYr, 10), parseInt(expMon, 10));

  return expirationDate > currentDate;
};

/**
 * Checks if payment method is valid
 * @param isDigitalPayment boolean
 * @param isCashPayment boolean
 * @param isOrbitalPaymentEnabled boolean
 * @param clickedPaymentMethod IPaymentMethod
 * @returns boolean return true if valid payment method, false otherwise
 */
export const validatePaymentMethod = ({
  isDigitalPayment,
  isCashPayment,
  isOrbitalPaymentEnabled,
  clickedPaymentMethod,
}: {
  isDigitalPayment: boolean;
  isCashPayment: boolean;
  isOrbitalPaymentEnabled: boolean;
  clickedPaymentMethod: IPaymentMethod | undefined;
}): boolean => {
  // If Orbital payment is enabled and orbitalIdentifier is missing in
  // the payment method, then it is considered invalid and a re-vaulting is required
  if (!isDigitalPayment && !isCashPayment && isOrbitalPaymentEnabled) {
    return (
      Boolean(clickedPaymentMethod?.credit) && Boolean(clickedPaymentMethod?.orbitalIdentifier)
    );
  }

  return true;
};
