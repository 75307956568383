import * as React from 'react';

import { Svg, SvgPath, SvgProps } from '@rbilabs/universal-components';

export const BurgerInHandSvg = (props: SvgProps) => (
  <Svg viewBox="0 0 258 130" {...props}>
    <SvgPath
      fill="#FF8732"
      d="M196.7 39.3c1 3.4-58.3 2-56.9 2.5-21.8.6-37 .2-49.9 0-26.7-.6-23.6.3-24.4-1.3.1-24.5 43.3-28.9 59.7-29.5h12.3q24.3-.1 43.9 7.1c7.9 4 14.9 13.7 15.3 20zM70 76c13.1.6 26.4 1.4 39.7 1.5 16.8.4 29.1 1 34.7.7 9-.4 17.7-2 26.9-2.6 22.3-1.1 22.5-4.4 22-.4-3.5 14.2-16.7 12.7-27.8 13.7q-21.7 1-43.3 1.3c-37.6-1-50.7 3.4-55.9-12-.2-2.1 2-2.2 3.7-2.2"
    />
    <SvgPath
      fill="#502314"
      d="M132 81.8h-9.2c-25.6-2.2-49.7.3-52.4-2h-.6c-.2-.4-.2-.1-1.4-.6 0-.2-.5 0-.5-.3q-.5 0-1-.3l-.5-.2-.1-.2H66V78c-.6 0-.9-.7-1.5-1q0-.2-.3-.2l-.2-.5h-.2c-.4-1.1-1.2-2.6-1.3-4.1v-2c1.1-5 3.9-4.7 9-6q1.3-.1 2.5-.4.3-.2.7-.1.5-.2 1.2-.2h.5c.3-.2.8 0 1.3-.2q1.7.1 3.9-.2h1.2q3.4-.5 7.1-.5c1 .1 3.1-.6 4.2.2h.6q1.4-.3 3.3-.3l.6-.1h.2c1.5-.3 3.2.1 4.7-.8l-.2.4c6.6-.3 13.5-1 20.2-1h.9q2.8.2 5.9 0h5.8q0-.1.4 0l7 .1a34 34 0 0 1 8.6 0l.3.1h.3c.2-.2.2.1 1-.1v.1-.1l.1-.2v.3q2.9.3 5.9.3t6-.2h.9l7.3-.4h4.8q.6-.2 1.4 0 1.5-.2 3.3.1l1.3.1 3.3.1q2.2-.2 4.6.2c1.9 0 4.1.6 5.3 2.5l.4 1.5v1.8q-.1.3 0 .4-.2 2.7-1.2 5.3c-2.1 4-2.1 5-18 6.3-15.6 1.2-31.4 1.4-47.1 2.7"
    />
    <SvgPath d="M198.3 64.7c0 .4-.5.4-.3.9v.2h-.2q-.2.8-1 1.5-.3 0-.2.4c-.8.2-1.2 1.3-2.2 1.6v.2l-.4.3v-.1l-1.1.7q-2.3 1-5 1.1c-1.5.5-3.5.5-5.3.8H181q-3.9.6-8 .7l-1.1.2-.1-.2v.1l-2 .2-5.5.6v-.1q0 .2-.2 0l-4.3.5h-2.2c-.1.2-1 .1-1.8.2-1.4 0-3 .4-4.6 0-4.2.3-9 .7-13.4.5q-1.3.4-4 .1-.1.2-.5 0v.1h-1.6q-.7.1-1.6-.1h-1.8L126 75q-.2.1-.6-.1h-.7q-3-.2-6.2 0l-1.6.1q-2 .2-4.2 0h-7c-3.4.2-7 0-10.6.3h-.6l-1.1.1v-.1l-.2.1h-.8l-1.9-.2h-1.7l-7.6-.2a85 85 0 0 1-8.6-1.3h-.2l-2.2-.8q-2-.6-4-2v-.1q-.4-.2-.4-.6l-.1.1c0-.2-.3-.1-.3-.5q-.4 0-.4-.4l-.1-.1-1.4-2.2.6-.7q1.1 1 1.9 2.7l1 1 .3.3a21 21 0 0 0 9.6 3c3.1.3 6.3.9 9.8.7.8-.2 3.4 0 6.2.2h1.5q.8-.2 1.8 0c.1 0 .3-.3.6 0 1.8-.4 4-.2 6-.3h13.4l2.6-.2h4.6l4.5.2h.5l6.1.1h6.5v-.2c.4.2 1-.1 1.5 0h.7s.9-.2 1.4 0h1a32 32 0 0 1 5.6-.3h2l7.1-.5v.1c.7-.3 1.6 0 2.3-.3 1.1.1 2.3-.4 3.6-.3l2.8-.3 2.5-.1V72h.7q.3-.2.7 0 .2-.2.5-.2c.4.2 1-.3 1.6 0q1-.3 2.5-.3h.2q.7-.2 1.5 0 1.3-.4 3-.4h.5l2.8-.4 2-.3 3.6-.7 1-.2q.2-.3.7-.5h-.1c.3 0 .5-.5 1-.5l.2-.4h.1c.2 0 .2-.5.6-.4 0-.4 1.5-1.1 1.6-2h.3v-.3s.3 0 0-.3h.2q.1-.9.7-1.7.5.3.8 1z" />
    <SvgPath d="M171.8 60.6q0 .2.7.1v.2h.2q.2 0 0 .2l.3.1c-.2.6 0 .5 0 .6a8 8 0 0 1-1.6 2.4q0 .5-.7.8v.1h-.2l-.2.2q-1.1 1.6-2.8 3.3-.4.7-1.2 1.2c-.5.3-1 1-1.8.8q-.2 0-.5-.3l-.2-.7q.1 0 0-.4h-.2V69l.3-.9q0-.5.7-1h-.2l.2-.2.2-.2v-.1h.1l.5-.8q-.1-.1.3-.4.6-1.4 1.5-2.7l.4-.7h.2c0-.1 0-.6.4-.5q0-.4.6-.6.3-.2.8-.1v-.2q-.1.1.4 0l.2.1zm-44 3.8.1-.4v.1c.2-.2.1-.5.6-.6q0-.4.3-.6h-.1v-.2c.3 0 .2-.3.6-.7l.3-.1v-.1l.3-.3c.4-.8 1.7-.2 2.6-.5l.1.1.1-.1q.3 0 .2.2.2-.2.3.1.3 0 .5.5h.1c.2.2-.1 0 0 .6l-.1.2h.1l-.1.4h-.1l-.1.5-.3.1-.1.3q0 .3-.3.4v.1c0 .2-.4.2-.3.5h-.1l-.4.8c.1.4-.4.2-.3 1q-.1 0 0 0l-.9 1q0 .4-.5.7v.1l-.8 1.3v-.1c-.2.4-.7.5-1.1 1.3v-.2q-.2 0 0 .2c-.2 0-.3.4-.6.4 0 .5-3.7 2-3.5.3l-.1-.6v.1q-.1-.3.4-1.2l.7-1.8.1-.4h.1q-.2-.2.2-.3V67l1.2-1.6h.1v-.2l.1.1c.2-.3.3-.9.7-1M104.6 67h.2l.2-.3h-.1q.1-.4.2-.2l1-1.7.8-1.1.2.1v-.4h.1l.2-.3.8-.8q0-.3.3-.3.4-.7 1.3-.6c0-.2.3 0 1.3-.4v.1-.1h.4c.5-.2 1.6 0 1.5.8q.2 0 .1.2-.2-.1-.1.4-.4.3-1 1.3v-.1.1l-.2.1c.2.2-.1.2 0 .4-.3.3-.6 1.1-1 1.5l-.3.4c0 .3-.6.4-.6 1h-.2v.2q-.5.3-.8 1h-.2l-.5.7-1.6 1.7-.5.4-1.6 1-.4.3q-.4.4-1.2.3l.1-.1h-.3v-.2c-.3 0-.2-.9-.1-1.7l.4-.6V70h.3v-.3h.1c.1-.8 1-1.8 1.3-2.6m39.7-4.1.1-.2q0-.3.3-.6l.1.1q0-.2.2-.3 0-.3.6-.5h.3c.3-.1.9-.6 1.4-.4.1-.2 1.1 0 1.5.5l.7.2q.3 0 .4.7v.7c-.2 0 0 .6-.3.6l-.3.9-.2.1h.2l-.4.6c-.3.3-.2.6-.5 1h-.1l-.3.6c-.3 0 0 .2-.6.5l-.2.4h-.1q-.6.8-1.4 1.5h-.1q-.3.2-.4.5t-.7.5s0 .4-.3.3q.1.2-.8.6h-.2c-.2.4-1 0-1.2-.4 0-.1-1-.9-.6-1.1l-.1-.3v.1c0-.2.3-.4 0-.6l.2-.2v-.1q0-.4.2-.7v-.1l.2-.3h-.1q0-.3.3-.4s-.2-.4.3-.6V66l.3-.6h.1l-.1-.1q0-.2.4-.2l-.2-.1.7-1v-.2s.4 0 .2-.2l.2-.3h.2-.2q0-.2.3-.3m-58.5 6.8q0-.2.4-.6l.3-.3h-.1q.9-.8 1.6-1.9h.2v-.4h.2l.2-.5.1-.2.2.1c0-.7.8-1 1.1-1.6h.1c.4-.6 1.2-1.2 1.6-1.9h.4c1-.2 3-.4 3.7.3h-.2c.5.1.2.4.4.9h-.3v.3l-1 1.2h.1l-.1.2h-.2l.1.1q-.2.5-.7.9-.1.3-.4.5v.2l-.1-.2q-2.7 3.2-6.2 5.3-1.2.8-2.5-.1-.3-.9.8-1.8z" />
    <SvgPath
      fill="#F5EBDC"
      d="M103.7 34.8c-2-.3.5-2.6 1.8-2.8 3-.1.3 2.4-1.2 2.8zm33.6-21.6c1.8 1-1.7 2.3-2.9 2-1.2-.6 1.7-1.8 2.4-2zM72.7 34.3q-.7.5-1.7 0V34l.2-.1c-.3.7.5.9 1.5.5m119.7-.5v.2zM98.8 24c1.9 0 4 2.6.6 2.2-1.8.2-3-2-.6-2.2m17.4-5.4c-1.2.6-3 1.1-4.5.6-1.4-1.2 3.6-2 4.5-1.2zM179.8 29c-1.6.2-2.8-1.3-.5-1.8 3.4-.2 4.3 1.4.5 1.8m-91 3.7c-.7 0-4.5-.5-2.6-1.7 1.7-1 5.6 1.5 2.7 1.7m78.6-.3c-.6 0-1.8 0-2-1 .1-2.6 6.5.5 2 1M93.7 17.7c-.1-.8 4.4-1.2 4.9-.4.3 1-4.5 1.7-5 .4m36.3 4.5c-1.7.3-1.7-2.3.6-2.5 2.2.1 1 2.4-.6 2.5m-12.9 5c-4.5-.8-.4-4.2 1.4-.9q-.3.8-1.4.9m10.7 4.1c1.6 0 2.7 2.3.3 2.4-2 0-2.5-2-.3-2.4m35.5-8.1c-1.2 0-3.5-1.7-1.4-2.4 1.3-.4 4 2.1 1.4 2.4m-19.5 3.1c-3.2-.6 1.8-2.6 3.1-1.8.6 1-2.1 1.8-3.1 1.8m4.4 5.6c-.1 1.3-2.5 2.7-3.2.9.2-1.2 2.5-2.4 3.2-1m1.7-14c-5.5.5-4.4-3 .4-.8l.1.6zM88.7 25q-.7.7-2 1c-.3 0-1.5.3-1.4-.5v-.1l.1-.1h.1l.1-.1q.2 0 .2-.2l1-.5h.4q.3-.3 1-.2h.2l.2.1q.2.3.1.6"
    />
    <SvgPath
      fill="#F5EBDC"
      d="M72.7 34.3c-.4.1-2 .6-1.4-.6v-.2h.1l.1-.2h.1v-.1h.1l.1-.2.1-.1h.1l.1-.1c.3-.3 1-.7 1.6-.4v.1l.3.3v.2l-.6.8q-.2 0-.4.3zM180 20.7c-.3.7-3 .2-2.8-.9q0-.9 1.4-.5h.1v.2l.4.2.4.2q.3.2.5.8m12.3 13-.3.6q-.4.4-1.2 0h-.2l-.2-.1-.1-.2q-.4-.2-.5-.8l.2-.4q.2-.4 1.1 0h.4q.5.3.8 1"
    />
    <SvgPath
      fill="#F5EBDC"
      d="M178.7 19.3c-.4-.1-1.3-.3-1.4.5-.5-.5.9-1 1.4-.5m12.5 13.4c-.2 0-.9-.3-1.1.1q-.2 0-.2.4c-.4-.7.8-.9 1.3-.5m-106-7.2c0 .6.8.7 1.5.6-.3.2-2 .2-1.4-.5M191 34.5q.4.2 1.2 0c-.1.2-1 .4-1.2 0M150 18c.3-.1.2-.3.5-.2V17q.4.6-.5.8m-61.7 6.5-1 .1-.5.1q.6-.4 1.5-.2m-14.8 9.4.6-.8q0 .5-.6.8m13.3-9.2q-.3.3-.9.5zm93.4-4q-.1-.4-.5-.7c.2 0 .7.4.5.8m-68.6-2 .2.7q-.5 0-.2-.7m4.8 0v-.5s.4.4 0 .5m-27.6 6.5-.1-.6q.4.1.1.6m90.5-5.4-.4-.2v-.2zm12.9 14.6.2-.2q0 .3-.2.2M179.6 20l-.4-.2q.3 0 .4.2M73 34.1q0-.2.4-.3 0 .2-.4.3m1-1.3-.2-.3q.3 0 .2.3m-1.3 1.5.3-.2q-.2.3-.3.2m77.6-17.2-.2-.1z"
    />
    <SvgPath
      fill="#F5EBDC"
      d="M85.8 25q0 .3-.2.2zm30.4-6.3v-.1h.1zm74.3 15.4.2.2q-.2 0-.2-.2M74 33v-.2q.1 0 0 .2m-2.6.5-.1.2zm.1-.2v.2zm120-.5h-.3.2m-103-8.3h-.1zM71.9 33v.1m119.8-.2-.2-.1zm-119.9.2v.1m-.1 0v.1m.3-.3"
    />
    <SvgPath
      fill="#198737"
      d="M79.5 69.9q-.2.1-.8-.1c-.3-.1-.4-1-1.4-.7-2.3 2-1.7-1.4-4.8.4-2.2-.4-.5-1.5-3.8.1h-.3l-.1-.2-.1-.3q-.2-.2 0-.5-.2-.1 0-.4H68v-.7h.1l-.1-.2q.2 0 0 0h.1v-.1h.1l-.1-.1.5-3.1v-.2l.2-.7.1-.3.2-.2q.3-.3.8 0c2 1.6 1.3 0 3 .4.9.4 1 .8 2.2.1 1.5-1.3.9 1.2 3 .3.1 0 .5-.5.7-.3V63c.8 0 1.3.7 2.2.8q.6 0 1.2-.4.2-.2.7-.2.1-.2.8.2l.6.3.2-.1h.2c1.9-1.2.9-.7 3.3-.3q.4-.4 1.3-.7.1-.1 1 .4c.7.2.7.3 1.7-.1q0-.3.2-.1.4-.4 1.2-.4c2.4 1.4.9-.1 3.7 0 .8.5 1.4-.4 2.4-.2.7.2.3.7 2 .2.2.1.2 0 .3.6l-.8 4.1c-.2.5-.1 1-.5 1.8-1.2 1-1.8-1.2-2.9.5-2.3.8-2-2-3.6 0-1.4.8-1.6-1.5-3.6-.2q-.5.6-1.4.5c-.6 0-1.2-1.7-2.2-.3v.1l-.3.4c-1.8.6-.7-2.2-3.7 0-1.6.4-.8-2-3.4 0"
    />
    <SvgPath
      fill="#FA0"
      d="M98.5 63.5v.4q-.1 0 0 .4-.2-.1-.1.4v.2l-.2.5v.6l-.1.2q-.2.1 0 .4-.2.1-.2.5v.1q-.2 0 0 0-.1.1 0 .4h-.2.1v.2h-.1l-.2 1.3c-.1 0 .2.5-.4.5-.3-.2.2-.7 0-1q.2 0 .1-.3h.1V68h.1v-.5q.2 0 0 0l.1-.6q.2 0 0-.2h.2v-.9l.2-.7q.2 0 0-.3l.3-1.5q.2-.4 0-.3v-.3s.4-.1.4.2v.3l-.1.4m-1.1 4.1h.2zm-16 .1v-.9l.4-3.1.1-.2h.2q0 .1 0 0v.3l-.1 1.3-.1 1v.5c-.1.7 0 2-.3 2.8q-.1.1-.4-.4l.1-.4v-.5zm0 .4q0 .2 0 0 .1 0 0 0m.4-.8q0-.2 0 0m-.4.7q0-.1 0 0m-2.9-2.2h-.1v.3l-.2.9v.2c0 1-1 3.3-.4.4V67l.1-.7v-.2l.1-.5v-.5q.2-.3 0-.4l.2-.2v-.7l.2-.4v-.2h.3v.5h-.1v1.1c-.2.1 0 0-.2.5v.4m16.3-2.1v-.8h.2v.1l.1.5V66h-.2.1v.7l-.1.8c-.2.5 0 1.3-.6 1.7v-.6l.1-.9v-.3q.2 0 0 0 .3-.6.2-1.3.2 0 0-.3l.2-.1v-.5h-.1q0-.2.1-.1v-1.3M71 66.9v.2h-.1v.2l-.1 1q-.2 0 0 .3h-.1q-.4 0-.3-.5v-.3l.2-.1h-.1l.1-.2v-.8q.2 0 0 0v-.1l.1-.7v-.2q.2 0 0-.1h.1v-.1l.4-2v-.2l.2-.1q.2-.1.2.4l-.1.2v.1s0 .6-.2.6zm14.4-2.3s-.3 0 0 .1v.2l-.1.4v.5h-.1v.8H85v.5c-.2.6.1 1.4-.3 2.1h-.2v-.9q.2 0 0-.3s.2-.5 0-.3l.1-.5v-.4l.1-.5V66l.1-1 .1.1v-.7c.2-.2-.1 0 0-.4l.2-.1-.1-.2q.2 0 0-.3.3 0 .4.3v.6q-.2.2 0 .3m2.3 2.3.2-.2v-1q.1 0 0-.2.1 0 0-.1l.1-.1v-.6h.2-.1v-.5h.1v-.8q0-.5.4-.2-.2.2 0 .4-.2 0 0 .5v.4h-.1v.5q-.3.1 0 .2l-.1.1-.1.9q-.1 0 0 .1l-.2.3v1.2l-.1.4-.1.8-.1.1-.2-.1v-.8l.1-.2zm-13.4 1.2v.8H74q-.2-.1-.1-.7v-.3h.1v-.4s0-.4.1-.2v-1.1l.2-.1v-.2q-.2-.1.1-.4h-.1v-.2l.1-1c-.1-.3.8-1.7.5-.4v.2h-.2l.2.2-.1.2v.1h-.1v.6c-.2 0 .1.2 0 .2v.4c-.1.1-.2 0-.2.6v.2q-.2.2 0 .4v.1q-.2 0 0 .2l-.1.1zm0-.1h-.1m17.4-2.7v1.2l-.2 1.6h-.2l.1.1v.5l-.2.2v-.1q-.2 0-.2-.4.2 0 0-.3l.2-.2v-1.5q.2 0 0-.1h.2q-.2-.1 0-.1V66h-.1v-1q.2 0 0 0l.1-.1v-.5q.1 0 0 0v-.3c.2 0 0-1.6.4-.8v.8s-.2.5 0 .6v.1l-.1.1zM88 64.2"
    />
    <SvgPath
      fill="#198737"
      d="M147.4 62v.1c.2.1 0 0 .3.6l-.5 2.5q-.3.7-.3 1.6-.1.7-.6 1.9-.9.6-2-.4c-.3-.1-.3.5-.9.8-.4 0-1.4.4-1.8-.3-1.9-1.8-.5 1.7-4.3-.4-3.7 2.4-2.1-.3-4.4.4l-.2.4q-.4.6-1.2.3l-.5-.4q-.5-.6-1.2-.3c-2.6 2.5-1.4-1.5-4.6.8l-.7-.1c-1.6-1.8-1.7.7-3.5-.3-1.4-1-.9-.5-3 0-1.2-.4-1-1.5-3.2 0l-.3.1h-.1q-.3 0-.4-.2c0-.1-.1 0 0-.8q-.1.1-.1-.4v-.7h.1l-.1-.1q.1 0 0 0v-.2h.1l-.1-.2.2-.4V66c-.2 0 0-1.4.4-2.5 0-.4.2-1.2.6-1.3h-.1s.2-.4.7 0c.5.3 1.2 1.2 2.4.2q.3-.1.6.2h.1c.9.4 1 .8 2.2 0 1.3-1.2 1 1.3 3 .4 1.1-1.3 2 1.2 4 0 1.8-1 .9 1.1 4-.6.5-.2 1 .8 1.8.5 1.7-1.5 1.9.4 4-.5q.6-.3 1.4-.4c2.6 1.6 1.5-1.2 4.1 0 .3.3.6-.4 1.6-.4s.8 1 2.5.4"
    />
    <SvgPath
      fill="#502314"
      d="M144 63.4c1-.7.7 1.4 0 .1m-4.6 3.6-.2.2c-.6.8-.9-1.3.2-.2m-16.4-.6c-1.3-.3 1-1.3 0 0m17.3-2.3c-.8 1.3-1-1.1 0 0M120 67.3c.5 0 .5.8 0 .7q-.6-.3 0-.7m22.1-2c0-.3 1-.4.7 0 0 .3-.6.4-.7 0m-16 2.3c-.3 1.4-1-.9 0 0m6.8-.5c.2-.3 1 0 .4.4q-.4.2-.4-.4m-3.6-1.3c0-.2.6-.4.6 0 .2.2-.4.7-.6 0m6.1-1.3c.4 0 .5.9-.2.6q-.2-.3.2-.6m-19.5 2q.1-.3.4-.1c.3.5-.8.8-.4.1m29.8 0c.5.5-.9.7-.3-.1z"
    />
    <SvgPath
      fill="#198737"
      d="m182.7 67.7-.4-.3c-.6-.7-1.1-1.1-1.9.3l-.1.2v-.1h-.1q-.3.2-.8 0l-.5-.5q-.4-.6-1.2-.3c-2.5 2.7-1.8-1.5-4.2.6l-.2.2-.1.1c-.4 0-.4.1-.8-.1v-.1c-.5-.1-.2-.7-1.3-.7-2.8 2.4-1.7-1.7-5 .5-1 0-1.3-1.5-2.5-.6-.2 0-1 1.2-1.6.5q-.3 0-.3-.6h.1l-.1-.2v-1.7l.6-3.6v-.1q.1-.7.5-1.4v-.1c.3-.5 1 0 1.4.4 1.3 1 1.4-.6 2.4 0l.1.2c1.9 1.5 2.3-1.8 3.7.4q0 .2 1 .1l.5-.2s.4-.6.7-.4q.5 0 2 .8c.2 0 .2.2 1.2-.3l.1-.1.5-.2q.2-.2.2 0h.3l1.1.5c1 .1 1.4-1.9 3.1-.3h.6v-.2.1c.4-.1.4-.4 1-.6l.4-.1c.7-.1 1.4 1 2.4.3h.3q0-.3 1-.6.4-.3 1.3.6c.4.4 1.3-.9 2.7-.7.4 0 .6.8 1.7-.2h.5c.6-.2 1 1 1.9.3h.4c.6 0-.1 2-.3 3.4l-.2 1.5v.3c-.4.7-.1 3-1.9 2.2q-.2.1-.7-.5-.3 0-.8 1h-.1c-.5 0-1.5.3-1.9-.4-.5-.6-.9-1-1.7.3-.3 0-.6.4-1.4-.2-1.4-1.2-1.4.1-3.2.6z"
    />
    <SvgPath
      fill="#502314"
      d="M191.6 59.6c1-.3.7 2.6.5 4.2l-.1 1.4v.3l-.2.8-.3.7c-1.7 2.3-1-1.1-.8-2.4.2-.5-.2-4 .9-5m-11.9 8.6c-1-.4-.7-1.8-.6-3v-1.4c0-1-.2-2.6.3-3.7q.1-.3.4-.3.2-.1.5.2c.2 0 .2 2 0 5l-.3 3-.1.2zm-6-7.5c.3 0 1.2.3 1.1.9.1 1-.3 2.2-.3 3.4l-.3 2.1v.1h-.2c-1.4 1.4-1-.4-.9-2.7.2.2-.2-4 .6-3.8m4.1.7v.4q0 2.5-.4 5.4 0 .6-1 .9v-.3l.2-4.7c0-.8-.6-4.1 1.1-2.5zM164.4 64v-.2l.4-1.7V62l.2-.8c.1-.2-.2-1 .5-1.1 0-.2.2 0 .6-.2v.1h.1q.2 0 .2.3l-.1.4-.4 1.7v.2l-.3 2q-.1 1-.5 2.3c-.3.3-.4-.3-1 0-.1-.3 0-1.8.4-2.9m17.6-.5c.1 0-.3-2.8.3-3.3.2-.4 1.3-1 .9.8v2.2h-.1v.1c-.2.2.3 6-1.3 3.7v-1zm-11.4-3c.3.4.5 0 1 .3h.1c.3.3.2.7 0 2.6-.4.7.3 3.9-1.4 4 0-.2 0 0-.2-.3v-.2l.2-1.4v-.2q0-2.3.3-4.7m15.2 3.2v2.8q-.2.5-1.2.3h-.1v-2.5c.5-.6-.5-4.1 1.2-4.2.3 0 .3 2 .1 3.6M168.6 62l-.2 1.6-.3 2.6v.3c-.6.4-1.5 1-1.1-.6V65c.2-.3.1-.3.2-1q0-1.5.5-3.4c.9-.4 1.3-.3.9 1.4m19.4-2q.5.3.8-.3l.2.2q.1 2.1-.2 4.7.1 1-.2 2v.1h-.8v-.3zm6 5.5-.1 1-.2.6h-.6q-.2-.1-.1-.4l.1-.6q.3-3 1-6.3.4-.3.8 0 0 1.6-.6 3.4v.6c0 .3 0 1.2-.3 1.7"
    />
    <SvgPath
      fill="#D72300"
      d="M149.4 70.8c-.5-.1-2.2-.3-2.4-1.4v-1.3l.1-.1v-.1l.2-.3.3-.3.6-.3h.1v-.1c.4 0 1.3-.7.6-1.2l-.2-.1-1-.3q-.7 0-1.6-.3-.5.1-1.4-.4l-.2-.2h-.2q-.9-.5-.7-2 0-.2.3-.5c0-.2 0 0 .4-.6q.5 0 1.4-.6h.1l.2-.1c.1 0 .5-.3.7 0 .4-.6 2.2.3 3.7-1.2q0-.3.5-.5l.1-.2.2-.2h.1l.1-.1h.7v-.2h.3v.1c1.3-.1 1.8 1.5 3.2 1.4 1.4 0 2.6 1 4.3 1q1.6.1 3.2 1.4l.3.2v.5h.1l.1.1-.1.1v.5l-.3.2q-.1.2-.7.4c-1 .3-2.1 0-3.3.2l-.5.2c-.3.4.5.6.9.9h.2l.6.3q.3 0 .4.3v.1h.1l.2.2v.2l.1.4v.1h.1l-.1.2q.2 0 0 .3-.1.8-1.1 1.2-.5.1-1.1-.1l-1.8-.6c-1-.2-3-1.5-4.1 0q-.2.6-.7 1.2-.2 0-.2.2c-.2 0-.3.4-.5.4l-.7.5h.1l-.2.1zm-42-1.4-.1-.1c-.5-.4-.4-1.9-2.2-.7q0 .2-.2.2-.5.8-1.5 1.2l-.1.2V70l-.2.1h-.2q-1.3.5-2.5-.3-.2 0-.5-.4l-.3-.2v-.1q-.2-.1-.2-.6V68l.2-.4.2-.2c.4-.8 2-.7 2.4-1.7v-.4l-1-.6q0-.2-.4-.5t-.6-1V63s.7-1.3 1.1-1.3c.4-.3.3-.1 1.2-.4l2-.7.4-.2.9-.6 2-1 1.6.2h.2v.1h.2l.2.2q0-.2.9.8h-.1v.1c.2.4.7.3 1.1.8l.2.2c.3.2.2.3 1 .8l.6.4q.2 0 .2.2h.1v.2h.2q.8.8 1.2 1.8v.1l.1.5s.2.5-.1.7l-.1.3-.1.2v.1q-.4.4-1.3.7l-.2-.1c0 .5-1.4-.6-2 .3h.2v.1l-.1.4.1.1h-.1v.3h.1v.6l-.2.2c-.3.7-1.4 1.2-2.4 1.2q-1 0-1.9-.8"
    />
    <SvgPath
      fill="#F5EBDC"
      d="M78.7 44.7h.3c4.5.7 7 .9 12.4.6 5.5 1.4 17.7 2.9 17 11-1 6-8 6.7-13 6.8-2.4 1-10.7 1.3-15.6 1.3-4-.3-6.8-.1-11.5-2C62.4 59 60.4 53.7 65 48c4.4-2.8 6.8-2.7 13.6-3.3m25.7 9.7c-2-2.3-13-3.1-18.8-3.2-3.2 0-6-1-9.4-1.1-1.3 0-11.8 1.6-9.2 3.6 1.4 1.7 1.3 2 3.9 2.6 4.8 1.6 30.3 4 33.5-1.9"
    />
    <SvgPath
      fill="#502314"
      d="M108.1 57.7c-1.2 2.6-.2-1.5-4.4-2.5 1.5-2 3.6.8 4.4 2.4zm-43-9.6c1.7-1.8.9 2.5 1.6 4.4l-.2.3c-.6-2.7-2.1 2.6-3.7 3.3-.6-1-.4-.7 2.7-4.8zm12.5 9.2c1 .3.9-.4.3 3.5-.3.3-.1 4-1.2 3.4-1.2.8.5-4.8.9-6.9M65 60c-1.4-1-.4-1.8 0-3.3.2-.9 2.5-4.8 2.3-2Q65.8 57.1 65 60m4.8-4.1c1.2-.1.2 1-.7 6.8-2 .2-.2-4 .7-6.8m3.2 8c-.2 0-.9 0-1-.2s1.5-9 2.3-6.1c-.7 2-.8 4-1.3 6.3m11.5.4h-1c0-1.9.5-4 .4-6.3 1.2-.7 1 4.8.6 6.3m2.7-19c1.7-.2-.4 2-.7 3.3-.4.2-.4 2.7-1.3 2.6-2 .4 1.6-4.7 2-5.8m-14.8 5.4c-.1 0 2-6.2 2.6-5.8.8-.2-.2 2.3-.6 3.4-.5 1-.4 2.5-2 2.4m27.7 7.3c-.2-.5-.4-1.1.9-1-.4.8 1 2.8.9 5.5-2.2.5-.7-.6-1.8-4.5m-19.7 6.5c-.9 1-.2-7.7 1.2-6.5 0 1.5-.6 3-.6 4.8-.2.5.3 1.7-.6 1.7m14-18.5 1 .2c-.4 1.7-.5 2.3-.4 4.9q.2 1-1 .7c-.5-1.7.2-3.8.5-5.8m-11.2-.6c-.2 1-1 4.2-1.8 5.3-.4-.1-1 .2-.7-.7.3-.6 1.5-5.7 2.5-4.6M106 60.7c-1.3.6-.4 1.7-3.1-4.8.7-1.1 1.8 1.9 3.1 4.8m-37-9c-.3-2 3.3-11 1.5-3-.5 1 0 2.9-1.6 3m21-.5c-.5-.5 2.2-8.2 2.3-5.2-1.6 2.5-.4 6-2.3 5.2m7.8 9c0 .3 1 3.2-.2 2.9q-.6.2-.7-.4c.6-1.1-1-4-.3-5.1.9-.3.4.5 1.2 2.7m-10.9-1.4c-.3-.5.5-1.2.8-.3-.1.3.8 5 .1 5.7-.9.1-.7-.1-.8-1.5q.2-1.8-.1-3.9m4.2 5c-1.5.7-.8-1-1-2.2.2-.3-.4-3.8 1-3.7zm2.8-1q0-1.7-.4-3.5c0-.3-.4-1.7.8-1.4-.1 1.7.8 3.6.6 5.5-.6 0-1.1.4-1-.7M77.8 50l-1 .1c1.5-5.4 4.8-9 1 0M99 52.4c-1.8.3-1.1-4.4-.7-5.6 1.2.4 0-.4.7 5.6m2.3.5q.6-2 .8-4.5c1.1-.7.7 3.6.1 4.8z"
    />
    <SvgPath fill="#502314" d="M104.4 54.4q-.1-.3-.5-.4.9-1.1 1.9-3.7c1.8.9-.4 3-1.4 4.1" />
    <SvgPath
      fill="#F5EBDC"
      d="M142.7 62.2c-7 .6-26.6 4.8-33.3-3.2a9 9 0 0 1-.6-11.8c2.6-4 9.3-4.4 14-4.8 2.5 0 4.6.7 7.2.7 7-.3 9.4.2 16.7 3.1 8.8 3.2 8.8 15-4 16m2.8-6.7c3.7-2.2 5-3-.4-4.5a87 87 0 0 0-17-1.7c-2.8-.4-5.7-1.3-8.6-1-1.5.5-11 1.8-7.8 4.2 1.2 5 25.3 5.4 33.8 3"
    />
    <SvgPath
      fill="#502314"
      d="M152.5 56.4c-1 2.4-.2-.3-3.2-2.3l-1.2-.4c1.5-2.1 4 .9 4.4 2.7M111.1 51l-.1.3c-.7-3-2 2.4-3.8 3.6-1.1-.8 1.9-3.9 2.7-5.3-.1-.8-.1-2.3-.4-3.5 1.7-2 1 3.1 1.6 4.9m10 12.6c-1.1.7.3-4.2.6-6.3.3-.6 0-1.4 1-1 0 0-.6 8.2-1.6 7.3M109.5 59c-.4-.5-1.2-.7-.6-1.7 0-.8 2.8-7.7 3-4.2a20 20 0 0 0-2.4 5.9m4 3-.7-.3c-.7-.2.7-5 1.5-7.2 1.4-.3 0 2-.8 7.4m3 1c0 .2 1.4-9.8 2.3-6.7-.7 2.3-.8 4.4-1.3 6.9zm12.5.5-1 .2c0-2.3.5-4.6.3-7 1.4-.8 1 5.7.7 6.8m2.7-20.5c1.8-.3-.3 2.1-.6 3.4-.4.2-.4 3.1-1.4 2.9-2 .5 1.7-5.2 2-6.3m-14.7 6s2-7.1 3-6.2c0 1.3-.8 2.5-1.2 4-.5.8-.2 2.2-1.8 2.2m28.5 6.6c-.5.9 2.6 8-.4 5.8.3-3.6-1.7-5.8.4-5.8M124.9 64c-1 1-.1-8 1-7.1.5.2-.3 2-.4 4.5-.2.2.3 2.6-.6 2.6M139 43.7l.8.2c-.3 2-.4 2.5-.4 5.2-.1.3.4 1.2-1 1-.4-2.1.2-4.2.6-6.4m-11.3-.6c-.3 1.3-1 4.3-1.9 5.8-.5-.2-1 .1-.6-1 .3-.4 1.5-6 2.5-4.8m22.5 16.5c-1.5 1.1-.8.3-1.7-1.6.1 0-1.9-3.4-1.2-3.7.6-.8 2.2 3.6 2.9 5.3m-37-9.6c-.1 0 1.3-8 2.1-6.6q.2.7-.6 3.7c-.5 1 0 2.8-1.5 3m21-.8s1.1-7.1 2.5-6c-.5 1.8-1.3 3.6-1.4 5.8.2.8-.8.6-1 .2zm7.8 10c.2.8 1 3.8-.9 3q.2-.8 0-3s-1.1-3 0-3c.5 0 .1.8.9 3M131.6 57c.5-.7.9 5.1.7 6.4-1.7 1-.6-2.6-1-5.9-.1-.2-.4-.6.3-.6m3.8 6.2c-.2 0-1.1.4-1-.4.1-.7-.2-6 .7-6 .5-.3.2 1 .3 6.4m2.8-1.3c.2-.7-1.3-5.6.3-5.3-.3 0 1.5 6.2.3 6q-.7.4-.6-.7m-14.5-19.5c1.1 0 .3.7-.8 4.2l-.6 1.7h-1c.4-2 1.5-3.9 2.4-5.9m19.8 8.2c-2 .3-1-4.9-.8-6 1.3.4 0-.5.8 6m2.2.5q.8-2.2.8-4.8c1.2-.9.7 4.1.2 5.2q-.5 0-1-.4"
    />
    <SvgPath fill="#502314" d="M148.9 52.8q-.2-.4-.6-.5 1.2-1.6 2-4c1.8 1-.5 3.4-1.4 4.5" />
    <SvgPath
      fill="#F5EBDC"
      d="M164.2 63.4h-.2c-8-1-15-7.6-8.7-15.4 4-3 8.3-3.6 13.4-4.3 4.5.2 7.1.1 12.2-.7 5.9 1 17.4 1.2 17.9 9 0 6-7 8-12.1 8.3-3.6 1.7-15 3.1-22.5 3.1m30.4-12.6c-4.2-3-19.5-.8-27.3-1.5a23 23 0 0 0-10.5 3.5c-.3.4 2.4 3 3.2 3 5.6 1.5 31.8 1.3 34.6-5"
    />
    <SvgPath
      fill="#502314"
      d="M153 55.6c2.7-4.5 3-3 1.9-7.3 1.4-1.8 1.2 2.6 2 4.4l-.1.3c-1-2.6-1.6 2.1-3.4 3.6q-.4-.3-.4-1m11 7.8-.6-.2c.1-.1.3-8.4 1.6-6.5l-.5 5.5c-.1.3.2 1.3-.5 1.2M181.2 43c1.2.1 0 .9-.2 3.4.2 4.9-2.8 3.2.2-3.4M168 63.3c-1 .8 0-5 .2-7 1 .1.9 0 .6 3.5-.2.1.3 4-.8 3.5m-7.6-7.6c1.3-.4.2 2 0 6.9l-.9-.3c-.5 0 .4-5 1-6.7m-4.4 4.5c-1.5-.9-.8-1.3-.5-2.9.2-.9 2-5.6 2.2-2.7-1 1.8-1.3 3.5-1.8 5.5m18.6-3.8c1.2-1 1.5 5 1.3 6.2l-1 .2q0-3-.3-6.4m2-12.9c1.7-.4 0 2-.3 3.4-.4.8 0 2.6-1 2.6-2 .6 1.1-4.9 1.4-6m-14 6.7c-.2.1 1.2-6.4 2.2-6 .3.1-.1 2-.6 3.4-.4 1 0 2.6-1.6 2.6m28.2 4.7c-.3-.6-.4-1.1.7-1.2-.2 1 1.4 3.3 1.5 5.4-2.2.8-.8-.7-2.2-4.2m-19.5 8c.1-.4-.4-7.2 1-6.2q-.2 3 0 6.1c.1.4-1 .7-1 0M184 43.4l.9.1q-.2 2.4.1 5.1c0 .3.2.6-.3.7-1.4.6-.8-4.4-.7-5.9m11.2 8.5-1.2-.2c1-2 3.9.3 4.6 2.1-.5 1.8-.6.5-1.5-.5q-.7-.8-1.9-1.4M172.8 44l-1.3 5.4c-.4 0-1 .3-.8-.7.2-.4 1.1-6.1 2-4.7m24 13c-1.4 1.2-1 0-1.9-1.2.1-.2-2.2-3-1.5-3.5.5-.6 2.6 3.4 3.3 4.6m-37.9-5.4c-.4-2.1 2.4-11.2 1.3-3-.4 1 .4 2.7-1.3 3m29.6 5.8c.3.1 1.3 4-.6 2.5.5-.9-1.8-4.9-.6-5.2.6-.2.1.3 1.2 2.7m-11-.5c-.5-.7.8-1.1.9.1-.2.9 1.8 5.7 0 5.3-.6-.6-.3-3.3-.9-5.4m4.7 4.7c-.3 0-1.2.7-1-.4 0-.7-1.2-5.7.3-5.5 0 1.9.5 3.9.6 5.9m3-4.4c0 .4 1.7 4.1-.2 3.7q-.6-2.5-1-5.3c.8-1.2.8.5 1.2 1.6m-18.3-7.9c1-5.6 3.9-9.3.9-.1zm20.9-5.4c1.3.3 0-.4 1.3 5.4-1.8.5-1.6-4.3-1.3-5.4m4.5 6-1-.3q.6-2 .4-4.5c1.1-.8 1 3.5.6 4.8"
    />
    <SvgPath fill="#502314" d="M194.6 50.9q-.1-.3-.5-.4 1-1.6 1.5-3.9c1.9.7-.1 3.1-1 4.3" />
    <SvgPath
      fill="#D72300"
      d="M99.6 60c-10 0-35.8-4-34.4-5.8.4-1.4-1.2-7 1.5-6.9 14-1.2 27.7 1 41.9.6 25 1 21-2.9 21.2 3.7-.2.3.2 4.6-1 4.6-9.3 3.6-19.3 2.8-29.2 3.9"
    />
    <SvgPath
      fill="#D72300"
      d="M199.3 49.5v.6c-.2.8.4 2.2-.6 2.8-4.5 2.4-9.6 2-14.5 2.9-13.5 2.6-42 3.9-52.7-1.1.1-.2-.8-7.4.4-7.2a82 82 0 0 1 14.6-.8c16 0 31.8-.3 47.8 1.2 5.5.7 4.8-.5 5 1.6"
    />
    <SvgPath
      fill="#198737"
      d="M126 34.5h.4a8 8 0 0 1 6.2 3.2c2.6-3 7.6-2.3 9.9.9 2.9-3.5 9.1-4.7 12.6-1.1 4-3.3 11.4-2 12.2 2.1q0 .3.5.2 6-.2 12.2.4c.7-5.4 7.6-7.2 10.1-2 4.4-2.8 6.8.9 7.2.1 6.8-3.5 17 5.3 6.1 8-.8-.2-.8 1.5-2 2.8-3 3.9-11.8 3.4-14.2.3-2.4 1-3.8.4-6-1-1.8 3-6.2 2.8-8.9.7-2 1.4-4.8.8-7.1.5-4 5.6-10 3.2-10.6-.3-2.6 1.6-6.3.9-8.5-1.4q-6 5.5-12.3 1.3c-2.2 4.5-8.8 5.8-12.9 2.8q-.5-.6-1.4-.2c-1.7 0-.3 2.3-2.8 4-2.5 1.4-6 1.9-8.6.5-6.4 5-15-.1-11.2-5.1-.8-.1-1.7-.9-2.6-1-2.1 1.1-5.2 1.3-7-.4-1.5 2.6-5.2 3.4-7 .8a4.3 4.3 0 0 1-6 .2c-2.5 3.3-9 3.3-8-2-3.3.1-7-3-3.2-5.8-4.5-3.7 1.1-5.7 4.6-5 1.8-2.6 7.8-2.4 10-.4 1.7-3.4 7-1.7 8.6 1 2.7-3.6 9.4-2 10.8 1.7q6.2-.1 12.5.4c1.8-5.6 6.6-5.2 10.2-2.3 1.3-2.5 3.3-3.4 6.1-3.9"
    />
    <SvgPath
      fill="#502314"
      d="M132 39.3c1.8 1-4 1-3.4 4.9-1 4.3-4.3-4.2 3.5-4.9m-64 2.1c.8-1 2.5-.5 4 0 3.4.5 3.4 5.6 1.1 3.6-.5-3.8-2.3-2.5-5.1-3.5zm43.3 10.7c-1.7-.3.6-5.6 3.6-5.5.4 0 1.7.2 1.1.7-3.6.4-3 2.6-4.2 4.8zm34.3-9.5c1-.5 9 1.8 6 2.9-4-2.8-6.4-1.6-10.2-1.4-.5-1 3.5-1.5 4.2-1.5M96.9 45c4.6-.2 6 5.5 3.2 5.2-.9-.3 1-3.5-2.6-4.2q-1-.1-2.3.1c-.8-.5.8-1.2 1.7-1.1m85.5 1.5c-2.2-.2 1.1-3 2.1-3.6.6-.3 5.9-2.2 3.2-.1-6.1 2.6-3.2 2.8-5.3 3.7m-94.6-4.8c-4.3-.4-3 4.2-5.3 3.3.1-6.5 10-3.4 5.3-3.3M196.5 46c0-3.4 5.7-4.6 7.4-2.6-1.5.2-3.4-.5-4.8.6-.8.3-2 4.3-2.6 2m-26.1-.7c-3.4.1-7.8-2.1-6.3-3.2 2.3 1.7 3.7 2.4 7 2 1.5.2.7 1.4-.7 1.2"
    />
    <SvgPath
      fill={props.fill ? props.fill : '#fff'}
      d="M59.2 130H27.6c1.4-5.3 1.8-11 2.8-16.5C12 105.2 2.5 84.6.9 65.5v-.7c.7-9.9 1.7-11.5 4.3-18.6 5.7-18.4 17.2-25 31.4-33A80 80 0 0 1 71 3.1c6.3-.2 9.6 1.9 13.8 5 3.9 2.5 8.7 5 8.9 8.2.1 3.4-1.4 8.8-5.8 8-.2 3.2-4 7-7.2 5.6-.2 6.3-6.4 7.3-11.2 5 1 5-2.4 7.2-6.6 6.8-7.2-1.9-14.6-2.7-20.9 2-9.5 7.8-10 23.5-6 33.3a31 31 0 0 0 14.8 16q5.4 2.3 11.1.9c10.8-2.2 7.8-11.8 16.7-10.5 11 2 7.5 14.3 3.7 19.1-4 5.7-10.2 8.7-16.3 11.6-1 .7-2.2.4-2.7 1.5q-2.5 7-4 14.5m105-115.2v-1.5c.8-4.3 7-6.8 9.8-9A20 20 0 0 1 188.7 0a97 97 0 0 1 53.8 24.7 46 46 0 0 1 10.6 19 42 42 0 0 1 4 18.8c-1.3 19.1-11.4 39.8-29.3 47.8q1.2 9.8 3.3 19.7h-31.6q-1.6-9-4.8-18c-28.4-10.6-26.4-32-13.8-32 5.5-.3 6.4 6.3 10.4 8.8 10.7 5.7 18.8.6 25-5.8 9.3-9.1 10.3-25 5.2-35.5-6.1-12-16-11.4-27-8.8-5.1-.2-6.6-3-5.9-7-6.1 3.1-11.3 0-11.1-4.9-3.6 1.3-7-2.5-7.3-5.5-4.6.4-4.8-3.6-5.9-6.4"
    />
    <SvgPath
      fill="#502314"
      d="M22.4 41c2.6-3 4.7-6.7 7.9-9.6 22.8-20 60.2-4 47.2.6-6 1.2-9-4.4-3.8-5.8-15.3-7.3-38.2-1.8-47.4 12.2v.3q-.3 0-.4.2l-2.3 3.2q-.7.2-1.2-.6zm53.5-10.2c4.2 0 2.8-3.7-.6-3.4-4.5.3-3 3.6.6 3.4m4.8 64.5c-1.6-.4-3.3-1.5-3.8-3.3-1-3.4 1.1-8.5 5.3-7.6 4.7 2.6 4.1 9.3 0 11zm-2.5-4.7c.3 4.9 6.3 4.4 5.8-1.2-1.2-5.3-6.2-4-5.8 1.2m15-73.2c-.6 5-7.8 2.5-7.8-1.7.8-6.3 8-3 7.8 1.7m-3.3 1.4c4.1-.2.6-5.4-1.3-5-3.5.6-1.6 5 1.3 5"
    />
    <SvgPath
      fill="#502314"
      d="M82 20.5c-13.8-9.7-33.2-6.1-46.9.4-3.3 1.5-6 4.2-9.3 6.1-1.8-.7.3-2.1 1.2-2.7a56 56 0 0 1 47.2-9.4 49 49 0 0 1 13.2 7.7c1.4 2.3-.7 4.7-3.2 4.6-7 .2-6.9-6.8-2.2-6.6zm2 5.3c6.6-1-4-7-3.8-2 0 1.4 2.4 2.2 3.8 2m-21 7c-11.3-3.6-28.2 2.6-34 12.3q-1.4 2.2-2.7 4.8c-2.3 2-1-1.7-.3-3 5-10.5 17.8-16.3 28.8-16.6 3.9.1 17.1 1.2 13.9 6.3-2.4 3.8-10.2-.3-5.7-3.8m2 .6c-4 .6-1.2 4 1.5 3.2 2-1 .6-3.4-1.4-3.2m170.6 5.4c-1.3 1.4-2.4-2.3-3.7-3.2-9-14.4-32.6-19.5-47.5-12.5 1.6.6 3 1.2 2.6 3.4-2.5 5.4-13.2 1.5-7.3-2.7 15.2-9.8 41.3-5.6 52 8.8l3.9 5.3zm-53.4-14.4c-4 .4-3 4 .8 3.3 4.4-.5 2.7-3.7-.8-3.3m-5.6 68c-1.6-.4-2.4-1-3.2-2.5l-.6-1.4-.1-.8c-.4-2.1.7-4.5 2.6-6 5.3-3.1 9.8 8.8 1.8 10.7zm-2.5-5.2c.4 5.5 6 4.3 5.8-.2-.1-5.8-5.7-5-5.8.2"
    />
    <SvgPath
      fill="#502314"
      d="M176.1 17.5c5.4.2 4 7.4-2.7 6.6-2.2-.1-3.8-2.2-2.8-4.4 1.6-1.8 3.7-2.8 5.8-4C188.8 7 207 10 218.1 14c2.2.5 16.4 8 14.8 9.6-.3 1.6-6-4-7.6-4.4-13.8-8-36.1-11.5-49.2-1.7m.1 1.3c-5.6.7-5 4.3-1.8 4 4.2.1 4.5-3.6 1.8-4m19 11 1 1c1.6 2.7-2.7 5-5.2 4-2.2-.6-2.7-3.9-1-4.8a34 34 0 0 1 27.3.4c17.6 7.9 18 24.2 13 13.7-5.8-11.7-23.4-18-35.1-14.3m-2.1.6c-2-.1-3.5 1.9-1.5 3.2 3 .8 5.4-2.6 1.5-3.2M169.5 9.3c5.8.6 2.8 8.7-2.2 7.8-4.6-.9-1.8-8 2.2-7.8m1.9 3.7c-1-6.3-8.4 2.3-3.4 2.8 1.6.2 3.5-1.5 3.4-2.8"
    />
  </Svg>
);
export default BurgerInHandSvg;
