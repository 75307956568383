import { useEffect, useState } from 'react';

import { Asset } from 'expo-asset';

import { theme } from 'styles/theme';
import { Cognito } from 'utils/cognito';
import NativeAuthStorage from 'utils/cognito/storage';
import { initExpoFonts } from 'utils/expo';
import { WebLocalStorage } from 'utils/local-storage/web-local-storage';
import logger from 'utils/logger';

import { sanityAssets } from '../sanityAssets';

/**
 * -- Global App Initialization status --
 *
 * Loading - Blocks the app from rendering
 * Initialized - All data is there and the app can load without crashing
 * Error - Prevents the app from rendering at all - TODO: RN - Add better feedback to guests to resolve the errors (ex. Network errors)
 *
 * */
export enum initStatuses {
  LOADING = 'loading',
  INITIALIZED = 'initialized',
  ERROR = 'error',
}

export const useAppBlockerInit = () => {
  const [state, setState] = useState(initStatuses.LOADING);

  useEffect(() => {
    const prepare = async () => {
      try {
        // Startup congito with its configuration as early as possible.
        Cognito.configure();

        const importedSanityAssets = Object.entries(sanityAssets.current).map(
          ([, assetRequire]) => {
            return Asset.fromModule(assetRequire).downloadAsync();
          }
        );

        await Promise.all([
          NativeAuthStorage.sync(), // this is called internally by Cognito.configure() but it doesn't give us a promise!
          initExpoFonts(theme.cachedFonts),
          WebLocalStorage.sync(),
          ...importedSanityAssets,
        ] as Array<Promise<Asset | void>>);

        setState(initStatuses.INITIALIZED);
      } catch (error) {
        logger.error({ error, message: 'Bummer screen' });
        setState(initStatuses.ERROR);
      }
    };
    prepare();
  }, []);

  return state;
};
