import { useBreakpointValue } from 'native-base';
import { Platform } from 'react-native';

export const useIsMobileBreakpoint = (): boolean => {
  return useBreakpointValue({
    base: true,
    md: false,
  });
};

/** note: this will be TRUE for tablets in the APP in some cases.
 * If used incorrectly with pixel values like `margin: 20px` directly to components
 * it will cause native android crashes. Consider useIsDesktopWebBreakpoint to guarantee web.*/
export const useIsDesktopBreakpoint = (): boolean => {
  return useBreakpointValue({
    base: false,
    md: true,
  });
};

export const useIsDesktopWebBreakpoint = (): boolean => {
  return (
    useBreakpointValue({
      base: false,
      md: true,
    }) && Platform.OS === 'web'
  );
};

export const useIsDesktopLargeBreakpoint = (): boolean => {
  return useBreakpointValue({
    base: false,
    lg: true,
  });
};
