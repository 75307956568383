import { primitive } from './primitives';

import Styles from './index';

export type DesignTokens = {
  'background-active': any;
  'background-button-hover-reversed': any;
  'background-button-primary-default': any;
  'background-button-primary-default-reversed': any;
  'background-button-primary-hover': any;
  'background-button-secondary-hover': any;
  'background-checkbox-disabled': any;
  'background-checked': any;
  'background-chip': any;
  'background-chip-rewards': any;
  'background-chip-rewards-locked': any;
  'background-default': any;
  'background-dialog': any;
  'background-error': any;
  'background-focus': any;
  'background-focus-reversed': any;
  'background-footer': any;
  'background-hero-light': any;
  'background-hero-reversed': any;
  'background-icon-hover-focus': any;
  'background-icon-hover-focus-reversed': any;
  'background-inline-alert': any;
  'background-input': any;
  'background-input-disabled': any;
  'background-pattern': any;
  'background-skeleton-loader-cta-start': any;
  'background-skeleton-loader-cta-end': any;
  'background-switch-thumb': any;
  'background-switch-track': any;
  'background-switch-track-disabled': any;
  'border-color-active': any;
  'border-color-button-secondary': any;
  'border-color-button-secondary-reversed': any;
  'border-color-checked': any;
  'border-color-default': any;
  'border-color-disabled': any;
  'border-color-disabled-reversed': any;
  'border-color-error': any;
  'border-color-hover': any;
  'border-color-input': any;
  'border-color-input-default': any;
  'border-color-options': any;
  'border-color-reversed': any;
  'border-radius-button': any;
  'border-radius-chip': any;
  'border-radius-input': any;
  'border-radius-input-icon-hover-focus': any;
  'border-radius-pattern': any;
  'border-radius-pattern-interactive': any;
  'border-radius-toggle-default': any;
  'border-radius-toggle-end': any;
  'border-radius-toggle-start': any;
  'border-width-button-secondary': any;
  'border-width-input': any;
  'border-width-tabs': any;
  'box-shadow-button': any;
  'box-shadow-component': any;
  'box-shadow-component-reversed': any;
  'box-shadow-pattern': any;
  'box-shadow-pattern-reversed': any;
  'box-shadow-toggle': any;
  'drop-shadow-bottom-nav': any;
  'drop-shadow-component': any;
  'drop-shadow-top-nav': any;
  'height-button-large': any;
  'height-button-small': any;
  'height-checkbox-radio': any;
  'height-chip': any;
  'height-icon': any;
  'height-icon-small': any;
  'height-input': any;
  'height-tabs': any;
  'icon-active': any;
  'icon-button-primary': any;
  'icon-button-primary-reversed': any;
  'icon-button-reversed': any;
  'icon-button-secondary': any;
  'icon-button-tertiary-default': any;
  'icon-button-tertiary-hover': any;
  'icon-default': any;
  'icon-disabled': any;
  'icon-disabled-reversed': any;
  'icon-form-checkbox': any;
  'icon-form-input-action': any;
  'icon-form-input-default': any;
  'icon-reversed': any;
  'padding-button-icon-end': any;
  'padding-button-icon-start': any;
  'padding-button-large': any;
  'padding-button-small': any;
  'padding-input': any;
  'text-active': any;
  'text-button-primary': any;
  'text-button-primary-reversed': any;
  'text-button-reversed': any;
  'text-button-secondary': any;
  'text-button-tertiary-default': any;
  'text-button-tertiary-hover': any;
  'text-default': any;
  'text-disabled': any;
  'text-disabled-reversed': any;
  'text-error': any;
  'text-hero-light': any;
  'text-hero-reversed': any;
  'text-link-default': any;
  'text-link-hover': any;
  'text-link-reversed': any;
  'text-reversed': any;
  'text-style-back-nav': any;
  'text-style-bottom-nav': any;
  'text-style-weight-bottom-nav': any;
  'text-style-button-large': any;
  'text-style-button-small': any;
  'text-style-card-copy': any;
  'text-style-card-header': any;
  'text-style-chip': any;
  'text-style-chip-rewards': any;
  'text-style-dialog-copy': any;
  'text-style-dialog-header': any;
  'text-style-footer-large': any;
  'text-style-footer-location': any;
  'text-style-footer-small': any;
  'text-style-form-checkbox': any;
  'text-style-form-checkbox-header': any;
  'text-style-form-hint': any;
  'text-style-form-input': any;
  'text-style-form-label-default': any;
  'text-style-form-label-value': any;
  'text-style-form-legend': any;
  'text-style-form-options': any;
  'text-style-hero-header': any;
  'text-style-hero-subheader': any;
  'text-style-hero-terms': any;
  'text-style-inline-alert': any;
  'text-style-item-description-copy': any;
  'text-style-item-description-header': any;
  'text-style-item-description-price': any;
  'text-style-item-description-subheader': any;
  'text-style-list-item': any;
  'text-style-list-item-info': any;
  'text-style-list-item-restaurant': any;
  'text-style-module': any;
  'text-style-segment-header': any;
  'text-style-tabs': any;
  'text-style-top-nav': any;
  'text-style-top-nav-right': any;
  'text-tabs-default': any;
  'text-toggle-selected': any;
  'transition-component': any;
  'width-checkbox-radio': any;
  'width-icon': any;
  'width-icon-small': any;
  'background-contrast-loyalty': any;
  'background-contrast-loyalty-reversed': any;
  'background-badge-cart-entry': any;
  'background-icon-header-contrast': any;
  'background-menu-customization-image': any;
  'border-default': any;
  'icon-header-contrast': any;
  'text-accent-order-number': any;
  'text-loyalty-highlight': any;
  'pickup-timed-fire-selector-button-background': any;
  'tims-rewards-text-color': any;
  'padding-list-item-group-header': any;
  'margin-top-product-wizard-layout': any; // app navHeight because TH doesn't have menu option carousel
  'store-locator-header-background': any;
  // Global App Colors
  'top-nav-bar-background-color': any;
  'bottom-nav-bar-background-color': any;
  'main-menu-carousel-background': any;
  'bsm-default-background-color': any;
  'bsm-in-progress-background-color': any;
  'bsm-drop-shadow': any;

  'crown-color': any;
};

export const designTokens = {
  Styles,
  'border-color-input': primitive.$blackOpacity30,
  'border-color-input-default': primitive.$blackOpacity30,
  'border-color-options': primitive.$blackOpacity10,
  'border-color-reversed': primitive.$whiteOpacity10,
  'border-radius-button': '100%',
  'border-radius-chip': '4px',
  'border-radius-input': '4px',
  'border-radius-input-icon-hover-focus': '100%',
  'border-radius-pattern': '20px',
  'border-radius-pattern-interactive': '12px',
  'border-radius-toggle-default': '0',
  'border-radius-toggle-end': '0 4px 4px 0',
  'border-radius-toggle-start': '4px 0 0 4px',
  'border-width-button-secondary': '1px',
  'border-width-input': '1px',
  'border-width-tabs': '1px',
  'box-shadow-button': `0 0 0 4px ${primitive.$blackOpacity10}`,
  'box-shadow-component': `0 0 0 4px ${primitive.$blackOpacity10}`,
  'box-shadow-component-reversed': `0 0 0 4px ${primitive.$whiteOpacity30}`,
  'box-shadow-pattern': `0 0 0 4px ${primitive.$blackOpacity10}`,
  'box-shadow-pattern-reversed': `0 0 0 4px ${primitive.$whiteOpacity30}`,
  'box-shadow-toggle': `inset 0 0 0 4px ${primitive.$blackOpacity10}`,
  'drop-shadow-bottom-nav': `0 -1px 1px ${primitive.$blackOpacity10}`,
  'drop-shadow-component': `0 4px 4px ${primitive.$blackOpacity10}`,
  'drop-shadow-top-nav': `0 1px 1px ${primitive.$blackOpacity10}`,
  'height-button-large': '48px',
  'height-button-small': '32px',
  'height-checkbox-radio': '20px',
  'height-chip': '24px',
  'height-icon': '24px',
  'height-icon-small': '16px',
  'height-input': '48px',
  'height-tabs': '56px',
  'background-active': primitive.bk.$fieryRed,
  'background-button-hover-reversed': primitive.$whiteOpacity10,
  'background-button-primary-default': primitive.bk.$fieryRed,
  'background-button-primary-default-reversed': primitive.$white,
  'background-button-primary-hover': primitive.bk.$fieryRedDarken10,
  'background-button-secondary-hover': primitive.bk.$fieryRedOpacity10,
  'background-checkbox-disabled': primitive.$blackOpacity30,
  'background-checked': primitive.bk.$bbqBrown,
  'store-locator-header-background': primitive.bk.$bbqBrown,
  'background-chip': primitive.bk.$bbqBrown,
  'background-chip-rewards': primitive.bk.$bbqBrown,
  'background-chip-rewards-locked': primitive.bk.$mayoEggWhite,
  'background-default': primitive.bk.$mayoEggWhite,
  'background-dialog': primitive.$white,
  'background-error': primitive.$error,
  'background-focus': primitive.$blackOpacity4,
  'background-focus-reversed': primitive.$whiteOpacity4,
  'background-footer': primitive.bk.$bbqBrown,
  'background-hero-light': primitive.bk.$mayoEggWhite,
  'background-hero-reversed': primitive.bk.$bbqBrown,
  'background-icon-hover-focus': primitive.$blackOpacity4,
  'background-icon-hover-focus-reversed': primitive.$whiteOpacity4,
  'background-input': primitive.$white,
  'background-input-disabled': primitive.$blackOpacity4,
  'background-pattern': primitive.$white,
  'background-switch-thumb': primitive.$white,
  'background-switch-track': primitive.bk.$bbqBrown,
  'background-switch-track-disabled': primitive.$blackOpacity30,
  'border-color-active': primitive.bk.$fieryRed,
  'border-color-button-secondary': primitive.bk.$fieryRed,
  'border-color-button-secondary-reversed': primitive.$white,
  'border-color-checked': primitive.bk.$bbqBrown,
  'border-color-default': primitive.$blackOpacity10,
  'border-color-disabled': primitive.$blackOpacity30,
  'border-color-disabled-reversed': primitive.$whiteOpacity30,
  'border-color-error': primitive.$error,
  'border-color-hover': primitive.bk.$bbqBrown,
  'icon-active': primitive.bk.$fieryRed,
  'icon-button-primary': primitive.$white,
  'icon-button-primary-reversed': primitive.bk.$bbqBrown,
  'icon-button-reversed': primitive.$white,
  'icon-button-secondary': primitive.bk.$fieryRed,
  'icon-button-tertiary-default': primitive.bk.$bbqBrown,
  'icon-button-tertiary-hover': primitive.bk.$fieryRed,
  'icon-default': primitive.bk.$bbqBrown,
  'icon-disabled': primitive.$blackOpacity30,
  'icon-disabled-reversed': primitive.$whiteOpacity30,
  'icon-form-checkbox': primitive.$white,
  'icon-form-input-action': primitive.bk.$bbqBrown,
  'icon-form-input-default': primitive.$blackOpacity30,
  'icon-reversed': primitive.bk.$mayoEggWhite,
  'padding-button-icon-end': `${primitive.$spacing0} ${primitive.$spacing2} ${primitive.$spacing0} ${primitive.$spacing4}`,
  'padding-button-icon-start': `${primitive.$spacing0} ${primitive.$spacing4} ${primitive.$spacing0} ${primitive.$spacing2}`,
  'padding-button-large': `${primitive.$spacing0} ${primitive.$spacing5}`,
  'padding-button-small': `${primitive.$spacing0} ${primitive.$spacing4}`,
  'padding-input': `${primitive.$spacing0} ${primitive.$spacing4}`,
  'text-active': primitive.bk.$fieryRed,
  'text-button-primary': primitive.$white,
  'text-button-primary-reversed': primitive.bk.$bbqBrown,
  'text-button-reversed': primitive.$white,
  'text-button-secondary': primitive.bk.$fieryRed,
  'text-button-tertiary-default': primitive.bk.$bbqBrown,
  'text-button-tertiary-hover': primitive.bk.$fieryRed,
  'text-default': primitive.bk.$bbqBrown,
  'text-disabled': primitive.$disabledText,
  'text-disabled-reversed': primitive.$disabledTextReversed,
  'text-error': primitive.$error,
  'text-hero-light': primitive.bk.$bbqBrown,
  'text-hero-reversed': primitive.bk.$mayoEggWhite,
  'text-link-default': primitive.bk.$bbqBrown,
  'text-link-hover': primitive.bk.$fieryRed,
  'text-link-reversed': primitive.bk.$mayoEggWhite,
  'text-reversed': primitive.bk.$mayoEggWhite,
  'text-style-back-nav': 'var(--font-copyOne)',
  'text-style-bottom-nav': 'var(--font-copyTwo)',
  'text-style-weight-bottom-nav': 'normal',
  'text-style-button-large': 'var(--font-headerThree)',
  'text-style-button-small': primitive.bk.$headerFour,
  'text-style-card-copy': 'var(--font-copyTwo)',
  'text-style-card-header': 'var(--font-headerThree)',
  'text-style-chip': 'var(--font-copyTwo)',
  'text-style-chip-rewards': primitive.bk.$headerFour,
  'text-style-dialog-copy': 'var(--font-copyOne)',
  'text-style-dialog-header': 'var(--font-headerOne)',
  'text-style-footer-large': 'var(--font-headerThree)',
  'text-style-footer-location': primitive.bk.$headerFour,
  'text-style-footer-small': 'var(--font-copyTwo)',
  'text-style-form-checkbox': 'var(--font-copyOne)',
  'text-style-form-checkbox-header': 'var(--font-headerThree)',
  'text-style-form-hint': primitive.bk.$formUtility,
  'text-style-form-input': 'var(--font-copyOne)',
  'text-style-form-label-default': 'var(--font-copyOne)',
  'text-style-form-label-value': primitive.bk.$formUtility,
  'text-style-form-legend': 'var(--font-headerThree)',
  'text-style-form-options': primitive.bk.$formUtility,
  'text-style-hero-header': 'var(--font-hero)',
  'text-style-hero-subheader': 'var(--font-headerThree)',
  'text-style-hero-terms': 'var(--font-copyTwo)',
  'text-style-inline-alert': 'var(--font-copyTwo)',
  'text-style-item-description-copy': 'var(--font-copyTwo)',
  'text-style-item-description-header': 'var(--font-headerThree)',
  'text-style-item-description-price': primitive.bk.$headerFour,
  'text-style-item-description-subheader': primitive.bk.$formUtility,
  'text-style-list-item': 'var(--font-copyOne)',
  'text-style-list-item-info': 'var(--font-copyTwo)',
  'text-style-list-item-restaurant': 'var(--font-headerThree)',
  'text-style-module': 'var(--font-headerOne)',
  'text-style-segment-header': 'var(--font-headerThree)',
  'text-style-tabs': 'var(--font-copyOne)',
  'text-style-top-nav': 'var(--font-copyOne)',
  'text-style-top-nav-right': primitive.bk.$headerFour,
  'text-tabs-default': primitive.bk.$bbqBrown,
  'text-toggle-selected': primitive.$white,
  'transition-component': 'all .3s ease',
  'width-checkbox-radio': '20px',
  'width-icon': '24px',
  'width-icon-small': '16px',
  // Whitelabel-app-specific tokens to be added here, e.g.:
  // 'my-token-name': primitive.$black,
  'background-contrast-loyalty': primitive.bk.$bbqBrown,
  'background-contrast-loyalty-reversed': primitive.bk.$flamingOrange,
  'background-badge-cart-entry': primitive.$blackOpacity30,
  'background-icon-header-contrast': primitive.$blackOpacity4,
  'background-inline-alert': primitive.$blackOpacity4,
  'background-menu-customization-image': primitive.bk.$bbqBrown,
  'border-default': `1px solid ${primitive.$blackOpacity10}`,
  'icon-header-contrast': primitive.bk.$bbqBrown,
  'text-accent-order-number': primitive.bk.$fieryRed,
  'text-loyalty-highlight': primitive.bk.$bbqBrown,
  'pickup-timed-fire-selector-button-background': primitive.$white,
  'tims-rewards-text-color': primitive.th.$coffeeBrown,
  'padding-list-item-group-header': `${primitive.$spacing6} ${primitive.$spacing4} ${primitive.$spacing2}`,
  'margin-top-product-wizard-layout': '0',

  // Global App Colors
  'top-nav-bar-background-color': primitive.bk.$mayoEggWhite,
  'bottom-nav-bar-background-color': primitive.bk.$mayoEggWhite,
  'main-menu-carousel-background': primitive.bk.$mayoEggWhite,
  'bsm-default-background-color': primitive.$white,
  'bsm-in-progress-background-color': primitive.bk.$bbqBrown,
  'bsm-drop-shadow': '0 0 3px rgb(0 0 0 / 10%)',

  'crown-color': '#FFAA00',
};
