import React, { PropsWithChildren, ReactNode, useMemo } from 'react';

import { Button, ButtonProps } from '@rbilabs/universal-components';
import { GestureResponderEvent } from 'react-native';

import { useNavigation } from 'hooks/navigation/use-navigation';
import useTraceLoading from 'hooks/use-trace-loading';
import { checkForOSPrompts } from 'state/location/routes';
import {
  ClickEventComponentNames,
  CustomEventNames,
  EventTypes,
  useMParticleContext,
} from 'state/mParticle';
import { hapticImpact } from 'utils/haptic';

import {
  ActionButtonSizes,
  ActionButtonVariants,
  IActionButtonProps,
  IconElementProp,
} from './types';

const resizeIcon = (icon: IconElementProp | undefined, isSmallButton: boolean) =>
  icon && React.cloneElement(icon, { size: isSmallButton ? '3' : '4' });

const ActionButton = ({
  children,
  disabled,
  eventAttributes = {},
  eventName,
  eventType,
  skipLogEvent = false,
  isLoading = false,
  onPress,
  onNonVisualPress,
  to,
  linkPath,
  perceptible,
  hapticFeedbackStyle,
  variant,
  color,
  tabIndex = 0,
  fullWidth,
  rightIcon,
  leftIcon,
  startIcon,
  endIcon,
  width,
  noLogOnLongLoading,
  size = ActionButtonSizes.LARGE,
  keepIconSize,
  ...rest
}: PropsWithChildren<IActionButtonProps>) => {
  const { logEvent, logButtonClick } = useMParticleContext();
  const isImperceptible = !perceptible && disabled;
  const { navigate, linkTo } = useNavigation();
  const handleClick = (e: GestureResponderEvent) => {
    if (isLoading) {
      e.preventDefault();
      return;
    }
    if (isImperceptible) {
      e.preventDefault();
      return;
    } else if (perceptible && disabled) {
      e.preventDefault();
      void onNonVisualPress?.(e);
      return;
    }

    if (!skipLogEvent) {
      try {
        if (
          eventName &&
          ![CustomEventNames.BUTTON_CLICK, CustomEventNames.CLICK_EVENT].includes(eventName)
        ) {
          logEvent(eventName, eventType || EventTypes.Navigation, {
            Name: eventName,
            ...eventAttributes,
          });
        } else {
          logButtonClick(
            {
              attributes: {
                ...eventAttributes,
                component: ClickEventComponentNames.BUTTON,
                Name: getTextFromChildren(children),
                name: getTextFromChildren(children),
              },
            },
            { logDuplicateClickEvent: eventName === CustomEventNames.CLICK_EVENT }
          );
        }
      } catch (_) {
        // Nothing to do here
      }
    }

    if (hapticFeedbackStyle) {
      hapticImpact({ style: hapticFeedbackStyle });
    }

    if (onPress) {
      onPress(e);
    }

    if (linkPath) {
      linkTo(linkPath);
    } else if (to) {
      navigate(to);
    }
  };

  const buttonName = eventName || getTextFromChildren(children) || rest.testID;
  const isLoadingOrLoggingDisabled = noLogOnLongLoading ? false : isLoading;
  useTraceLoading({
    name: buttonName,
    isLoading: isLoadingOrLoggingDisabled,
  });

  const focusable = !(isLoading || isImperceptible || tabIndex === -1);
  const isSmallButton = size === ActionButtonSizes.SMALL;

  const iconProps: Partial<ButtonProps> = useMemo(
    () => ({
      leftIcon: keepIconSize ? leftIcon : resizeIcon(leftIcon, isSmallButton),
      rightIcon: keepIconSize ? rightIcon : resizeIcon(rightIcon, isSmallButton),
      startIcon: keepIconSize ? startIcon : resizeIcon(startIcon, isSmallButton),
      endIcon: keepIconSize ? endIcon : resizeIcon(endIcon, isSmallButton),
    }),
    [keepIconSize, endIcon, isSmallButton, leftIcon, rightIcon, startIcon]
  );

  const commonProps: Partial<ButtonProps> = {
    ...rest,
    ...iconProps,
    isDisabled: !!disabled,
    onPress: handleClick,
    isLoading,
    focusable,
    width: fullWidth ? 'full' : width,
    size: isSmallButton ? 'sm' : 'lg',
  };

  const variantProps: Partial<ButtonProps> = useMemo(() => {
    switch (variant) {
      case ActionButtonVariants.BOX_SHADOW_PRIMARY:
        return {
          shadow: '4',
        };
      case ActionButtonVariants.BOX_SHADOW_INVERSE:
        return {
          shadow: '4',
          variant: 'solid-reversed',
        };
      case ActionButtonVariants.TEXT_ONLY:
        return {
          variant: 'ghost',
          color,
        };
      case ActionButtonVariants.INVERSE:
        return {
          variant: 'solid-reversed',
        };
      case ActionButtonVariants.OUTLINE:
        return {
          variant: 'outline',
        };
      case ActionButtonVariants.OUTLINE_V2:
        return {
          variant: 'outline-v2',
        };
      case ActionButtonVariants.PRIMARY:
      default:
        return {};
    }
  }, [color, variant]);

  return (
    <Button {...commonProps} {...variantProps} dd-action-name={buttonName}>
      {children}
    </Button>
  );
};

// TODO: This wraps the button with an anchor tag. We should instead style the link as a button.
export const ActionLink = ({
  children,
  to,
  linkPath,
  fullWidth = false,
  onPress,
  ...props
}: React.PropsWithChildren<IActionButtonProps & { to: string }>) => {
  const { navigate, linkTo } = useNavigation();
  const { logEvent, logRBIEvent } = useMParticleContext();
  const isOSLink = to?.startsWith('/OS/');
  const handlePress = (e: GestureResponderEvent) => {
    if (isOSLink) {
      checkForOSPrompts(to, logEvent, logRBIEvent);
    } else if (linkPath) {
      linkTo(linkPath);
    } else {
      navigate(to);
    }
    if (onPress) {
      onPress(e);
    }
  };
  return (
    <ActionButton onPress={handlePress} fullWidth={fullWidth} {...props}>
      {children}
    </ActionButton>
  );
};

export default ActionButton;

// Return the first non-false string value
export function getTextFromChildren(children?: ReactNode): string {
  try {
    if (!children) {
      return '';
    }

    /* @ts-expect-error TS(2322) FIXME */
    return React.Children.map(children || '', child => {
      if (typeof child === 'string') {
        return child;
      } else if (child && React.isValidElement(child)) {
        return getTextFromChildren((child.props as { children?: ReactNode }).children);
      }
      return '';
    }).reduce((name, curr) => name || curr, '');
  } catch (_ex) {
    return '';
  }
}

export { ActionButtonVariants, ActionButtonSizes } from './types';
export type { IActionButtonProps, IActionLinkProps } from './types';
