import React from 'react';

import { useIntl } from 'react-intl';

import { HamburgerButton } from 'components/app-header/mobile-header/hamburger-button';
import {
  Header,
  LinkNoDecoration,
  MenuButtonContainer,
  MobileHeaderContainer,
} from 'components/app-header/styled';
import {
  NO_NETWORK_BACKGROUND_COLOR,
  NoNetworkHeader,
} from 'components/error-network/no-network-header';
import { LayoutContainer } from 'components/layout/styled';
import Logo from 'components/logo';
import { TopOrderStatusBar } from 'components/top-order-status-bar';
import { useTopOrderStatusBar } from 'components/top-order-status-bar/use-top-order-status-bar';
import { TopServiceMode } from 'components/top-service-mode';
import { useRoute } from 'hooks/navigation/use-route';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { useAuthContext } from 'state/auth';
import useAuthFlow from 'state/auth/hooks/use-auth-flow';
import { useMobileHeaderBackButton, useMobileHeaderComponent } from 'state/mobile-header-nav';
import { useNetworkContext } from 'state/network';
import { useReversedUIContext } from 'state/reversed-ui';
import { useStoreContext } from 'state/store';
import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';
import { routes } from 'utils/routing';

import SignupMobileHeader from '../signup-mobile-header';
import {
  HeaderContainer,
  StyledCenterSpacer,
  StyledLogo,
  StyledRightSpacer,
  TopServiceModeContainer,
} from '../styled';

import { CartButton } from './cart-button';
import { MobileHeaderCta } from './mobile-header-cta';

export const StandardMobileHeader = () => {
  const { noStoreSelected } = useStoreContext();
  const BackButtonComponent = useMobileHeaderBackButton();
  const { isNetworkConnected, isInternetReachable } = useNetworkContext();
  const { pathname } = useRoute();
  const topOrderStatusBarData = useTopOrderStatusBar();
  const showTopStatusBar = !!topOrderStatusBarData.heading;

  const { formatMessage } = useIntl();
  const { reversedUI } = useReversedUIContext();

  const isMobile = useIsMobileBreakpoint();

  const shouldShowHamburgerMenu =
    pathname.includes(formatMessage({ id: 'routes.termsConditionsRewards' })) ||
    pathname.includes(formatMessage({ id: 'routes.rewardsPolicy' })) ||
    pathname.includes(formatMessage({ id: 'routes.deliveryTerms' }));

  const { isInAuthFlow } = useAuthFlow();
  const { modalAuthIsOpen } = useAuthContext();

  if (isInAuthFlow && !modalAuthIsOpen) {
    return <SignupMobileHeader />;
  }

  const LeftSideComponent = (!shouldShowHamburgerMenu && BackButtonComponent) || HamburgerButton;

  const normalBackground = reversedUI
    ? theme.token('background-hero-reversed')
    : theme.token('background-hero-light');

  const showNoNetworkHeader = !isNetworkConnected || !isInternetReachable;

  return (
    <MobileHeaderContainer
      accessibilityLabel={formatMessage({ id: 'primaryNavLinks' })}
      testID="mobile-header"
      background={showNoNetworkHeader && isMobile ? NO_NETWORK_BACKGROUND_COLOR : normalBackground}
    >
      {showNoNetworkHeader && <NoNetworkHeader />}
      <LayoutContainer background={normalBackground}>
        <HeaderContainer reversed={reversedUI}>
          <Header>
            <MenuButtonContainer>
              <LeftSideComponent />
            </MenuButtonContainer>
            <StyledCenterSpacer>
              <LinkNoDecoration to={routes.base}>
                <StyledLogo testID="mobile-header-logo">
                  <Logo padding="0" />
                </StyledLogo>
              </LinkNoDecoration>
            </StyledCenterSpacer>
            <StyledRightSpacer>
              <MobileHeaderCta />
              <CartButton />
            </StyledRightSpacer>
          </Header>
        </HeaderContainer>
      </LayoutContainer>
      <TopServiceModeContainer>
        <LayoutContainer
          background={showTopStatusBar ? primitive.bk.$crunchyGreen : primitive.$white}
        >
          {topOrderStatusBarData.queryWasExecuted &&
            (showTopStatusBar ? (
              <TopOrderStatusBar data={topOrderStatusBarData} />
            ) : (
              !noStoreSelected && <TopServiceMode />
            ))}
        </LayoutContainer>
      </TopServiceModeContainer>
    </MobileHeaderContainer>
  );
};

export default function MobileHeader() {
  const HeaderComponent = useMobileHeaderComponent();

  return HeaderComponent ? <HeaderComponent /> : <StandardMobileHeader />;
}
