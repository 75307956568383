import React, { useContext, useEffect } from 'react';

import { Locale } from 'date-fns';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { LANGUAGES, LOCALES } from 'state/intl/types';
import { isTest } from 'utils/environment';
import { Region } from 'utils/environment/types';
import { ISOs } from 'utils/form/constants';
import { polyfillListFormatLocale } from 'utils/intl/polyfills/polyfill-list-format-locale';
import polyfillPluralRules from 'utils/intl/polyfills/polyfill-plural-rules-locale';
import noop from 'utils/noop';

import useIntlHook from './hook';
import { LocaleData } from './types';

export type SupportedReadableRegions = {
  US: string;
  CA: string;
};

export type SupportedReadableLanguages = {
  en?: string;
  es?: string;
  fr?: string;
};

export const defaultLocale = 'en-US';

export interface IIntlProviderContext {
  region: Region;
  feCountryCode: ISOs;
  language: LANGUAGES;
  locale: string;
  prevLocale: string;
  findSupportedRegion: Function;
  findSupportedLanguage: Function;
  readableRegions: SupportedReadableRegions;
  readableLanguages: SupportedReadableLanguages;
  regionDomainValues: any;
  setCurrentLocale: Function;
  clearRegionSpecificStorage: Function;
  supportedLocales: LocaleData[];
  messages: Record<string, string>;
  dateFormat: Locale;
  hasShownLocaleSelector: boolean;
  setHasShownLocaleSelector(): void;
  inferLanguage(): Promise<string>;
  setDeterminingLocaleComplete(): void;
  determiningLocaleComplete: boolean;
  shouldAutoShowSelector: boolean;
}

export const IntlContext = React.createContext<IIntlProviderContext>({} as IIntlProviderContext);

export const useLocale = () => useContext(IntlContext);

// NOTE: cypress-v2 test suite requirement
// Skip react-intl error logging when running in cypress
const skipErrorLogging = Boolean(isTest || window.Cypress);

// typescript 5.3.3 has an issue with this old IntlProvider code
const ReactIntlProviderAny = ReactIntlProvider as any;

export function IntlProvider({ children }: { children: React.ReactNode }) {
  const value = useIntlHook();
  useEffect(() => {
    polyfillListFormatLocale(value.locale as LOCALES);
    polyfillPluralRules(value.locale as LOCALES);
  }, [value.locale]);
  return (
    <IntlContext.Provider value={value}>
      <ReactIntlProviderAny
        defaultLocale={defaultLocale}
        locale={value.locale}
        messages={value.messages}
        {...(skipErrorLogging && { onError: noop })}
      >
        {children}
      </ReactIntlProviderAny>
    </IntlContext.Provider>
  );
}

export default IntlContext.Consumer;
