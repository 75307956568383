
  /* eslint-disable */
export const sanityAssets = {

    US: {
        ['assets/images/sanity/afb5db92f2494a70c9fb012e56d14c479fc18322-24x24.png']: require('./assets/images/sanity/afb5db92f2494a70c9fb012e56d14c479fc18322-24x24.png'),
  ['assets/images/sanity/fb6651c7ed2d3be4e6f5ac9499078cd98a0723d3-24x24.png']: require('./assets/images/sanity/fb6651c7ed2d3be4e6f5ac9499078cd98a0723d3-24x24.png'),
  ['assets/images/sanity/7ba95726ef76f181e658ceb137277d2a9be17db2-26x24.png']: require('./assets/images/sanity/7ba95726ef76f181e658ceb137277d2a9be17db2-26x24.png'),
  ['assets/images/sanity/7bd03133bdaa9c78bee7def654bb78e94a9e6fe0-23x22.png']: require('./assets/images/sanity/7bd03133bdaa9c78bee7def654bb78e94a9e6fe0-23x22.png'),
  ['assets/images/sanity/f58c57910809a05b52d5387472eeabdc7811d292-21x20.png']: require('./assets/images/sanity/f58c57910809a05b52d5387472eeabdc7811d292-21x20.png'),
  ['assets/images/sanity/19187be318cb77fab7ab5a41242d67556aac289f-21x21.png']: require('./assets/images/sanity/19187be318cb77fab7ab5a41242d67556aac289f-21x21.png'),
  ['assets/images/sanity/00553ce0b224cdeb1269bc2c756d8775c0b21a9f-24x24.png']: require('./assets/images/sanity/00553ce0b224cdeb1269bc2c756d8775c0b21a9f-24x24.png'),
  ['assets/images/sanity/94efcc5626019bed5b07ae8a4ed4cd6bada7b8e4-24x24.png']: require('./assets/images/sanity/94efcc5626019bed5b07ae8a4ed4cd6bada7b8e4-24x24.png'),
  ['assets/images/sanity/ca61127dd95f2e6473303320dba757fa9c1d45e1-24x24.png']: require('./assets/images/sanity/ca61127dd95f2e6473303320dba757fa9c1d45e1-24x24.png'),
  ['assets/images/sanity/d5f507c68d692504f2f10c68c981cf29c32ceb1e-24x24.png']: require('./assets/images/sanity/d5f507c68d692504f2f10c68c981cf29c32ceb1e-24x24.png')
    },
      
    CA: {
        ['assets/images/sanity/7403f126809b06ca7f51492b4e28e8e5fed5f58a-24x24.png']: require('./assets/images/sanity/7403f126809b06ca7f51492b4e28e8e5fed5f58a-24x24.png'),
  ['assets/images/sanity/6ddcc22d3f6a72a15bfbee5105b4e48d5e57c7f0-24x24.png']: require('./assets/images/sanity/6ddcc22d3f6a72a15bfbee5105b4e48d5e57c7f0-24x24.png'),
  ['assets/images/sanity/ffee0e87ee2cb7e3d839cb8f0692c4d5fa2d762a-24x24.png']: require('./assets/images/sanity/ffee0e87ee2cb7e3d839cb8f0692c4d5fa2d762a-24x24.png'),
  ['assets/images/sanity/d8c48963ebe2c5773d8737e9fd5ead3bf06aecda-24x24.png']: require('./assets/images/sanity/d8c48963ebe2c5773d8737e9fd5ead3bf06aecda-24x24.png'),
  ['assets/images/sanity/7ab4dab3de42e03da44101db27bb54fe71619ccc-25x24.png']: require('./assets/images/sanity/7ab4dab3de42e03da44101db27bb54fe71619ccc-25x24.png'),
  ['assets/images/sanity/aba96e1a3f6ed7c5dcd20208f9a3b5f22c56c7e9-24x24.png']: require('./assets/images/sanity/aba96e1a3f6ed7c5dcd20208f9a3b5f22c56c7e9-24x24.png'),
  ['assets/images/sanity/8f5cbf993261ef198218d0e3ae7ab7dafa62f15b-24x24.png']: require('./assets/images/sanity/8f5cbf993261ef198218d0e3ae7ab7dafa62f15b-24x24.png'),
  ['assets/images/sanity/8626d5ae74becfdb5513b4cb9a0740e6de2e7814-24x24.png']: require('./assets/images/sanity/8626d5ae74becfdb5513b4cb9a0740e6de2e7814-24x24.png'),
  ['assets/images/sanity/dcc32b86ea63041cf3d2fd36cb22722d37e4b68a-24x24.png']: require('./assets/images/sanity/dcc32b86ea63041cf3d2fd36cb22722d37e4b68a-24x24.png'),
  ['assets/images/sanity/db9308615763b6fb29f8db2812583cbcffa746a5-24x24.png']: require('./assets/images/sanity/db9308615763b6fb29f8db2812583cbcffa746a5-24x24.png'),
  ['assets/images/sanity/1244109f4455b86e3c76e63bc932d2a4c54fcdc3-280x200.png']: require('./assets/images/sanity/1244109f4455b86e3c76e63bc932d2a4c54fcdc3-280x200.png'),
  ['assets/images/sanity/5f38d86c52af98252c0f59f8563d1b49fe3ffc3a-280x200.png']: require('./assets/images/sanity/5f38d86c52af98252c0f59f8563d1b49fe3ffc3a-280x200.png')
    },
      
};
  