import React from 'react';

import { Box, HStack, Text, VStack } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { A11yWrapper } from 'components/a11y-wrapper';
import { Halal } from 'components/ucl/svgs/Halal';

import {
  Address,
  ServiceModes,
  Status,
  StoreCardViewContainer,
  disableColorProp,
} from '../store-card.styled';
import { StoreCardDetailsProps } from '../types';

import { StoreCardMessages } from './store-card-messages';

export const StoreCardDetails: React.FC<StoreCardDetailsProps> = ({
  errorMessage,
  storeDetails,
  distanceComponent,
}) => {
  const { formatMessage } = useIntl();
  const {
    restaurantStatus,
    serviceModesText,
    address,
    city,
    isHalal,
    isActive,
    accessInstructions,
    isNonTraditionalStore,
  } = storeDetails;

  return (
    <StoreCardViewContainer>
      {address && (
        <VStack focusable={false}>
          <Address {...(!isActive && disableColorProp)}>{address}</Address>
          <HStack alignItems={'baseline'} justifyContent={'start'} width={'full'}>
            <Box flexDirection={'row'} alignItems={'center'}>
              <Text variant="copyTwo" {...(!isActive && disableColorProp)}>
                {city}
              </Text>
              <Text>{' • '}</Text>
            </Box>
            <Box>
              <A11yWrapper alignItems={'center'}>
                <Status
                  isWithinOneHourOfClose={restaurantStatus.isWithinOneHourOfClose}
                  isOpen={restaurantStatus.isOpen && isActive}
                >
                  {restaurantStatus.text}
                </Status>
              </A11yWrapper>
            </Box>
            {isNonTraditionalStore && (
              <Box>
                <HStack alignItems={'center'} px={'$1'}>
                  <Text>{' • '}</Text>
                  <StoreCardMessages
                    errorMessage={errorMessage}
                    accessInstructions={isNonTraditionalStore ? accessInstructions : ''}
                  />
                </HStack>
              </Box>
            )}
          </HStack>
        </VStack>
      )}
      {isHalal && <Halal title={formatMessage({ id: 'isHalalTitle' })} />}
      {serviceModesText && (
        <HStack alignItems="center" justifyContent={'space-between'} focusable>
          <ServiceModes {...(!isActive && disableColorProp)}>{serviceModesText}</ServiceModes>
          {distanceComponent}
        </HStack>
      )}
    </StoreCardViewContainer>
  );
};
