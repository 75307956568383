import { useRef } from 'react';

import { logger } from 'utils/logger';

type AdditionalContext =
  | {
      // more info when useful to help us locate the code / situation causing this issue
      moreInfo: string;
    }
  | { [key: string]: any };

export enum LogUserErrorSeverity {
  /** Irrecoverable - user is unlikely to be able to convert at the selected store */
  Irrecoverable = 'irrecoverable',
  /** Recoverable - user could likely retry / fix validation errors and convert */
  Recoverable = 'recoverable',
  /** GeneralError - general error that isn't directly related to conversion */
  GeneralError = 'general-error',
  /** Informational - no changes are required to convert / informational */
  Informational = 'informational',
  /** Unknown - unclassified/unknown effect on conversion */
  Unknown = 'unknown',
}

/**
 * Useful in cases where the message is buried in conditional JSX. (see below example)
 * This will log the @errorMessage if function executed once per parent mount.
 *
 * ex:
 *   const getBoomMessage = useGetAndLogUserErrorMessage(formatMessage({ id: 'boom' }))
 * ...
 * ...
 *   {boom && <Text>{getBoomMessage()}</Text>}
 *
 * @returns the error message for display
 */
export const useGetAndLogUserErrorMessage = ({
  message,
  severity = LogUserErrorSeverity.Unknown,
  context,
}: {
  message: string;
  severity?: LogUserErrorSeverity;
  context?: AdditionalContext;
}) => {
  const lastFormattedErrorMessage = useRef<string | null>(null);

  return (context2?: AdditionalContext) => {
    try {
      if (lastFormattedErrorMessage.current === message) {
        return message;
      }

      lastFormattedErrorMessage.current = message;

      return logUserErrorMessage({
        message,
        severity,
        context: {
          ...context,
          ...context2,
        },
      });
    } catch (error) {
      logger.error({ message: 'useGetAndLogUserErrorMessage error', error });
    }
    return message;
  };
};

/**
 * Will log a truthy @errorMessage once per parent mount when @logWhenTrue is also true.
 */
export const useLogUserErrorMessage = ({
  message,
  logWhenTrue = true,
  severity = LogUserErrorSeverity.Unknown,
  context,
}: {
  message: string | null | undefined;
  logWhenTrue?: boolean;
  severity?: LogUserErrorSeverity;
  context?: AdditionalContext;
}) => {
  const lastErrorMessage = useRef<string | null>(null);

  if (logWhenTrue && !!message && lastErrorMessage.current !== message) {
    lastErrorMessage.current = message;
    logUserErrorMessage({ message, severity, context });
  }
};

/**
 * IMPORTANT DO NOT USE in an unprotected react render or hook, use useLogUserErrorMessage instead!
 * this will log a user error message on every invocation and return the message for convenience (for use in useEffect, etc.)
 * @returns the @errorMessage passed in for convenience purposes
 */
export const logUserErrorMessage = ({
  message,
  severity = LogUserErrorSeverity.Unknown,
  context,
}: {
  message: string;
  severity?: LogUserErrorSeverity;
  context?: AdditionalContext;
}) => {
  try {
    logger.info({
      message: `User Error Message - ${message}`,
      isUserErrorMessage: true,
      userErrorMessageContext: { ...context, severity },
    });
  } catch (error) {
    logger.error({ message: 'logUserErrorMessage error', error });
  }
  return message;
};
