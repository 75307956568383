import { Text } from '@rbilabs/universal-components';

import Link from 'components/link';

export const Terms = Text.withConfig({
  variant: 'bodyOne',
  fontSize: 'xs',
  marginY: '$4',
});

export const TermsLink = Link.withConfig({
  isUnderlined: true,
  isInline: true,
  fontSize: 'xs',
});
