import { useCallback } from 'react';

import { IHistoricalOrder, IServerOrder } from '@rbi-ctg/menu';
import { IOrderInProgressRecentOrder } from 'components/top-service-mode-notification/types';

export const useIOSLiveActivities = () => {
  const startActivityFn = useCallback(
    (serverOrder: IServerOrder | IHistoricalOrder, showImHere: boolean) => {},
    []
  );

  const updateActivityFn = useCallback(
    (
      serverOrder: IServerOrder | IHistoricalOrder | IOrderInProgressRecentOrder,
      showImHere: boolean
    ) => {},
    []
  );

  const endLiveActivityFn = useCallback(
    (serverOrder: IServerOrder | IHistoricalOrder | IOrderInProgressRecentOrder) => {},
    []
  );

  return {
    startActivity: startActivityFn,
    updateActivity: updateActivityFn,
    endActivity: endLiveActivityFn,
  };
};
