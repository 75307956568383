import { useCallback } from 'react';

import { isFunction, isNil } from 'lodash-es';
import { useIntl } from 'react-intl';

import { IRestaurant } from '@rbi-ctg/store';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { serviceModeList } from 'pages/cart/service-mode-details/service-mode-list';
import { useGeolocation } from 'state/geolocation';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { CustomEventNames, EventTypes, useMParticleContext } from 'state/mParticle';
import { useOrderContext } from 'state/order';
import { useRestaurantPosConnectivityStatus } from 'state/restaurant-connectivity-status';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import {
  checkIfWithinOneHourOfCloseToday,
  milesBetweenCoordinates,
  readableCloseHourToday,
  readableDistanceFromStore,
  useGetRestaurantAvailabilityFn,
  useIsMobileOrderingAvailable,
} from 'utils/restaurant';
import { TWELVE_HOUR_TIME_PARSE_FORMAT } from 'utils/restaurant/constants';
import { routes } from 'utils/routing';
import { getStoreSEOSlug } from 'utils/slugify';

interface IDefaultServiceModeOptions {
  restaurant: IRestaurant;
}

export function useDefaultServiceMode({ restaurant }: IDefaultServiceModeOptions) {
  const defaultServiceModeInStore = useFlag(LaunchDarklyFlag.DEFAULT_SERVICE_MODE_IN_STORE);
  const { serviceModeStatus } = useServiceModeStatus(restaurant);

  // get default service mode from ld
  const defaultAvailableServiceMode = serviceModeList.find(key => {
    return (
      key === defaultServiceModeInStore &&
      serviceModeStatus[key].available &&
      !serviceModeStatus[key].disabled
    );
  });
  // default service mode to:
  // 1) ld flag defaultServiceModeInStore
  // 2) the first available shown in cart when choosing a store
  // 3) the first service mode
  return (
    defaultAvailableServiceMode ||
    serviceModeList.find(key => {
      return serviceModeStatus[key].available && !serviceModeStatus[key].disabled;
    }) ||
    serviceModeList[0]
  );
}

interface IUseSelectRestaurantOptions {
  restaurant: IRestaurant;
}

interface ISelectRestaurantOptions {
  callback?: () => void;
  requestedServiceMode?: ServiceMode;
}
export function useSelectRestaurant({ restaurant }: IUseSelectRestaurantOptions) {
  const {
    selectStore,
    reorder: { pendingReorder, setShouldHandleReorder },
  } = useOrderContext();
  const { setServiceMode } = useServiceModeContext();

  const { logEvent } = useMParticleContext();

  const { storeLocatorCallbackUrl } = useLocationContext();
  const { navigate } = useNavigation();
  const enableOrderingFlag = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const enableOrdering = isNil(enableOrderingFlag) || enableOrderingFlag;
  const defaultServiceMode = useDefaultServiceMode({ restaurant });

  const navigateBack = useCallback(() => {
    navigate(storeLocatorCallbackUrl || routes.menu);
  }, [navigate, storeLocatorCallbackUrl]);

  const defaultSetRestaurantCallback = useCallback(
    (requestedServiceMode: ServiceMode) => async () => {
      if (!enableOrdering) {
        navigate(routes.offers);

        return;
      }

      // set service mode to ensure we aren't taken back to static menu
      // need to do this after previous checks so that we don't set service mode
      // when enableOrdering is false
      setServiceMode(requestedServiceMode);

      // if user has a pending reorder return and let the useEffect handle it below
      if (pendingReorder) {
        setShouldHandleReorder(true);
        return;
      }

      logEvent(CustomEventNames.ORDER_HERE_STORE_CONFIRMATION_FLOW, EventTypes.Navigation, {
        'Restaurant Id': restaurant._id,
        'Restaurant Name': restaurant.name,
      });
      navigateBack();
    },
    [
      enableOrdering,
      logEvent,
      navigate,
      navigateBack,
      pendingReorder,
      restaurant._id,
      restaurant.name,
      setServiceMode,
      setShouldHandleReorder,
    ]
  );

  const selectRestaurant = useCallback(
    ({ requestedServiceMode = defaultServiceMode, callback }: ISelectRestaurantOptions = {}) => {
      const selectStoreCallback = isFunction(callback)
        ? callback
        : defaultSetRestaurantCallback(requestedServiceMode);

      // FIXME: IRestaurantNode can't be safely cast to IStore
      selectStore(restaurant, selectStoreCallback, requestedServiceMode);
    },
    [defaultServiceMode, defaultSetRestaurantCallback, selectStore, restaurant]
  );

  return {
    selectRestaurant,
  };
}

interface IUseStoreCardOptions {
  restaurant: IRestaurant;
  shouldGetRemoteAvailabilityData?: boolean;
}

export function useStoreCard({
  restaurant,
  shouldGetRemoteAvailabilityData = true,
}: IUseStoreCardOptions) {
  const { activeCoordinates } = useGeolocation();
  const { region } = useLocale();
  const { formatMessage } = useIntl();
  const { serviceModeStatus } = useServiceModeStatus(restaurant);
  const driveThruOpen =
    serviceModeStatus.DRIVE_THRU.available && !serviceModeStatus.DRIVE_THRU.disabled;
  const dineInOpen = serviceModeStatus.EAT_IN.available && !serviceModeStatus.EAT_IN.disabled;
  const takeoutOpen = serviceModeStatus.TAKEOUT.available && !serviceModeStatus.TAKEOUT.disabled;
  const diningRoomOpen = dineInOpen || takeoutOpen;
  const curbsideOpen = serviceModeStatus.CURBSIDE.available && !serviceModeStatus.CURBSIDE.disabled;
  const openHours = driveThruOpen ? restaurant.driveThruHours : restaurant.diningRoomHours;
  const enableOrderingFlag = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const enableOrdering = isNil(enableOrderingFlag) || enableOrderingFlag;
  const areDiagnosticToolsEnabled = useFlag(LaunchDarklyFlag.ENABLE_INTERNAL_DIAGNOSTIC_TOOLS);
  const checkAvailability = useGetRestaurantAvailabilityFn();
  const timeFormatConfig = useConfigValue({
    key: 'timeFormat',
    defaultValue: TWELVE_HOUR_TIME_PARSE_FORMAT,
  });

  const closeHourTodayText = readableCloseHourToday(openHours, timeFormatConfig);

  const isWithinOneHourOfCloseToday = checkIfWithinOneHourOfCloseToday(openHours);
  const restaurantConnectivityStatus = useRestaurantPosConnectivityStatus({
    // @ts-expect-error TS(2322) FIXME: Type 'Maybe<string> | undefined' is not assignable... Remove this comment to see the full error message
    storeId: restaurant.number,
    skip: !shouldGetRemoteAvailabilityData,
  });

  // use the just-fetched restaurant if queried from backend
  const { isRestaurantPosOnline } = shouldGetRemoteAvailabilityData
    ? restaurantConnectivityStatus
    : {
        isRestaurantPosOnline: checkAvailability(restaurant),
      };

  let distanceText;
  if (activeCoordinates && restaurant.latitude && restaurant.longitude) {
    const distance = milesBetweenCoordinates(activeCoordinates, {
      lat: restaurant.latitude,
      lng: restaurant.longitude,
    });
    distanceText = readableDistanceFromStore(distance, region, formatMessage);
  }

  const isMobileOrderingAvailable = useIsMobileOrderingAvailable(restaurant);

  const storeSEOSlug = getStoreSEOSlug(restaurant);

  const { selectRestaurant } = useSelectRestaurant({ restaurant });

  return {
    storeSEOSlug,
    distanceText,
    curbsideOpen,
    dineInOpen,
    takeoutOpen,
    diningRoomOpen,
    driveThruOpen,
    openHours,
    selectRestaurant,
    isMobileOrderingAvailable,
    isWithinOneHourOfCloseToday,
    closeHourTodayText,
    isRestaurantPosOnline: !!isRestaurantPosOnline,
    enableOrdering,
    areDiagnosticToolsEnabled,
  };
}
