export const UUID_REGEX_STRING =
  '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';
export const GLOBAL_UUID_REGEX = new RegExp(UUID_REGEX_STRING, 'g');
export const LOYALTY_PHYSICAL_CARD_NUMBER_LENGTH = 12;
export const ENABLE_AUTO_SHOW_REGION_SELECTOR = false;
export const ENABLE_IDENTITY_SYNC_ON_LOGIN = false;
export const ENABLE_LEGACY_EMAIL_IMPORT = false;
export const ENABLE_LOYALTY_2_0_SEE_MORE_OPTIONS = false;
export const ENABLE_LOYALTY_20_ON_BOARDING_CONTENT_CARD_ON_THE_HOME_PAGE = false;
export const ENABLE_NO_PIN_CHECK_PREPAID_MERGE = false;
export const LOYALTY_CARD_DETAILS_POLL_INTERVAL_MS = 120000;
export const IPAD_ASPECT_RATIO = 9 / 16;
export const APP_MINIMUM_HEIGHT = 360;
export const WEB_APP_MAX_WIDTH = 768;
export const WEB_APP_MAX_WIDTH_DESKTOP_LARGE = 1200;
export const WEB_APP_MODAL_CONTENT_MAX_WIDTH = 564;
export const WEB_APP_CTA_MAX_WIDTH = 368;
