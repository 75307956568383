import React from 'react';

import { BrandLogo } from '@rbilabs/universal-components';

import { useReversedUIContext } from 'state/reversed-ui';

import { ILogoProps } from './types';

const Logo: React.FC<ILogoProps> = ({ reversed, ...props }) => {
  const { reversedUI } = useReversedUIContext();
  const isReversed = reversed ?? reversedUI;
  return (
    <BrandLogo
      accessibilityLabel="Burger King Logo. Navigate to Home"
      accessibilityRole="link"
      reversed={isReversed}
      variant="BkLogoPrimary"
      height="full"
      {...props}
    />
  );
};

export default Logo;
