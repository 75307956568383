import React, { FC } from 'react';

import { Center, Text } from '@rbilabs/universal-components';
import { TabBar as RNTabBar } from 'react-native-tab-view';

import { theme } from 'styles/configure-theme';

import {
  ColorOptions,
  GetTextColorParams,
  ITabBarProps,
  TabBarLabelType,
  TabBarTextProps,
} from './types';

const getBackgroundColor = (color?: ColorOptions) => {
  return color === 'inverted' ? theme.token('background-active') : 'transparent';
};

const getIndicatorColor = (color?: ColorOptions) => {
  return color === 'inverted' ? Styles.color.white : theme.token('background-active');
};

const getTextColor = ({ color, isDisabled, isFocused }: GetTextColorParams) => {
  if (isDisabled) {
    return theme.token(color === 'inverted' ? 'text-disabled-reversed' : 'text-disabled');
  } else if (color === 'inverted') {
    return Styles.color.white;
  } else if (color === 'secondary') {
    return Styles.color.accent;
  } else if (isFocused) {
    return theme.token('text-active');
  }
  return theme.token('text-default');
};

const TabBarLabel = ({ route, focused, tabBarTextProps, color }: TabBarLabelType) => {
  const textProps: Partial<TabBarTextProps> = {
    ...tabBarTextProps,
    color: getTextColor({ color, isDisabled: route.disabled, isFocused: focused }),
    bold: focused,
  };

  return (
    <Center>
      <Text {...textProps}>{route.title}</Text>
      {route.subTitle && <Text {...textProps}>subtitle</Text>}
    </Center>
  );
};

export const TabBar: FC<React.PropsWithChildren<ITabBarProps>> = ({
  color,
  backgroundColor,
  tabBarTextProps,
  ...props
}) => {
  // NOTE: This ensures more accurate width calculations for the tab components.
  // setting an initialLayout width is essential for the tab bar to calculate its dimensions accurately on the first render.
  // Without it, the layout might appear broken initially until the component re-renders with the correct dimensions.
  const totalTabs = props.navigationState.routes.length;
  const tabViewContainerWidth = props.layout.width;
  const tabWidth = tabViewContainerWidth / totalTabs;

  return (
    <RNTabBar
      {...props}
      onTabPress={ev => {
        if (ev.route.disabled) {
          ev.preventDefault();
        }
      }}
      pressColor="transparent"
      style={{
        backgroundColor: backgroundColor || getBackgroundColor(color),
      }}
      contentContainerStyle={{
        borderBottomColor: theme.token('border-color-default'),
      }}
      tabStyle={{
        width: tabWidth,
        alignItems: 'stretch',
      }}
      indicatorStyle={{
        backgroundColor: getIndicatorColor(color),
        height: 3,
      }}
      renderLabel={renderProps => (
        <TabBarLabel {...renderProps} tabBarTextProps={tabBarTextProps} color={color} />
      )}
    />
  );
};
