import { v4 as uuidv4 } from 'uuid';

import { IBackendCartEntries, ICartEntry } from '@rbi-ctg/menu';
import { CartEntryType } from 'utils/cart';
import { parseStringifiedJSON } from 'utils/parse-string';

const emptyJsonObj = '{}';

export const mapBackendToFrontend = (
  cartEntry: IBackendCartEntries,
  isChildOfCombo?: boolean
): ICartEntry => {
  const id = cartEntry.sanityId || '';
  return {
    _id: id,
    cartId: uuidv4(),
    children: cartEntry.children
      ? cartEntry.children.map(entry =>
          mapBackendToFrontend(
            entry,
            [CartEntryType.combo, CartEntryType.comboSlot].includes(cartEntry.type)
          )
        )
      : [],
    image: parseStringifiedJSON({ value: cartEntry.image || emptyJsonObj }),
    name: cartEntry.name || '',
    pickerSelections: parseStringifiedJSON({ value: cartEntry.pickerSelections || emptyJsonObj }),
    // NOTE: Currently this util does not price anything. It will return zero for all prices
    // If you need prices the useMapToFrontendCartEntry hook will reprices/repair selections for you. (https://github.com/rbilabs/ctg-whitelabel-app/blob/master/workspaces/frontend/src/state/order/hooks/use-map-to-frontend-cart-entry.ts)
    quantity: cartEntry.quantity,
    reorder: true,
    isDonation: cartEntry.isDonation ?? false,
    isExtra: cartEntry.isExtra ?? false,
    sanityId: id,
    type: cartEntry.type,
    url: cartEntry.url,
    offerVendorConfigs: cartEntry.offerVendorConfigs,
    vendorConfigs: cartEntry.vendorConfigs,
    // prevents double price in the cart
    // we don't want to include price for direct children items of combos and comboslots (main items)
    ...(!isChildOfCombo && { price: 0 }),
  };
};
