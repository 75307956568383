import * as Application from 'expo-application';
import * as Updates from 'expo-updates';

import versions from '../../../versions';

const webVersion = versions
  ? `${versions.APP_VERSION_MAJOR}.${versions.APP_VERSION_MINOR}.${versions.APP_VERSION_PATCH}`
  : `UNKNOWN`;

/** ex 7.1.2 on mobile / web */
export const appVersion = Application.nativeApplicationVersion || webVersion;

/** ex:
 * - mobile: 7.1.2(2)
 * - web: 7.1.2
 * */
export const appVersionWithBuildNumber = Updates.runtimeVersion || webVersion;
