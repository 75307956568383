import { ReactNode, useState } from 'react';

import { useWindowDimensions } from 'react-native';

import { ActionsheetContent } from 'components/action-sheet';
import { FocusableContainer } from 'components/modal/focusable-container';
import { KeyboardAwareView } from 'components/ucl/keyboard-aware-view';
import { useScrollRestoration } from 'hooks/use-scroll-restoration';

export const ModalAuthActionSheetContent: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { height: windowHeight } = useWindowDimensions();
  const [scrollViewHeight, setScrollViewHeight] = useState<number | undefined>(undefined);
  const scrollProps = useScrollRestoration('modal-auth');

  const handleScrollViewLayout = (event: any) => {
    if (!scrollViewHeight) {
      const { height } = event.nativeEvent.layout;
      const twentyPercentOfWindowHeight = (windowHeight * 20) / 100;
      const finalHeight =
        height >= windowHeight ? windowHeight - twentyPercentOfWindowHeight : height;
      setScrollViewHeight(finalHeight);
    }
  };
  return (
    <ActionsheetContent alignItems="stretch">
      <KeyboardAwareView
        onLayout={handleScrollViewLayout}
        style={{ maxHeight: scrollViewHeight }}
        keyboardShouldPersistTaps="handled"
        withScroll
        {...scrollProps}
      >
        <FocusableContainer>{children}</FocusableContainer>
      </KeyboardAwareView>
    </ActionsheetContent>
  );
};
