import React from 'react';

import { Svg, SvgPath, SvgProps, registerSVG } from '@rbilabs/universal-components';
import { Circle } from 'react-native-svg';

export function BkUpsellPlus(props: SvgProps) {
  return (
    <Svg width="44" height="44" viewBox="0 0 44 44" fill="none" {...props}>
      <Circle cx="21.4496" cy="21.45" r="12" fill="#D62300" />
      <SvgPath
        d="M27.05 22.2501H22.25V27.0501H20.65V22.2501H15.85V20.6501H20.65V15.8501H22.25V20.6501H27.05V22.2501Z"
        fill="white"
      />
    </Svg>
  );
}

registerSVG('BkUpsellPlus', BkUpsellPlus);
