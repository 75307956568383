import { Svg, SvgImage, SvgProps, SvgRect, registerSVG } from '../../../components/svg';

export function GooglePay(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 753 400">
      <SvgRect y="6" width="24" height="12.5217" fill="url(#pattern0)" />
      <SvgImage href={require('./GooglePay.png')} />
    </Svg>
  );
}

registerSVG('GooglePay', GooglePay);
