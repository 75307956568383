import { useFeatureWalmartUiQuery } from 'generated/sanity-graphql';

const FEATURE_LOYALTY_WALMART_SINGLETON_ID = 'walmart-singleton';

export const useLoyaltyWalmart = () => {
  const { data, loading } = useFeatureWalmartUiQuery({
    variables: { featureWalmartUIId: FEATURE_LOYALTY_WALMART_SINGLETON_ID },
    fetchPolicy: 'cache-and-network',
  });

  return {
    data: data?.Walmart,
    loading,
  };
};
