import { useMemo } from 'react';

import { useFeatureOnboardingQuery } from 'generated/sanity-graphql';
import { maybeLocale } from 'utils/graphql';
import { getLocaleTextBlock } from 'utils/sanity';

import { IFeatureOnboardingScreenContent, IOnboardingScreen } from '../types';

export const useOnboardingScreens = () => {
  const { data } = useFeatureOnboardingQuery();

  const featureOnboardings = useMemo(
    () =>
      data?.allFeatureOnboardings?.map(onboarding => {
        const featureOnboardingScreens = onboarding.screens?.reduce(
          (acc: IFeatureOnboardingScreenContent[], screen: IOnboardingScreen) => {
            if (screen?.__typename !== 'ImageWithHeaderAndText') {
              return acc;
            }
            const buttonText = screen?.buttonAction?.actionText?.locale ?? '';
            const buttonUrl = screen?.buttonAction?.actionUrl?.locale ?? '';
            return [
              ...acc,
              {
                id: screen._id,
                image: maybeLocale(screen.image),
                imageDescription: screen?.imageDescription?.locale ?? '',
                textContent: getLocaleTextBlock(screen, 'textContent'),
                buttonText,
                buttonUrl,
                secondaryButtonText: screen?.secondaryButtonAction?.actionText?.locale ?? '',
                secondaryButtonUrl: screen?.secondaryButtonAction?.actionUrl?.locale ?? '',
              },
            ];
          },
          []
        );
        return {
          id: onboarding._id,
          featureOnboardingChannel: onboarding.channel ?? '',
          featureOnboardingTriggerRule: onboarding.triggerRule ?? '',
          featureOnboardingScreens,
        };
      }),
    [data?.allFeatureOnboardings]
  );

  return {
    featureOnboardings,
  };
};
