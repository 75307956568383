import { Svg, SvgPath, SvgProps, SvgRect, registerSVG } from '../../../components/svg';

export function Cash(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <SvgPath
        d="M11.85 16.767H12.318C12.656 16.767 12.864 16.572 12.864 16.221V15.753C12.864 15.649 12.929 15.571 13.033 15.545C14.177 15.259 14.905 14.336 14.905 13.166C14.905 11.658 13.982 10.969 12.669 10.579C11.356 10.189 10.966 9.916 10.966 9.435C10.966 9.019 11.395 8.694 11.993 8.694C12.578 8.694 12.929 9.032 13.215 9.63C13.488 10.176 14.606 9.942 14.489 9.136C14.437 8.759 14.385 8.46 14.346 8.213C14.268 7.875 14.008 7.641 13.618 7.511C13.41 7.446 13.254 7.394 13.046 7.342C12.929 7.329 12.864 7.251 12.864 7.147V6.679C12.864 6.328 12.656 6.133 12.318 6.133H11.85C11.499 6.133 11.291 6.315 11.291 6.679V7.108C11.291 7.225 11.239 7.303 11.135 7.329C9.86104 7.641 9.10704 8.434 9.10704 9.604C9.10704 11.034 10.082 11.749 11.187 12.061C12.318 12.386 12.773 12.711 12.773 13.361C12.773 13.816 12.448 14.193 11.902 14.193C11.317 14.193 10.784 13.829 10.485 13.062C10.199 12.282 8.89904 12.607 9.06804 13.413C9.15904 13.842 9.21104 14.362 9.26304 14.648C9.31504 14.947 9.58804 15.129 9.92604 15.272C10.186 15.389 10.576 15.48 11.122 15.571C11.239 15.584 11.291 15.649 11.291 15.766V16.221C11.291 16.585 11.499 16.767 11.85 16.767Z"
        fill="#F5EBDC"
      />
      <SvgRect y="4" width="24" height="16" rx="2" fill="white" />
      <SvgRect
        x="0.5"
        y="4.5"
        width="23"
        height="15"
        rx="1.5"
        stroke="black"
        fill="white"
        strokeOpacity="0.3"
      />
      <SvgPath
        d="M12.0739 17V15.8333C11.5341 15.7407 11.0914 15.5394 10.7457 15.2292C10.4001 14.919 10.1515 14.5278 10 14.0556L10.7955 13.7361C10.9564 14.1806 11.1884 14.5116 11.4915 14.7292C11.7945 14.9468 12.1591 15.0556 12.5852 15.0556C13.0398 15.0556 13.4138 14.9444 13.7074 14.7222C14.0009 14.5 14.1477 14.1944 14.1477 13.8056C14.1477 13.3981 14.0175 13.0833 13.7571 12.8611C13.4967 12.6389 13.0066 12.412 12.2869 12.1806C11.6051 11.9676 11.0961 11.6852 10.7599 11.3333C10.4238 10.9815 10.2557 10.5417 10.2557 10.0139C10.2557 9.50463 10.4238 9.0787 10.7599 8.73611C11.0961 8.39352 11.5341 8.19907 12.0739 8.15278V7H12.9261V8.15278C13.3523 8.19907 13.7192 8.33565 14.027 8.5625C14.3348 8.78935 14.5691 9.0787 14.7301 9.43056L13.9347 9.76389C13.8021 9.46759 13.6245 9.25231 13.402 9.11806C13.1795 8.9838 12.8883 8.91667 12.5284 8.91667C12.0928 8.91667 11.7472 9.01389 11.4915 9.20833C11.2358 9.40278 11.108 9.66667 11.108 10C11.108 10.3519 11.25 10.6366 11.5341 10.8542C11.8182 11.0718 12.3438 11.2963 13.1108 11.5278C13.7547 11.7222 14.2306 12.0023 14.5384 12.3681C14.8461 12.7338 15 13.1944 15 13.75C15 14.3333 14.8248 14.8032 14.4744 15.1597C14.1241 15.5162 13.608 15.7454 12.9261 15.8472V17H12.0739Z"
        fill="#502314"
        stroke="#502314"
      />
    </Svg>
  );
}

registerSVG('Cash', Cash);
