import { isString } from 'lodash-es';

import { IAmplitudeProduct } from 'state/amplitude/types';
import { CustomEventNames } from 'state/mParticle/constants';
import Braze from 'utils/braze';

export function logBrazeCustomEvent(eventName: string, attributes?: Record<string, any>) {
  Braze.logCustomEvent(eventName, attributes);
}

/**
 * Given the mParticle eCommerce arguments, this method translates those to equivalent Braze events
 * and logs an event to Braze.
 * Here we do our best to emulate the same logic used in the mParticl web SDK to maintain parity on the same
 * event naming and attribute naming schemas so our Braze events match correctly.
 *
 * The mParticle web SDK code which implements similar logic we are trying to model is found here:
 * https://github.com/mParticle/mparticle-web-sdk/blob/acd756b041262e033ec6adead16a7135bacd1b0c/src/ecommerce.js#L441
 *
 * @param mParticleProductActionType the type of action
 * @param products list of Products and information being logged
 * @param attributes list of other attributes to log adittionally
 */
export const logBrazeCommerceEvent = (
  customEventName: CustomEventNames,
  products: IAmplitudeProduct[],
  attributes: { Currency?: string } | null | undefined
) => {
  //@ts-ignore - we log the products array below, there's no need to log them again.
  if (customEventName === CustomEventNames.E_COMMERCE_PURCHASE_ITEM) {
    return;
  }
  if (!attributes) {
    attributes = {};
  }

  if (attributes.Currency) {
    attributes['Currency Code'] = attributes.Currency;
  }

  const productList = Array.isArray(products) ? products : [products];
  sanitizeProductList(productList);

  if (customEventName === CustomEventNames.E_COMMERCE_PURCHASE) {
    logBatchedBrazeCommerceEvent(customEventName, productList, attributes);
  }

  productList.forEach(product => {
    logSingleBrazeCommerceEvent(customEventName, product, attributes);
  });
};

const logSingleBrazeCommerceEvent = (
  customEventName: CustomEventNames,
  product: IAmplitudeProduct,
  attributes: { Currency?: string } | null | undefined
) => {
  const brazeEventName = amplitudeEcommerceEventToBrazeEventName(customEventName, false);
  const brazeAttributes = {
    ...product,
    ...attributes,
  };
  const convertedAttributes = convertAmplitudeAttributesToBrazeAttributes(brazeAttributes);

  Braze.logCustomEvent(brazeEventName, convertedAttributes);

  if (customEventName === CustomEventNames.E_COMMERCE_PURCHASE) {
    const currencyCode = attributes?.Currency ?? '';
    Braze.logPurchase(product.id, product.price.toString(), currencyCode, product.quantity, {
      ...convertedAttributes,
    });
  }
};

const logBatchedBrazeCommerceEvent = (
  customEventName: CustomEventNames,
  products: IAmplitudeProduct[],
  attributes: object | null | undefined
) => {
  const brazeEventName = amplitudeEcommerceEventToBrazeEventName(customEventName, true);
  const brazeAttributes = {
    ...attributes,
  };

  const convertedAttributes = convertAmplitudeAttributesToBrazeAttributes(brazeAttributes);

  brazeAttributes['Product Count'] = products ? products.length : 0;
  Braze.logCustomEvent(brazeEventName, convertedAttributes);
};

const sanitizeProductList = (productList: IAmplitudeProduct[]) => {
  productList.forEach(product => {
    if (product.total_product_amount) {
      product.total_product_amount = sanitizeAmount(product.total_product_amount);
    }
    if (product.price) {
      product.price = sanitizeAmount(product.price);
    }
    if (product.quantity) {
      product.quantity = sanitizeAmount(product.quantity);
    }
  });
};

const sanitizeAmount = (amount: string | number) => {
  if (isString(amount)) {
    return parseNumber(parseFloat(amount));
  }
  return parseNumber(amount);
};

const parseNumber = (value: number): number => {
  if (isNaN(value) || !isFinite(value)) {
    return 0;
  }
  return isNaN(value) ? 0 : value;
};

/**
 * Translates the Amplitude commerce event name to the equivalent Braze custom event name for logging
 * https://github.com/mParticle/mparticle-web-sdk/blob/acd756b041262e033ec6adead16a7135bacd1b0c/src/ecommerce.js#L136
 * @param customEventName
 * @param batched boolean for whether or not this event represents a single product or multiple products
 */
export const amplitudeEcommerceEventToBrazeEventName = (
  customEventName: CustomEventNames,
  batched: boolean
) => {
  const brazeEventName = amPlitudeToBrazeMapping.get(customEventName);
  return `eCommerce - ${brazeEventName} - ${batched ? 'Total' : 'Item'}`;
};

// Get expanded names
const amPlitudeToBrazeMapping = new Map<CustomEventNames, string>([
  [CustomEventNames.E_COMMERCE_ADD_TO_CART, 'add_to_cart'],
  [CustomEventNames.E_COMMERCE_REMOVE_FROM_CART, 'remove_from_cart'],
  [CustomEventNames.E_COMMERCE_CHECKOUT, 'checkout'],
  [CustomEventNames.CLICK_EVENT, 'click'],
  [CustomEventNames.E_COMMERCE_VIEW_DETAIL, 'view_detail'],
  [CustomEventNames.E_COMMERCE_PURCHASE, 'purchase'],
]);

const convertAmplitudeAttributesToBrazeAttributes = (brazeAttributes: Record<string, any>) => {
  const {
    products,
    id,
    total_product_amount,
    name,
    price,
    quantity,
    custom_attributes,
    ...rest
  } = brazeAttributes;
  const convertedAttributes = { ...rest };

  if (custom_attributes && typeof custom_attributes === 'object') {
    convertedAttributes.Attributes = {
      ...custom_attributes,
    };
  }

  if (custom_attributes?.couponCode) {
    convertedAttributes['Coupon Code'] = custom_attributes?.couponCode;
  }
  if (name) {
    convertedAttributes.Name = name;
  }
  if (id) {
    convertedAttributes.Id = id;
    convertedAttributes.Sku = id;
  }
  if (price) {
    convertedAttributes['Item Price'] = price;
    convertedAttributes.Price = price;
  }

  if (quantity) {
    convertedAttributes.Quantity = quantity;
  }
  convertedAttributes['Total Product Amount'] = total_product_amount || 0;
  return convertedAttributes;
};
