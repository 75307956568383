import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { SetState } from '@rbi-ctg/frontend';
import {
  ICommunicationPreference,
  IFavoriteStore,
  ISocialLoginInput,
  ProviderType,
} from 'generated/graphql-gateway';
import { UserDetails } from 'state/auth/hooks/types';
import { ISignIn, ISignUpResult } from 'state/auth/hooks/use-account-authentication';
import { ISignupForm } from 'utils/form/types';

export interface OpenSignInModalProps {
  errorMessage?: string;
  shouldWaitLoginForVendor?: boolean;
  shouldDisableHeadlineLinks?: boolean;
  navigateOnSuccessRoute?: string;
  trigger?: ModalAuthState['trigger'];
}

export interface OnSignInSuccessProps {
  navigateOnSuccessRoute?: string;
}

export interface ModalAuthHook {
  modalAuthState: ModalAuthState;
  setModalAuthState: (modalAuthstate: ModalAuthState) => void;
  modalAuthIsOpen: boolean;
  openSignInModal: (params?: OpenSignInModalProps) => void;
  onSignInSuccess: (params?: OnSignInSuccessProps) => void;
  closeModalAuth: () => void;
}

export type UserInfo = {
  dob: string;
  name: string;
  phoneNumber: string | null;
  promotionalEmails: boolean;
  isoCountryCode: string;
  zipcode: string;
  defaultReloadAmt: number;
  defaultCheckoutPaymentMethodId?: string;
  defaultReloadPaymentMethodId?: string;
  autoReloadEnabled: boolean;
  autoReloadThreshold: number;
  adsPersonalizationOptOut?: boolean;
};

export type SignupArgs = {
  email: string;
  name: string;
  phoneNumber: string;
  country: string;
  wantsPromotionalEmails: boolean;
  crmAttributes?: { source: string };
};

export interface IAuthRouteParams {
  showOtpForm?: boolean;
  activeRouteIsSignIn?: boolean;
  attemptedSignUpWithExistingEmail?: boolean;
  hasSignedOut?: boolean;
  errorMessage?: string;
  triggerSignInAccessibility?: boolean;
  email?: string;
  currentTabKey?: string;
}

export type UpdateUserInfoOptions = {
  shouldThrowException?: boolean;
  shouldMuteUserInfoErrors?: boolean;
};

export enum ModalAuthScreen {
  SIGN_IN = 'sign-in',
  SIGN_UP = 'sign-up',
  OTP = 'otp',
  CLOSED = 'closed',
}

/**
 * Defines the context or event that triggers the display of the ModalAuth component.
 * This helps control when and where the authentication modal should appear.
 */
export enum ModalAuthTrigger {
  Global = 'global',
  Campaign = 'campaign',
}

export type ModalAuthState = {
  onClose?: () => void;
  onPreSignIn?: () => void | Promise<void>;
  screen?: ModalAuthScreen;
  providerType?: ProviderType;
  user?: Partial<Pick<ISignupForm, 'email' | 'name'>>;
  onSignInSuccess?: any;
  heading?: string;
  errorMessage?: string;
  shouldDisplaySignInSuccessMessage?: boolean;
  shouldWaitLoginForVendor?: boolean;
  shouldDisableHeadlineLinks?: boolean;
  /**
   * Set this flag to `true` if you want to just execute the callback function(if exists) set on the state
   */
  shouldSkipDefaultSignInCallback?: boolean;
  loginForVendorIsLoading?: boolean;
  disableEmailInput?: boolean;
  trigger?: ModalAuthTrigger;
  navigateOnSuccessRoute?: string;
};

export interface AuthInterface {
  // Computed values
  loading: boolean;
  originLocation: null | string;
  user: null | UserDetails;
  otpAuthError: null | string;
  modalAuthState: ModalAuthState;
  modalAuthIsOpen: boolean;
  // Functions
  getCurrentSession(): Promise<null | CognitoUserSession>;
  refetchCurrentUser(): Promise<void>;
  refreshCurrentUser(): Promise<void>;
  refreshCurrentUserWithNewSession(): Promise<void>;
  isAuthenticated: boolean;
  setOriginLocation(location: null | string): void;
  signIn(args: ISignIn): Promise<void>;
  /**
   * Signs out the user.
   * @param isGlobalSignOut If `true` the user will be sign out from all devices.
   */
  signOut(isGlobalSignOut?: boolean): Promise<void>;
  signUp(
    args: SignupArgs,
    signInOverride?: (args: ISignIn) => Promise<void>,
    authenticationMethod?: string,
    socialAuthenticationType?: ProviderType
  ): Promise<ISignUpResult>;
  socialLogin(args: ISocialLoginInput): Promise<void>;
  updateUserInfo(args: UserInfo, options?: UpdateUserInfoOptions): Promise<void>;
  updateUserCommPrefs(
    commPrefs: Array<ICommunicationPreference>,
    options?: {
      shouldThrowException?: boolean;
    }
  ): Promise<void>;
  updateUserFavStores(favStores: Array<IFavoriteStore>): Promise<void>;
  useUpdateMeMutationLoading: boolean;
  validateLogin(args: { jwt: string; username: string }): Promise<void>;
  validateLoginOtp(args: { otpCode: string }): Promise<void>;
  setOtpAuthError(error: null | string): void;
  setModalAuthState: ModalAuthHook['setModalAuthState'];
  openSignInModal: ModalAuthHook['openSignInModal'];
  closeModalAuth: ModalAuthHook['closeModalAuth'];
  onSignInSuccess: ModalAuthHook['onSignInSuccess'];
  /**
   * Used to determine if an already logged-in user is currently re-authenticating
   * and avoid navigating away from auth-guarded components.
   * This is useful in certain data-sensitive operations to make sure that the
   * user performing the action is the real owner of the account. e.g., editing
   * user account information.
   */
  isReAuthenticating: boolean;
  setIsReAuthenticating: (value: boolean) => void;
  accountCallbackUrl: string;
  setAccountCallbackUrl: SetState<string>;
}
