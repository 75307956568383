import { Box, Image, Text } from '@rbilabs/universal-components';

import { ResizeMode } from 'components/picture/types';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { primitive } from 'styles/constants/primitives';

export const Container = Box.withConfig({
  paddingBottom: '$6',
  width: '100%',
  backgroundColor: primitive.$white,
  borderRadius: 'xl',
});

export const Title = Text.withConfig({
  variant: 'headerThree',
  fontSize: 'md',
  marginBottom: '$2',
  marginTop: '$6',
  textAlign: 'center',
});

export const BenefitText = Text.withConfig({
  variant: 'headerThree',
  fontWeight: 'bold',
  fontSize: '6xl',
  marginBottom: '$2',
  textAlign: 'center',
  lineHeight: 'md',
});

export const ContentText = Text.withConfig({
  variant: 'headerThree',
  fontSize: 'md',
  fontWeight: 400,
  color: primitive.bk.$blueWalmart,
  textAlign: 'center',
  margin: 0,
});

export const InfoContainer = Box.withConfig({
  display: 'flex',
  alignItems: 'center',
  marginTop: '$4',
});

export const InfoBadge = Box.withConfig({
  backgroundColor: primitive.bk.$blueLight,
  height: '$8',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  width: 'fit-content',
  padding: '$2',
});

export const InfoText = Text.withConfig({
  variant: 'headerThree',
  fontSize: 'sm',
  textAlign: 'center',
  color: primitive.bk.$blueWalmart,
  fontWeight: 400,
  margin: 0,
});

export const StyledImage = Image.withConfig(() => {
  const isMobile = useIsMobileBreakpoint();

  return {
    resizeMode: isMobile ? ResizeMode.cover : ResizeMode.contain,
    height: '181px',
  };
});

export const SectionTitle = Text.withConfig({
  variant: 'headerThree',
  fontSize: 'xl',
  marginBottom: 'unset',
  marginY: '$6',
  textAlign: 'center',
});
