import * as React from 'react';
import { useEffect, useRef } from 'react';

import { HStack, VStack } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { AccessibilityInfo, findNodeHandle } from 'react-native';

import { A11yWrapper } from 'components/a11y-wrapper';
import { useRoute } from 'hooks/navigation/use-route';
import { routes } from 'utils/routing';

import { StoreOrderButton } from '..';

import { StoreCardActions, StoreCardDetails } from './components';
import { Container, Distance, disableColorProp } from './store-card.styled';
import { StoreCardViewProps } from './types';

export const StoreCardView: React.FC<StoreCardViewProps> = ({
  isFavorite = false,
  isUpdatingFavorites = false,
  onCardPress,
  onDiagnosticsClick,
  onFavoriteClick,
  onOrderPress,
  onInfoPress,
  storeDetails,
  errorMessage,
  isInMapView,
  isInSupportForm,
  restaurantCanBeSelected,
  isSelected,
}) => {
  const { formatMessage } = useIntl();
  const {
    address,
    distanceText,
    restaurant,
    isActive,
    restaurantStatus,
    isHalal,
    city,
    accessInstructions,
  } = storeDetails;
  const { pathname } = useRoute();
  const isFavoriteSearch = pathname === routes.signUp;
  const halalTitle = isHalal ? formatMessage({ id: 'isHalalTitle' }) : '';
  const accessibilityLabel = `${address} ${city} ${restaurantStatus.text}, ${halalTitle}, ${accessInstructions}`;
  const elementFocusAfterExpandRef = useRef(null);

  // This useEffect handles focus management for accessibility. It focuses the
  // first child element when the parent component becomes selected.
  useEffect(() => {
    if (isSelected && elementFocusAfterExpandRef.current) {
      const nodeHandle = findNodeHandle(elementFocusAfterExpandRef.current);
      if (nodeHandle) {
        return AccessibilityInfo.setAccessibilityFocus(nodeHandle);
      }
    }
  }, [isSelected]);

  return (
    <Container
      isActive={isActive}
      onPress={onCardPress}
      accessible={!isSelected}
      accessibilityState={{ expanded: !!isSelected }}
      accessibilityRole="button"
      accessibilityLabel={accessibilityLabel}
      dd-action-name={`${storeDetails.address} open accordion`}
      aria-expanded={isSelected}
    >
      <HStack>
        <StoreCardDetails
          onInfoPress={onInfoPress}
          errorMessage={errorMessage}
          storeDetails={storeDetails}
          isSelected={isSelected}
          isInMapView={isInMapView}
          elementFocusAfterExpandRef={elementFocusAfterExpandRef}
        />
        <VStack justifyContent="space-between">
          <StoreCardActions
            dd-action-name={`${address} ${isFavorite ? 'unfavorite' : 'favorite'}`}
            isFavorite={isFavorite}
            onDiagnosticsClick={!isFavoriteSearch ? onDiagnosticsClick : undefined}
            onFavoritesClick={!isFavoriteSearch ? onFavoriteClick : onCardPress}
            isUpdatingFavorites={isUpdatingFavorites}
          />
          <A11yWrapper flexGrow={0}>
            <Distance {...(!isActive && disableColorProp)}>{distanceText}</Distance>
          </A11yWrapper>
        </VStack>
      </HStack>
      {(isSelected || isInMapView) && restaurantCanBeSelected && (
        <StoreOrderButton
          isInSupportForm={isInSupportForm}
          onOrderPress={onOrderPress}
          restaurant={restaurant}
          restaurantCanBeSelected={restaurantCanBeSelected}
        />
      )}
    </Container>
  );
};
