import { Box, Header, Pressable, Text } from '@rbilabs/universal-components';

import { primitive } from 'styles/constants/primitives';

export const Container = Pressable.withConfig(({ isActive }: { isActive: boolean }) => ({
  flexDir: 'column',
  padding: '$4',
  ...(!isActive && {
    backgroundColor: primitive.bk.$mayoEggWhite,
  }),
}));

export const StoreCardViewContainer = Box.withConfig({
  position: 'relative',
  overflow: 'hidden',
  flex: 1,
});

export const Wrapper = Box.withConfig(({ isSelected }: { isSelected: boolean }) => ({
  borderBottomWidth: 1,
  borderColor: 'blackOpacity.10',
  position: 'relative',
  zIndex: Styles.zIndex.below,
  backgroundColor: Styles.color.cardBackground,
  ...(isSelected && {
    borderWidth: '2px',
    borderColor: primitive.bk.$fieryRed,
  }),
}));

export const Address = Header.withConfig({
  variant: 'headerThree',
  marginBottom: 0, // Reset default margin bottom
  marginY: '$1',
  underline: true,
  focusable: false,
});

export const ServiceModes = Text.withConfig({
  variant: 'copyTwo',
});

export const Status = Text.withConfig(
  ({ isOpen, isWithinOneHourOfClose }: { isOpen: boolean; isWithinOneHourOfClose: boolean }) => ({
    variant: 'copyOne',
    fontSize: 'sm',
    fontFamily: 'heading',
    marginTop: '$1',
    focusable: true,
    color: isWithinOneHourOfClose
      ? primitive.bk.$chkingOrange
      : isOpen
      ? primitive.bk.$crunchyGreen
      : primitive.bk.$fieryRed,
  })
);

export const Distance = Text.withConfig({
  fontFamily: 'heading',
  focusable: true,
});

export const disableColorProp = { color: primitive.$blackOpacity50 };
