import { FC } from 'react';

import { Box, Header, Pressable } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import Picture from 'components/picture';
import { useNavigation } from 'hooks/navigation/use-navigation';
import useEffectOnce from 'hooks/use-effect-once';
import { useAuthContext } from 'state/auth';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { isActiveWalmartUser } from 'state/loyalty/hooks/utils/walmart';
import { portalToFooter } from 'state/mobile-footer';
import { portalToReplaceHeader } from 'state/mobile-header-nav';
import { isIOS } from 'utils/environment';
import { routes } from 'utils/routing';

import { useLoyaltyWalmart } from '../walmart/use-loyalty-walmart';

import { LogoContainer, StyledBackIcon, StyledBox } from './styled';
import { WalmartOfferItem } from './walmart-offer-item';

export const WalmartOffer: FC<React.PropsWithChildren<{}>> = () => {
  const { formatMessage } = useIntl();
  const { closeModalAuth, setAccountCallbackUrl } = useAuthContext();
  const { navigate } = useNavigation();
  const { loyaltyUser, loading: isLoadingLoyaltyUser } = useLoyaltyUser();
  const isWalmartUser = isActiveWalmartUser(loyaltyUser);
  const { data, loading } = useLoyaltyWalmart();

  useEffectOnce(() => {
    return () => {
      // TODO: Consider closing the modal using state in the useAuth context on mount.
      closeModalAuth();
    };
  });

  const HiddenAppHeader = portalToReplaceHeader(() => {
    return (
      <Box marginTop={isIOS() ? '$12' : '$0'}>
        <Pressable
          onPress={() => {
            setAccountCallbackUrl(routes.base);
            navigate(routes.account);
          }}
          padding="$4"
        >
          <StyledBackIcon variant="back" />
        </Pressable>
        <Header alignSelf="center" variant="headerTwo" top="$4" position="absolute">
          {formatMessage({ id: 'partnerOffers' })}
        </Header>
      </Box>
    );
  });
  const HiddenAppFooter = portalToFooter(() => null);

  return (
    (!loading && (
      <>
        <StyledBox>
          <WalmartOfferItem
            name={formatMessage({ id: 'partnershipWalmartTitle' })}
            description={formatMessage({ id: 'partnershipWalmartDescription' })}
            image={
              <LogoContainer>
                <Picture
                  width="$24"
                  image={data?.logo?.locale}
                  alt={formatMessage({ id: 'deliveryLogoAlt' })}
                />
              </LogoContainer>
            }
            activateButtonLabel={formatMessage({ id: 'activate' })}
            linkedButtonLabel={formatMessage({ id: 'active' })}
            onPartnershipActivate={() => {
              navigate(routes.walmart);
            }}
            isLoadingUser={isLoadingLoyaltyUser}
            isWalmartUser={isWalmartUser}
          />
        </StyledBox>
        <HiddenAppHeader />
        <HiddenAppFooter />
      </>
    )) ||
    null
  );
};
