import { FC, PropsWithChildren } from 'react';

import { useIntl } from 'react-intl';

import { Terms, TermsLink } from './styled';

export const WalmartTerms: FC<PropsWithChildren<{}>> = () => {
  const { formatMessage } = useIntl();

  return (
    <Terms>
      {formatMessage(
        { id: 'walmartTerms' },
        {
          bkLink: <TermsLink to="/">{formatMessage({ id: 'bkWebsiteUrl' })}</TermsLink>,
          bkTermsLink: (
            <TermsLink to={formatMessage({ id: 'bk.routes.terms' })}>
              {formatMessage({ id: 'bkTermsUrl' })}
            </TermsLink>
          ),
        }
      )}
    </Terms>
  );
};
