import { Nullable } from '@rbi-ctg/frontend';
import { IBackendCartEntries, ICartEntry, IHistoricalOrder, IServerOrder } from '@rbi-ctg/menu';
import { IRestaurant } from '@rbi-ctg/store';
import { RbiOrderStatus as OrderStatus } from 'generated/graphql-gateway';
import { ServiceMode } from 'state/service-mode/types';
import { IPlaceAddress } from 'utils/geolocation';

export { OrderStatus };

export enum CartPaymentType {
  APPLE_PAY = 'APPLE_PAY',
  CREDIT_ANONYMOUS = 'CREDIT_ANONYMOUS',
  GOOGLE_PAY = 'GOOGLE_PAY',
  VAULTED_ACCOUNT = 'VAULTED_ACCOUNT',
  CASH = 'CASH',
  PAYPAL = 'PAYPAL',
  VENMO = 'VENMO',
}

export enum DeliveryStatus {
  DRIVER_ASSIGNED = 'DRIVER_ASSIGNED',
  DRIVER_UNASSIGNED = 'DRIVER_UNASSIGNED',
  DRIVER_AT_CUSTOMER = 'DRIVER_AT_CUSTOMER',
  DRIVER_AT_STORE = 'DRIVER_AT_STORE',
  DRIVER_STARTING = 'DRIVER_STARTING',
  ORDER_ABANDONED = 'ORDER_ABANDONED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_DROPPED_OFF = 'ORDER_DROPPED_OFF',
  ORDER_ERROR = 'ORDER_ERROR',
  ORDER_PICKED_UP = 'ORDER_PICKED_UP',
  ORDER_TIMEOUT = 'ORDER_TIMEOUT',
  QUOTE_ERROR = 'QUOTE_ERROR',
  QUOTE_REQUESTED = 'QUOTE_REQUESTED',
  QUOTE_SUCCESSFUL = 'QUOTE_SUCCESSFUL',
  QUOTE_UNAVAILABLE = 'QUOTE_UNAVAILABLE',
}

interface IFireOrderInXSecondsArgs {
  rbiOrderId: string;
  timeInSeconds: number;
}

export type FireOrderInXSeconds = (
  params: IFireOrderInXSecondsArgs
) => Promise<IServerOrder | null>;

export interface IFavoriteOrder {
  cart: {
    cartEntries: IBackendCartEntries[];
    reorderCartEntries?: IBackendCartEntries[];
    serviceMode: ServiceMode | null;
  };
  status?: OrderStatus;
}

export type IPendingReorder = IHistoricalOrder | IFavoriteOrder;

export interface IOrderContext {
  unavailableCartEntries: ICartEntry[];
  setUnavailableCartEntries: React.Dispatch<React.SetStateAction<ICartEntry[]>>;
  serviceMode: Nullable<ServiceMode>;
  serverOrder: IServerOrder | null;
  alertOrderDeliveryMinimum: () => void;
  shouldEmptyCart: (args: { cartId: string }) => boolean;
  selectServiceMode: (serviceMode: ServiceMode | null) => Promise<boolean>;
  clearServerOrder: () => void;
  selectStore: (
    newStore: IRestaurant,
    callback: () => void,
    requestedServiceMode: ServiceMode,
    skipRedirection?: boolean
  ) => Promise<void>;
  fetchingPosData: boolean;
  clearCartStoreServiceModeTimeout: () => void;
  logCartStoreAndTimeout: (resetStoreTimeout: number, timeSinceLastVisit: number) => void;
  deliveryAddress: IPlaceAddress | null;
  setDeliveryAddress: React.Dispatch<React.SetStateAction<IPlaceAddress | null>>;
  deliveryInstructions: string | undefined;
  setDeliveryInstructions: React.Dispatch<React.SetStateAction<string | undefined>>;
  orderPhoneNumber: string;
  setOrderPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  isDelivery: boolean;
  fireOrderIn: number;
  setFireOrderIn: React.Dispatch<React.SetStateAction<number>>;
  onCommitSuccess: (serverOrder: IServerOrder) => Promise<void>;
  curbsidePickupOrderId: string;
  setCurbsidePickupOrderId: React.Dispatch<React.SetStateAction<string>>;
  updateShouldSaveDeliveryAddress: (shouldSave: boolean) => void;
  setCurbsidePickupOrderTimePlaced: React.Dispatch<React.SetStateAction<string>>;
  quoteId: string;
  setQuoteId: React.Dispatch<React.SetStateAction<string>>;
  fireOrderInXSeconds: FireOrderInXSeconds;
  reorder: {
    handleReorder: (order: IPendingReorder, url?: string) => Promise<void>;
    resetPendingReorder: () => void;
    reordering: boolean;
    setReordering: React.Dispatch<React.SetStateAction<boolean>>;
    pendingReorder: IPendingReorder | null;
    setPendingReorder: React.Dispatch<React.SetStateAction<IPendingReorder | null>>;
    reorderedOrderId: string | null;
    setShouldHandleReorder: (bool: boolean) => void;
  };
  recent: {
    pendingRecentItem: ICartEntry | null;
    setPendingRecentItem: React.Dispatch<React.SetStateAction<ICartEntry | null>>;
    unavailablePendingRecentItem: ICartEntry | null;
    setUnavailablePendingRecentItem: React.Dispatch<React.SetStateAction<ICartEntry | null>>;
  };
  isPreConfirmCurbside: () => boolean;
  cancelCurbsideOrderCallbackUrl: string | undefined;
  setCancelCurbsideOrderCallbackUrl: (url: string) => void;
}
