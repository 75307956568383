import React, { useState } from 'react';

import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import Modal from 'components/modal';
import { ProgressDots } from 'components/progress-dots';
import { ImageWithHeaderAndText } from 'components/widgets/image-with-header-and-text';
import useEffectOnce from 'hooks/use-effect-once';
import useBrazeContext from 'state/braze/hooks/use-braze-context';
import {
  ClickEventComponentNames,
  CustomEventNames,
  EventTypes,
  useMParticleContext,
} from 'state/mParticle';
import { useOnboardingContext } from 'state/onboarding';
import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';
import { isIOS } from 'utils/environment';

import { ContentContainer, NavigationContainer, Stepper } from './styled';

export const OnboardingScreensModal = () => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isLoadingPerm, setIsLoadingPerm] = useState<boolean>(false);
  const { logButtonClick, logRBIEvent } = useMParticleContext();
  const { isInAppMessageOpen } = useBrazeContext();
  const {
    triggerRule,
    onButtonPress,
    featureOnboardingScreens,
    isFirstAppLoadOnboardingComplete,
    currentScreenIndex,
    navigateBackward,
    isFirstScreen,
    isLastScreen,
    numberOfScreens,
  } = useOnboardingContext();

  useEffectOnce(() => {
    logRBIEvent({
      name: CustomEventNames.MODAL_APPEARANCE,
      type: EventTypes.Other,
      attributes: {
        name: 'Onboarding modal',
      },
    });
  });

  if (
    !numberOfScreens ||
    isFirstAppLoadOnboardingComplete ||
    !triggerRule ||
    !isOpen ||
    isInAppMessageOpen
  ) {
    return null;
  }

  const onPress = (url?: string) => {
    setIsLoadingPerm(true);
    logButtonClick({
      type: EventTypes.Other,
      attributes: {
        component: ClickEventComponentNames.BUTTON,
        name:
          featureOnboardingScreens[currentScreenIndex]?.buttonText || url || 'Onboarding button',
        identification: featureOnboardingScreens[currentScreenIndex]?.id,
      },
    });
    onButtonPress(featureOnboardingScreens[currentScreenIndex], url, () => {
      setIsLoadingPerm(false);
      if (isLastScreen) {
        setIsOpen(false);
      }
    });
  };

  const onPresSkipButton = () => {
    logButtonClick({
      type: EventTypes.Other,
      attributes: {
        component: ClickEventComponentNames.BUTTON,
        name: 'Onboarding Skip Button',
        identification: featureOnboardingScreens[currentScreenIndex]?.id,
      },
    });
    onPress('/OS/continue');
    setIsLoadingPerm(false);
  };

  return (
    <Modal
      testID={'onboarding-modal'}
      allowsDismiss={false}
      isFullHeightContent
      mParticleEventData={{
        modalAppearanceEventMessage: 'Onboarding Triggered',
      }}
      backgroundColor={primitive.$white}
      contentStyle={{ borderRadius: 0 }}
      body={
        <ContentContainer>
          <ImageWithHeaderAndText
            image={featureOnboardingScreens[currentScreenIndex]?.image ?? null}
            imageDescription={featureOnboardingScreens[currentScreenIndex]?.imageDescription ?? ''}
            textContent={featureOnboardingScreens[currentScreenIndex]?.textContent ?? []}
            buttonText={featureOnboardingScreens[currentScreenIndex]?.buttonText ?? ''}
            buttonUrl={featureOnboardingScreens[currentScreenIndex]?.buttonUrl ?? ''}
            secondaryButtonText={
              featureOnboardingScreens[currentScreenIndex]?.secondaryButtonText ?? ''
            }
            secondaryButtonUrl={
              featureOnboardingScreens[currentScreenIndex]?.secondaryButtonUrl ?? ''
            }
            onButtonPress={onPress}
            loading={isLoadingPerm}
          />
        </ContentContainer>
      }
      footer={
        <NavigationContainer mb={isIOS() ? '$6' : '$2'}>
          <ActionButton
            variant={ActionButtonVariants.TEXT_ONLY}
            size={ActionButtonSizes.SMALL}
            onPress={navigateBackward}
            disabled={isFirstScreen}
            {...(isFirstScreen && { _text: { color: 'transparent' } })}
          >
            {formatMessage({ id: 'previous', description: '' })}
          </ActionButton>
          {numberOfScreens > 1 && (
            <Stepper>
              <ProgressDots dotCount={numberOfScreens} activeDotIndex={currentScreenIndex} />
            </Stepper>
          )}
          <ActionButton
            variant={ActionButtonVariants.TEXT_ONLY}
            size={ActionButtonSizes.SMALL}
            onPress={() => {
              onPresSkipButton();
            }}
            _text={{ color: theme.token('icon-button-secondary') }}
            alignSelf="flex-end"
          >
            {formatMessage({ id: 'skip', description: '' })}
          </ActionButton>
        </NavigationContainer>
      }
    />
  );
};
