import { useEffect } from 'react';

import {
  useFeatureStaticImagesLazyQuery,
  useFeatureStaticImagesQuery,
} from 'generated/sanity-graphql';

import { IPictureProps } from './types';

import Picture from '.';

const SINGLETON_ID = 'feature-static-images-singleton';

/** run once at app start to make image loading from the internet faster (1 hop instead of two) */
export const usePreloadStaticImagePaths = () => {
  const [doQuery] = useFeatureStaticImagesLazyQuery();
  useEffect(() => {
    //might as well do before we encounter an image (which will automatically trigger it) but not necessary to be in the flurry of requests right at app start
    setTimeout(() => {
      doQuery({
        variables: { id: SINGLETON_ID },
      });
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

type StaticImageProps = {
  source: StaticImages;
} & IPictureProps;

/** a "StaticImage" image that resolves from sanity "Feature Static Images" section. Could be a normal image or a lottie. */
export const StaticImage = (props: StaticImageProps) => {
  const { data } = useFeatureStaticImagesQuery({
    variables: { id: SINGLETON_ID },
  });

  const asset = data?.FeatureStaticImages?.staticImagesList?.find(
    x => x?.uniqueId === props.source
  );

  if (asset?.lottie) {
    return <Picture lottie={asset.lottie} {...props} />;
  }

  if (asset?.image) {
    return <Picture image={asset.image} {...props} />;
  }

  // should be loaded at app start by usePreloadStaticImages but just in case... (or someone deletes an image uniqueId by mistake in sanity)
  return null;
};

/** only use this if you are using lottie directly, likely because you have onAnimationStart/Finish etc. hooks that mean it could never be swapped to a png etc. type image without a code change*/
export const useStaticLottieUri = (source: StaticImages) => {
  const { data } = useFeatureStaticImagesQuery({
    variables: { id: SINGLETON_ID },
  });

  const asset = data?.FeatureStaticImages?.staticImagesList?.find(x => x?.uniqueId === source);

  return asset?.lottie?.asset?.url;
};

// the list from here.... https://dev-bk-us-whitelabel-cms.rbi.tools/structure/marketingContent;features;featureStaticImages
// don't remove IDs from the site or it could break images on older app versions
export enum StaticImages {
  StoreLocatorLoader = 'store-locator-loader',
  PullDownToReload = 'pull-down-to-reload',
  PullDownReloading = 'pull-down-reloading',
  PaymentSuccessLoader = 'payment-success-loader',
  PaymentProcessingLoader = 'payment-processing-loader',
  LoyaltyOptInAnimation = 'loyalty-opt-in-animation',
  FavoriteLoader = 'favorite-loader',
  DotLoader = 'dot-loader',
  ConfettiFries = 'confetti-fries',
  CartLoader = 'cart-loader',
  BurgerBounceLoader = 'burger-bounce-loader',
  LocationPermissions = 'location-permissions',
  WalmartLocation = 'walmart-location',
}
