import ModalOrderUnavailable from 'components/modal-order-unavailable';
import { useOrderContext } from 'state/order';

export const RecentItemUnavailableModal = () => {
  const {
    recent: { unavailablePendingRecentItem, setUnavailablePendingRecentItem },
  } = useOrderContext();

  if (!unavailablePendingRecentItem) {
    return null;
  }

  return (
    <ModalOrderUnavailable
      items={[unavailablePendingRecentItem]}
      onDismiss={() => setUnavailablePendingRecentItem(null)}
    />
  );
};
