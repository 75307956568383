import React from 'react';

import { AlertDialog, Box, Header, makeUclComponent } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import noop from 'utils/noop';

import { AlertDialogEventLogger } from './alert-dialog-event-logger';
import type { IDialogProps } from './types';

const CloseButtonContainer = Box.withConfig({
  mt: '$2',
  width: 'full',
});

export const CloseButton = makeUclComponent(ActionButton).withConfig({
  fullWidth: true,
  variant: ActionButtonVariants.TEXT_ONLY,
});

const Dialog: React.FC<React.PropsWithChildren<IDialogProps>> = ({
  children,
  heading,
  headingComponent,
  body,
  bodyComponent,
  onDismiss,
  actions,
  image,
  initialFocusRef,
  /**
   * A small string sent to mParticle describing the purpose of the modal.
   */
  modalAppearanceEventMessage,
  /**
   * Additional Error details to be sent to mParticle.
   */
  errorEventMessage,
  testID,
  showCloseButton = false,
  showDialog,
  confirmButtonLabel,
  closeButtonLabelId = 'close',
  confirmDialog = noop,
  maxHeight,
  ...props
}) => {
  const { formatMessage } = useIntl();

  if (confirmButtonLabel && !actions) {
    actions = (
      <ActionButton
        fullWidth={showCloseButton}
        onPress={confirmDialog}
        testID="dialog-confirm-btn"
        variant={showCloseButton ? ActionButtonVariants.PRIMARY : ActionButtonVariants.TEXT_ONLY}
      >
        {confirmButtonLabel}
      </ActionButton>
    );
  }

  if (!actions && !showCloseButton && __DEV__) {
    // eslint-disable-next-line no-console
    console.error('Dialog: Please add actions or show the close button (showCloseButton)');
  }
  const cancelRef = React.useRef(null);

  // The dialog content will respect its default maximum height unless you specify a different maxHeight value.
  const dialogContentMaxHeight = { ...(maxHeight && { maxHeight }) };

  return (
    <AlertDialogEventLogger
      modalAppearanceEventMessage={modalAppearanceEventMessage}
      errorEventMessage={errorEventMessage}
      modalAppearanceHeader={heading}
      modalAppearanceMessage={body}
      leastDestructiveRef={initialFocusRef || cancelRef}
      isOpen={showDialog}
      onClose={onDismiss}
      {...props}
    >
      <AlertDialog.Content {...dialogContentMaxHeight} testID={testID}>
        {image}
        {headingComponent ? (
          headingComponent
        ) : heading ? (
          <AlertDialog.Header>
            <Header variant="headerTwo">{heading}</Header>
          </AlertDialog.Header>
        ) : null}
        {children ? (
          children
        ) : bodyComponent ? (
          bodyComponent
        ) : body ? (
          <AlertDialog.Body>{body}</AlertDialog.Body>
        ) : null}
        {(actions || showCloseButton) && (
          <AlertDialog.Footer>
            {actions}
            {showCloseButton && (
              <CloseButtonContainer>
                <CloseButton onPress={onDismiss}>
                  {formatMessage({ id: closeButtonLabelId })}
                </CloseButton>
              </CloseButtonContainer>
            )}
          </AlertDialog.Footer>
        )}
      </AlertDialog.Content>
    </AlertDialogEventLogger>
  );
};

export default makeUclComponent(React.memo(Dialog));
