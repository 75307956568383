import { Box, HStack } from '@rbilabs/universal-components';

const FORM_SPACING = '$4';

export const StyledExpiryCVVWrapper = HStack.withConfig({
  justifyContent: 'space-between',
  width: 'full',
});

export const StyledExpiryWrapper = Box.withConfig({
  marginRight: '$4',
  marginBottom: FORM_SPACING,
});

export const StyledCvvWrapper = Box.withConfig({
  flexShrink: 1,
  flexGrow: 1,
  marginBottom: FORM_SPACING,
});

export const CardNumberWrapper = Box.withConfig({
  position: 'relative',
  flexBasis: {
    base: '100%',
    md: '50%',
  },
  flexGrow: 1,
  marginBottom: FORM_SPACING,
});
