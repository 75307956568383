import * as React from 'react';

import { Svg, SvgPath, SvgText } from '@rbilabs/universal-components';

export const QuestPointsPicture = ({ bonusPoints }: { bonusPoints: number }) => (
  <Svg width="$16" height="$16" viewBox="0 0 64 64" fill="none">
    <SvgPath
      d="M23.8913 63.8463C-9.44216 58.7187 -6.20791 9.89025 23.2785 1.5828C29.3566 0.116769 32.7784 -1.08768 43.0401 1.58278C61.8532 6.47862 65.361 29.0274 63.5628 37.7997C58.3989 62.9906 45.9563 64.6724 23.8913 63.8463Z"
      fill="#FF8732"
    />
    <SvgPath
      d="M26.2051 11.9558C6.15934 17.7004 4.29778 50.3025 26.9648 53.8483C41.9681 54.4195 50.1096 54.4195 53.6107 36.9999C54.8299 30.9338 53.9129 17.5434 39.5081 10.942C32.6635 10.1496 30.3373 10.942 26.2051 11.9558Z"
      fill="#F5EBDC"
    />
    <SvgPath
      d="M26.6774 55.0112C2.78519 51.2737 4.74737 16.9094 25.8767 10.8542C30.2323 9.78565 32.6842 8.95043 39.8987 9.78565C55.0822 16.7438 56.0488 30.8581 54.7637 37.2521C51.0734 55.6133 42.4917 55.6133 26.6774 55.0112ZM10.723 34.1267C10.8515 48.9059 21.0377 53.385 33.6069 53.7921C46.1696 55.0112 51.1677 46.2069 52.3193 37.0514C56.8912 0.45961 10.5549 4.86426 10.723 34.1267Z"
      fill="#502314"
    />
    <SvgPath
      d="M31.6408 4.7229C30.0284 4.98052 30.1144 2.5671 32.1684 2.41016C34.1632 2.52268 33.0843 4.59556 31.6408 4.7229Z"
      fill="#502314"
    />
    <SvgPath
      d="M31.6408 59.8381C30.0284 60.0958 30.1144 57.6823 32.1684 57.5254C34.1632 57.6379 33.0843 59.7108 31.6408 59.8381Z"
      fill="#502314"
    />
    <SvgPath
      d="M58.3263 30.8853C58.0684 29.2744 60.4841 29.3603 60.6412 31.4124C60.5285 33.4054 58.4537 32.3275 58.3263 30.8853Z"
      fill="#502314"
    />
    <SvgPath
      d="M3.15732 30.8853C2.89945 29.2744 5.31513 29.3603 5.47222 31.4124C5.35959 33.4054 3.28477 32.3275 3.15732 30.8853Z"
      fill="#502314"
    />
    <SvgPath
      d="M50.7606 49.633C51.7184 48.3117 53.3658 50.079 52.0244 51.641C50.5343 52.9707 49.8301 50.7427 50.7606 49.633Z"
      fill="#502314"
    />
    <SvgPath
      d="M11.7499 10.6603C12.7077 9.33906 14.355 11.1063 13.0137 12.6684C11.5235 13.998 10.8193 11.7701 11.7499 10.6603Z"
      fill="#502314"
    />
    <SvgPath
      d="M13.3732 49.985C14.6957 50.9419 12.9268 52.5877 11.3633 51.2476C10.0324 49.7589 12.2624 49.0553 13.3732 49.985Z"
      fill="#502314"
    />
    <SvgPath
      d="M52.383 11.0143C53.7055 11.9712 51.9366 13.617 50.3731 12.2769C49.0422 10.7882 51.2722 10.0846 52.383 11.0143Z"
      fill="#502314"
    />
    <SvgPath
      d="M11.9102 26.9253C11.4947 24.9957 13.4622 23.425 15.2398 24.2674L22.5204 27.7179C23.6294 28.2434 24.9544 27.833 25.5761 26.7714L29.9099 19.3715C30.8361 17.7899 33.1199 17.81 34.0184 19.4077L38.2215 26.8817C38.8122 27.9324 40.0917 28.3732 41.2003 27.9081L49.1871 24.5561C50.9601 23.812 52.8318 25.3841 52.4166 27.2687L48.8696 43.3668C48.6294 44.4578 47.6666 45.2341 46.5545 45.2341H17.766C16.6496 45.2341 15.6844 44.4518 15.4483 43.3559L11.9102 26.9253Z"
      fill="#FF8732"
    />

    <SvgText
      x="50%"
      y="40"
      fill="#F5EBDC"
      fontSize="12"
      fontFamily="Flame-Regular"
      textAnchor="middle"
    >
      {bonusPoints}
    </SvgText>
  </Svg>
);
