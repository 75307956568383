import { min as findMin, sum } from 'lodash-es';

import {
  ICombo,
  IComboSlot,
  IComboSlotOption,
  IItem,
  IItemOption,
  IItemOptionModifier,
  IModifierSelection,
  INutritionInfo,
  IPicker,
  IPickerOption,
  ISanityItemOption,
  ISanityItemOptionModifier,
  MenuObject,
} from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import { CaloriesSuffix } from 'hooks/use-format-calories/types';

import { computeSelectedOption } from './compute-selected-option';
import { defaultPickerAspect } from './get-default-picker-aspect';
import { getDefaultPickerSelection } from './get-default-picker-selection';

export type IFormatCalorieRange = {
  min: number;
  max?: number | null;
  length?: number;
  formatFn: (calories: number, suffix?: CaloriesSuffix) => string;
};

export function formatCalorieRange({
  min,
  max = null,
  length = 1,
  formatFn,
}: IFormatCalorieRange): string {
  // If there are no calories do not display
  if (min === null || min === Infinity) {
    return '';
  }

  const minFormatted = formatFn(min);

  if (max) {
    const delimiter = length === 2 ? '/' : ' - ';
    const maxFormatted = formatFn(max);

    if (maxFormatted === minFormatted) {
      return minFormatted;
    }

    // We need to show the "cal" suffix only once
    const shortMinFormatted = formatFn(min, 'none');

    return `${shortMinFormatted}${delimiter}${maxFormatted}`;
  }

  return minFormatted;
}

type IDMap = {
  [id: string]: string;
};

function getCaloriesForCombo(item: ICombo): number {
  const comboOptions = item.options.filter(
    option => option._type === 'comboSlot' && option.maxAmount
  );

  const comboSlotCalories = comboOptions.reduce((comboCalories, option) => {
    // we need to account for all hidden comboslot calories
    if (option.uiPattern === 'hidden') {
      comboCalories += option.options.reduce((hiddenCalories, opt) => {
        const defaultAmount = opt.defaultAmount || opt.minAmount;
        return hiddenCalories + defaultAmount * getItemCalories(opt.option);
      }, 0);
    } else {
      comboCalories += getDefaultComboslotsCalories(option);
    }
    return comboCalories;
  }, 0);

  const mainItemCalories = item.mainItem ? getItemCalories(item.mainItem) : 0;

  return comboSlotCalories + mainItemCalories;
}

function getCaloriesForPicker(item: IPicker, pickerSelections: Record<string, string>): number {
  const selection = computeSelectedOption(pickerSelections, item);
  if (selection === false) {
    return 0;
  }
  return getItemCalories(selection as IItem | ICombo | IPicker);
}

const getDefaultModifiers = ({ options }: IItemOption): number => {
  const defaultItems = options.filter(item => item.default);
  const [calories] = defaultItems.map(modifier => modifier.nutritionWithModifiers?.calories || 0);

  return calories || 0;
};

const getCaloriesWithDefaultsForItem = (item: IItem): number => {
  const baseCalories = item.nutritionWithModifiers?.calories || 0;
  const defaultModifiers = item.options?.length
    ? item.options.map(getDefaultModifiers).reduce((i, j) => i + j, 0)
    : 0;

  return baseCalories + defaultModifiers;
};

interface IComboSlotSelection {
  data: Omit<IComboSlot, 'options'>;
  selections: { option: IComboSlotOption; quantity: number }[];
}

interface IComputeCurrentCalories {
  comboSlotSelections?: { [id: string]: IComboSlotSelection };
  item: IItem | ICombo | IPicker;
  modifierSelections?: IModifierSelection[];
  pickerSelections: IDMap;
  quantity: number;
}

const caloriesForItemNutrition = (item: { nutritionWithModifiers: INutritionInfo }): number => {
  return (item.nutritionWithModifiers && item.nutritionWithModifiers.calories) || 0;
};

// calculates calories based whats selected in menu modal (pickers)
export function computeCurrentCalories({
  comboSlotSelections = {},
  item,
  modifierSelections = [],
  pickerSelections,
  quantity,
}: IComputeCurrentCalories) {
  let totalCalories = 0;
  const selection = computeSelectedOption(pickerSelections, item);

  // If we have mods (default or not) we calculate their nutrition here
  if (modifierSelections.length) {
    totalCalories += modifierSelections.reduce((acc, { modifier }) => {
      return acc + getItemCalories(modifier) * modifier.quantity;
    }, 0);
  }

  // Next, we get the item calories (whether it's an item alone, or an item on a combo)
  if (item._type === 'item' || (selection && selection._type === 'item')) {
    const itemCalories = caloriesForItemNutrition(
      item._type === 'item' ? item : (selection as IItem)
    );
    totalCalories += itemCalories;
  } else if (selection && selection._type === 'combo') {
    //sometimes combos have mainItems we need to account for
    totalCalories += selection.mainItem ? getItemCalories(selection.mainItem) : 0;

    // calculate all items selected (including items that cannot be edited)
    const selectionsForSlots: IComboSlotSelection['selections'] = collectComboSlotSelections(
      comboSlotSelections
    );

    totalCalories += selectionsForSlots.reduce(
      (slotCalories, slot) => (slotCalories += slot.quantity * getItemCalories(slot.option.option)),
      0
    );
  }

  return quantity * totalCalories;
}

// calculates calories based on item type
export function getItemCalories(
  originalItem: ICombo | IItem | IItemOptionModifier | IPicker
): number {
  if (!originalItem) {
    return 0;
  }

  const item = { ...originalItem };
  // @HACK: massage type so we get calories appropriately and we don't break typescript
  // @ts-expect-error TS(2339) FIXME: Property '_key' does not exist on type 'ICombo | I... Remove this comment to see the full error message
  if (item._key) {
    // @ts-expect-error TS(2339) FIXME: Property '_key' does not exist on type 'ICombo | I... Remove this comment to see the full error message
    switch (item._key.split('_')[0]) {
      case 'modifierMultiplier':
      case 'ModifierGroup':
        item._type = MenuObjectTypes.ITEM_OPTION_MODIFIER;
        break;
      default:
        break;
    }
  }

  switch (item._type) {
    case 'item':
      return getCaloriesWithDefaultsForItem(item);
    case 'itemOptionModifier':
      return item.nutritionWithModifiers && item.nutritionWithModifiers.calories
        ? item.nutritionWithModifiers.calories
        : 0;
    case 'picker':
      return getCaloriesForPicker(item, defaultPickerAspect(item));
    case 'combo':
      return getCaloriesForCombo(item);
    default:
      return 0;
  }
}

// get default calories from a combo slot
function getDefaultComboslotsCalories(option: IComboSlot) {
  // since right now sanity doesn't provide a default we just default to the first item
  const firstOption = option.options[0];
  const defaultAmount = firstOption.defaultAmount || firstOption.minAmount;
  return defaultAmount * getItemCalories(firstOption.option);
}

export interface IMinMaxCalories {
  length?: number;
  max: number;
  min: number;
}

export const zeroCals: IMinMaxCalories = { max: 0, min: 0 };

// returns the min and max calorie combination in a combo meal
function getMinMaxComboSlotCalories(comboSlots: IComboSlot[]): IMinMaxCalories {
  if (!comboSlots) {
    return zeroCals;
  }

  let hiddenItemsCalories = 0;
  const nonHiddenItemMinCalories: number[] = [];
  const nonHiddenItemMaxCalories: number[] = [];

  comboSlots.forEach(slot => {
    // if item is hidden (user can't edit), we want all the calories
    if (slot.uiPattern === 'hidden') {
      hiddenItemsCalories += slot.options.reduce((total, option) => {
        const defaultAmount = option.defaultAmount || option.minAmount;
        return total + defaultAmount * getItemCalories(option.option);
      }, 0);
    } else {
      // Determine the minRequired choices & calories for the comboSlot
      const { minRequiredAmount, minRequiredCalories } = slot.options.reduce(
        (total, option) => {
          return {
            minRequiredAmount: total.minRequiredAmount + option.minAmount,
            minRequiredCalories:
              total.minRequiredCalories + option.minAmount * getItemCalories(option.option),
          };
        },
        { minRequiredAmount: 0, minRequiredCalories: 0 }
      );

      // Determine the gap between minSlot requirement and slotOption minimums
      let gap = slot.minAmount - minRequiredAmount;
      let minCalories = minRequiredCalories;
      const ascendingSortedOptions = [...slot.options].sort((a, b) => {
        return getItemCalories(a.option) < getItemCalories(b.option) ? -1 : 1;
      });
      while (gap && ascendingSortedOptions.length) {
        // While there is a gap grab the smallest calories while still respecting the slot.option maximums
        const smallestItem = ascendingSortedOptions.shift();
        if (!smallestItem) {
          gap = 0;
          continue;
        }
        const allowedAmount = smallestItem.maxAmount - smallestItem.minAmount;
        const amountToAdd = findMin([gap, allowedAmount])!;

        gap -= amountToAdd;
        minCalories += amountToAdd * getItemCalories(smallestItem.option);
      }

      // Determine the gap between maxSlot requirement and slotOption minimums
      gap = slot.maxAmount - minRequiredAmount;
      let maxCalories = minRequiredCalories;
      const descendingSortedOptions = [...slot.options].sort((a, b) => {
        return getItemCalories(a.option) > getItemCalories(b.option) ? -1 : 1;
      });
      while (gap && descendingSortedOptions.length) {
        // While there is a gap grab the largest calories while still respecting the slot.option maximums
        const largestItem = descendingSortedOptions.shift();
        if (!largestItem) {
          gap = 0;
          continue;
        }
        const allowedAmount = largestItem.maxAmount - largestItem.minAmount;
        const amountToAdd = findMin([gap, allowedAmount])!;

        gap -= amountToAdd;
        maxCalories += amountToAdd * getItemCalories(largestItem.option);
      }

      nonHiddenItemMinCalories.push(minCalories);
      nonHiddenItemMaxCalories.push(maxCalories);
    }
  });

  return {
    // max combo meal equals to all the un-editable calories plus the sum of the most caloric items per slot.
    max: hiddenItemsCalories + sum(nonHiddenItemMaxCalories),
    // min combo meal equals to all the un-editable calories plus the sum of the least caloric items per slot.
    min: hiddenItemsCalories + sum(nonHiddenItemMinCalories),
  };
}

function minMaxForItemOption(itemOption: IItemOption): IMinMaxCalories {
  const { maxAmount, minAmount, options } = itemOption;

  if (!options?.length) {
    return zeroCals;
  }

  const sortedOptions = [...options].sort((left, right) => {
    return getItemCalories(left) - getItemCalories(right);
  });

  const minOption = sortedOptions[0];
  const maxOption = sortedOptions[sortedOptions.length - 1];

  const itemWithDefaultOptions = options.reduce((acc, item) => {
    const newValue = item.default ? getItemCalories(item) : 0;
    return acc + newValue;
  }, 0);

  const min = itemWithDefaultOptions || getItemCalories(minOption) * minAmount;

  const max = getItemCalories(maxOption) * maxAmount;

  return { min, max };
}

function getCaloriesRangeForItem(item: IItem): IMinMaxCalories {
  const itemCals =
    item.nutritionWithModifiers && item.nutritionWithModifiers.calories
      ? item.nutritionWithModifiers.calories
      : 0;

  const { min, max } = (item.options || []).reduce((acc, option) => {
    const minMaxForOpt = minMaxForItemOption(option);

    return {
      min: minMaxForOpt.min + acc.min,
      max: minMaxForOpt.max + acc.max,
    };
  }, zeroCals);

  return { min: min + itemCals, max: max + itemCals };
}

export const getFormattedCalorieDefault = (
  item: MenuObject,
  formatFn: (calories: number) => string
) => {
  switch (item._type) {
    case MenuObjectTypes.ITEM: {
      return formatFn(getItemCalories(item));
    }
    case MenuObjectTypes.PICKER: {
      const defaultItem = getDefaultPickerSelection(item);
      return defaultItem ? formatFn(getItemCalories(defaultItem)) : '';
    }
    default: {
      return getFormattedCalorieRange(item, formatFn);
    }
  }
};

export const getFormattedCalorieRange = (
  item: MenuObject,
  formatFn: (calories: number) => string
) => {
  const { min, max, length } = getCaloriesRange(item);

  return formatCalorieRange({
    min,
    max,
    length,
    formatFn,
  });
};

type CalorieRangeItem = MenuObject | ISanityItemOption | ISanityItemOptionModifier | IPickerOption;

export const updateMinMax = (
  accumulatedMinMax: IMinMaxCalories,
  nextOptionMinMax: IMinMaxCalories
): IMinMaxCalories => {
  return {
    max: Math.max(accumulatedMinMax.max, nextOptionMinMax.max),
    min: !accumulatedMinMax.min
      ? nextOptionMinMax.min
      : Math.min(accumulatedMinMax.min, nextOptionMinMax.min),
  };
};

export const getCaloriesRange = (item: CalorieRangeItem): IMinMaxCalories => {
  if (!item) {
    return { ...zeroCals, length: 0 };
  }

  function reduceList(itemList: CalorieRangeItem[]) {
    const { min, max } = itemList.reduce(
      (ranges: { min: number; max: number }, currentItem: CalorieRangeItem) => {
        const results = getCaloriesRange(currentItem);

        return updateMinMax(ranges, results);
      },
      { min: 0, max: 0 }
    );

    return { min, max, length: itemList.length };
  }

  switch (item._type) {
    case MenuObjectTypes.ITEM: {
      const options = item.options || [];
      const { min, max } = getCaloriesRangeForItem(item);
      return { min, max, length: options.length };
    }
    case MenuObjectTypes.PICKER: {
      // We could calculate this length, but with a picker its almost gauranteed that the
      // calorie difference is a range and not a a/b.
      return { ...reduceList(item.options || []), length: Infinity };
    }
    case MenuObjectTypes.PICKER_OPTION: {
      return getCaloriesRange(item.option);
    }
    case MenuObjectTypes.SECTION: {
      const options = item.options || [];

      return { ...reduceList(options), length: Infinity };
    }
    case MenuObjectTypes.COMBO: {
      const mainItemRange = item.mainItem ? getCaloriesRange(item.mainItem) : zeroCals;
      const options = item.options || [];
      const comboRange = getMinMaxComboSlotCalories(options);

      return {
        min: mainItemRange.min + comboRange.min,
        max: mainItemRange.max + comboRange.max,
        length: options.length,
      };
    }
    default:
      return { min: 0, max: 0, length: 0 };
  }
};

export function collectComboSlotSelections(comboSlotSelections: any) {
  return Object.values(comboSlotSelections).flatMap(slot => (slot as any).selections);
}
