import React, { createContext } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';

import { BrazeCssBugOverride } from './braze-css-bug-override';
import useBrazeContext from './hooks/use-braze-context';
import { IBrazeContext } from './types';

export const BrazeContext = createContext<IBrazeContext>({} as IBrazeContext);

export const BrazeProvider = ({ children }: IBaseProps) => {
  const ctxValue = useBrazeContext();

  return (
    <BrazeContext.Provider value={ctxValue}>
      <BrazeCssBugOverride />
      {children}
    </BrazeContext.Provider>
  );
};
