export const componentsLibraryPrimitives = {
  // Brand-agnostic primitives go here

  // colors
  $black: '#000',
  $white: '#fff',
  $error: 'rgb(209,69,69)',
  $disabledText: 'rgba(0,0,0,0.55)',
  $disabledTextReversed: 'rgba(255,255,255,0.51)',
  $blackOpacity70: 'rgba(0,0,0,0.7)',
  $blackOpacity50: 'rgba(0,0,0,0.5)',
  $blackOpacity30: 'rgba(0,0,0,0.3)',
  $blackOpacity20: 'rgba(0,0,0,0.2)',
  $blackOpacity10: 'rgba(0,0,0,0.1)',
  $blackOpacity4: 'rgba(0,0,0,0.04)',
  $whiteOpacity30: 'rgba(255,255,255,0.3)',
  $whiteOpacity10: 'rgba(255,255,255,0.1)',
  $whiteOpacity4: 'rgba(255,255,255,0.04)',

  // spacing
  $spacing0: '0',
  $spacing1: '4px',
  $spacing2: '8px',
  $spacing3: '12px',
  $spacing4: '16px',
  $spacing5: '20px',
  $spacing6: '24px',
  $spacing7: '32px',
  $spacing8: '40px',
  $spacing9: '48px',
  $spacing10: '56px',
  $spacing11: '80px',

  /** Burger-King-only primitives */
  bk: {
    // colors
    $bbqBrown: 'rgb(80,35,20)',
    $mayoEggWhite: 'rgb(245,235,220)',
    $fieryRed: 'rgb(214,35,0)',
    $crunchyGreen: 'rgb(25,135,55)',
    $flamingOrange: 'rgb(255,135,50)',
    $meltyYellow: 'rgb(255,170,0)',
    $fieryRedOpacity10: 'rgba(214,35,0,0.1)',
    $fieryRedOpacity30: 'rgba(214, 35, 0, 0.3)',
    $fieryRedDarken10: 'rgb(163,27,0)',
    $chkingOrange: '#D8601E',
    $blueWalmart: '#0071DC',
    $blueLight: '#F2F8FD',
    // text styles
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $hero: '32px/36px "Flame", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOne: '24px/24px "Flame", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwo: '18px/20px "Flame", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThree:
      '16px/20px "Flame", "Cooper Black", "Helvetica Neue", Helvetica, Arial, sans-serif',
    $headerFour:
      '13px/16px "Flame", "Cooper Black", "Helvetica Neue", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOne: '15px/20px "Flame Sans", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwo: '12px/16px "Flame Sans", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    $formUtility: '11px/12px "Flame Sans", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',

    // text styles for Desktop
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $heroDesktop: '56px/56px "Flame Bold", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOneDesktop: '30px/32px "Flame", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwoDesktop: '24px/28px "Flame", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThreeDesktop:
      '18px/20px "Flame", "Cooper Black", "Helvetica Neue", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOneDesktop:
      '16px/24px "Flame Sans", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwoDesktop:
      '13px/20px "Flame Sans", "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif',
  },

  /** Popeyes-only primitives */
  plk: {
    // colors
    $nolaDark: 'rgb(31,31,31)',
    $nolaLight: 'rgb(245,241,239)',
    $popeyesOrange: 'rgb(255,125,0)',
    $burntOrange: 'rgb(242,86,0)',
    $nolaTeal: 'rgb(0,166,158)',
    $nolaPurple: 'rgb(145,25,135)',
    $butterYellow: 'rgb(255,200,0)',
    $spicyRed: 'rgb(217,15,6)',
    $burntOrangeOpacity10: 'rgba(242,86,0,0.1)',
    $burntOrangeOpacity30: 'rgba(242,86,0,0.3)',
    $burnOrangeDarken10: 'rgb(191,86,0)',

    // text styles
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $hero: '32px/36px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOne: '24px/28px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwo: '18px/24px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThree: '16px/20px "chicken_sans_bold", sans-serif',
    $headerFour: '13px/16px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOne: '15px/20px "chicken_sans", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwo: '12px/16px "chicken_sans", sans-serif',
    $formUtility: '11px/12px "chicken_sans", sans-serif',

    // text styles for Desktop
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $heroDesktop: '56px/56px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOneDesktop: '30px/32px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwoDesktop: '24px/28px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThreeDesktop: '18px/20px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOneDesktop: '16px/24px "chicken_sans", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwoDesktop: '13px/20px "chicken_sans", sans-serif',
  },

  /** Tim-Horton-only primitives */
  th: {
    // colors
    $coffeeBrown: 'rgb(63,32,33)',
    $alwaysRed: 'rgb(200,16,46)',
    $donutCream: 'rgb(221,203,164)',
    $donutCreamLighten20: 'rgb(249,245,238)',
    $alwaysRedOpacity10: 'rgba(200,16,46,0.1)',
    $alwaysRedOpacity30: 'rgba(200,16,46,0.3)',
    $alwaysRedDarken10: 'rgb(153,12,35)',

    // text styles
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $hero: '32px/36px "sofia_pro_bold", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOne: '24px/28px "sofia_pro_bold", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwo: '18px/24px "sofia_pro_bold", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThree: '16px/20px "sofia_pro_bold", Helvetica, Arial, sans-serif',
    $headerFour: '13px/16px "sofia_pro_bold", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOne: '15px/20px "sofia_pro", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwo: '12px/16px "sofia_pro", Helvetica, Arial, sans-serif',
    $formUtility: '11px/12px "sofia_pro", Helvetica, Arial, sans-serif',

    // text styles for Desktop
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $heroDesktop: '56px/56px "sofia_pro_bold", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOneDesktop: '30px/32px "sofia_pro_bold", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwoDesktop: '24px/28px "sofia_pro_bold", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThreeDesktop: '18px/20px "sofia_pro_bold", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOneDesktop: '16px/24px "sofia_pro", Helvetica, Arial, sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwoDesktop: '13px/20px "sofia_pro", Helvetica, Arial, sans-serif',
  },
  /** Firehouse-Subs-only primitives */
  fhs: {
    // colors
    $houseRed: '#E31837',
    $houseYellow: '#EBA518',
    $houseDark: '#01000B',
    $houseLight: '#F2F2F6',
    $houseYellowLighten20: '#FBEDD1',
    $houseRedDarken10: '#B1080F',
    $houseRedOpacity10: 'rgba(227, 24, 55, 0.1)',

    // text styles
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $hero: '32px/36px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOne: '24px/28px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwo: '18px/24px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThree: '16px/20px "chicken_sans_bold", sans-serif',
    $headerFour: '13px/16px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOne: '15px/20px "chicken_sans", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwo: '12px/16px "chicken_sans", sans-serif',
    $formUtility: '11px/12px "chicken_sans", sans-serif',

    // text styles for Desktop
    /** @deprecated Use the CSS variable for this font instead: var(--font-hero) */
    $heroDesktop: '56px/56px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerOne) */
    $headerOneDesktop: '30px/32px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerTwo) */
    $headerTwoDesktop: '24px/28px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-headerThree) */
    $headerThreeDesktop: '18px/20px "chicken_sans_bold", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyOne) */
    $copyOneDesktop: '16px/24px "chicken_sans", sans-serif',
    /** @deprecated Use the CSS variable for this font instead: var(--font-copyTwo) */
    $copyTwoDesktop: '13px/20px "chicken_sans", sans-serif',
  },
} as const;
/**
 * Style Primitives - USE DIRECTLY IN CSS ONLY WHEN DESIGN TOKENS ARE NOT APPROPRIATE
 */
export const primitive = {
  ...componentsLibraryPrimitives,

  // Add brand-agnostic primitives here (additions must be confirmed with design team)

  /** Burger-King-only primitives */
  bk: {
    ...componentsLibraryPrimitives.bk,

    // Add bk-only primitives here (additions must be confirmed with design team)
  },

  /** Popeyes-only primitives */
  plk: {
    ...componentsLibraryPrimitives.plk,

    // Add plk-only primitives here (additions must be confirmed with design team)
  },

  /** Tim-Horton-only primitives */
  th: {
    ...componentsLibraryPrimitives.th,

    // Add th-only primitives here (additions must be confirmed with design team)
  },

  fhs: {
    ...componentsLibraryPrimitives.fhs,

    // Add fhs-only primitives here (additions must be confirmed with design team)
  },
} as const;
