import { Box } from '@rbilabs/universal-components';

const FORM_SPACING = '$4';

export const StyledSaveCardContainer = Box.withConfig({
  paddingX: FORM_SPACING,
  paddingBottom: '$2',
});

export const StyledBillingCityWrapper = Box.withConfig({
  flexBasis: '60%',
  marginRight: '5%',
  marginBottom: FORM_SPACING,
});

export const StyledBillingStateWrapper = Box.withConfig({
  flexBasis: '35%',
  marginBottom: FORM_SPACING,
});

export const StyledStreetAddressWrapper = Box.withConfig({
  flexBasis: '100%',
  marginBottom: FORM_SPACING,
});

export const StyledBillingAptWrapper = Box.withConfig({
  width: 'full',
  flexGrow: 1,
  marginRight: {
    md: '5%',
  },
  marginBottom: FORM_SPACING,
});

export const StyledBillingZipWrapper = Box.withConfig({
  width: 'full',
  flexGrow: 1,
  marginBottom: FORM_SPACING,
});

export const StyledNameOnCardWrapper = Box.withConfig({
  flexBasis: '100%',
  marginBottom: FORM_SPACING,
});

export const StyledCountrySelectWrapper = Box.withConfig({
  flexBasis: '100%',
  marginBottom: FORM_SPACING,
});

interface IFormContainer {
  withPadding?: boolean;
  withBorder: boolean;
  isHidden?: boolean;
}

export const FormContainer = Box.withConfig<IFormContainer>(p => ({
  flexDirection: 'row',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  backgroundColor: { base: 'transparent', md: p.withBorder ? Styles.color.white : 'transparent' },
  visibility: p.isHidden ? 'hidden' : 'visible',
  padding: { md: p.withPadding ? '$4' : 0 },
  borderWidth: { md: p.withBorder ? '1px' : undefined },
  borderColor: { md: p.withBorder ? Styles.color.grey.four : undefined },
}));
