import React, { useEffect, useMemo } from 'react';

import 'expo-dev-client';

import './polyfill';

import { Platform, View } from 'react-native';

import { AppContainer } from 'components/app-container';
import { EnvPicker } from 'components/env-picker';
import ErrorBoundary from 'components/error-boundary-static';
import ReduceProviders from 'components/reduce-providers';
import { initStatuses, useAppBlockerInit } from 'hooks/use-app-init';
import 'utils/accessibility/text-size';
import 'utils/keyboard/keyboard-config';
import { brand, isLocalDev, platform, shouldShowEnvPickerAtStart } from 'utils/environment';
import { EventName, emitEvent } from 'utils/event-hub';
import LocalStorage from 'utils/local-storage';
import { initializePerformanceMonitors } from 'utils/performance';
import { Measures, PerformanceMarks, logMeasurement, setMark } from 'utils/timing';

import { Router } from './router';
import { IAppProps } from './types';

// Added this to prevent crash on Android
// See https://github.com/andyearnshaw/Intl.js#regexp-cache--restore
// and https://github.com/expo/expo/issues/6536
if (Platform.OS === 'android') {
  if (typeof (Intl as any).__disableRegExpRestore === 'function') {
    (Intl as any).__disableRegExpRestore();
  }
}

// Create start time before trying to render the app for true performance numbers
const LOADING_START_TIME = performance.now?.() ?? 0;

// Log an event to Google Analytics
// TODO: RN - @react-native-firebase/analytics causes iOS to crash
// Fix this before continuing analytics work
// analytics().logEvent('app_loaded', {
//   isReactNative: true,
// });

const CoreApp: React.FC<IAppProps> = ({ onErrorBoundaryRequestReload }) => {
  const blockingStatus = useAppBlockerInit();

  const appIsReady = useMemo(() => blockingStatus !== initStatuses.LOADING, [blockingStatus]);

  // TODO: RN - properly initialize GTM in RN.
  useEffect(() => {
    if (!isLocalDev) {
      const renderSuccessTime = performance.now?.();
      const durationTillRenderSuccess = renderSuccessTime - LOADING_START_TIME;
      (window.dataLayer || []).push({
        event: 'APP_REACT_RENDER_SUCCESS',
        brand: brand(),
        timestamp: renderSuccessTime,
        duration: durationTillRenderSuccess,
        platform: platform(),
      });
    }
  }, []);

  useEffect(() => {
    if (blockingStatus === initStatuses.INITIALIZED) {
      // NOTE: cypress-v2 test suite requirement
      // Avoid localStorage clearing. Cypress does this automatically between tests.
      const shouldClear = !LocalStorage.isCurrentVersion();

      if (shouldClear && !window.Cypress) {
        LocalStorage.clearAll();
        LocalStorage.setCurrentVersion();
      }
    }
  }, [blockingStatus]);

  let AppContent = null;

  switch (blockingStatus) {
    default:
    case initStatuses.LOADING:
      setMark(PerformanceMarks.APP_LOADING_START);
      break;
    // TODO: Create a new error screen for when the app fails to load
    case initStatuses.ERROR:
      AppContent = (
        <Router>
          <ReduceProviders onErrorBoundaryRequestReload={onErrorBoundaryRequestReload}>
            <ErrorBoundary onRequestReload={onErrorBoundaryRequestReload} />
          </ReduceProviders>
        </Router>
      );
      break;
    case initStatuses.INITIALIZED:
      logMeasurement(Measures.APP_LOADING, PerformanceMarks.APP_LOADING_START);
      AppContent = (
        <Router>
          <ReduceProviders onErrorBoundaryRequestReload={onErrorBoundaryRequestReload}>
            <AppContainer />
          </ReduceProviders>
        </Router>
      );
      break;
  }

  if (shouldShowEnvPickerAtStart()) {
    return <EnvPicker />;
  }

  if (!appIsReady) {
    return null;
  }

  return <View style={style}>{AppContent}</View>;
};

const style = { flex: 1, backgroundColor: Styles.color.background };

setMark(PerformanceMarks.APP_RN_TSX_START);

// We need to initialize the performance event handlers before any event is fired.
initializePerformanceMonitors();
emitEvent(EventName.JAVASCRIPT_START);

export default CoreApp;
