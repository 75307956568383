import { ReactNode } from 'react';

import { Badge, Box, Button, HStack, Header, Text, VStack } from '@rbilabs/universal-components';
import { GestureResponderEvent } from 'react-native';

import { BadgeContainer } from './styled';

type IWalmartOfferItem = {
  name: string;
  description: string;
  image: ReactNode;
  activateButtonLabel: string;
  onPartnershipActivate: ((event: GestureResponderEvent) => void) | null | undefined;
  isLoadingUser: boolean;
  isWalmartUser?: boolean;
  linkedButtonLabel: string;
};

export const WalmartOfferItem = ({
  name,
  description,
  image,
  activateButtonLabel,
  onPartnershipActivate,
  isLoadingUser,
  isWalmartUser,
  linkedButtonLabel,
}: IWalmartOfferItem) => (
  <Box bg="token.background-pattern" rounded={10} borderWidth={0} borderColor={'transparent'}>
    <VStack p="$3" pt="$3" borderTopRadius={10} borderBottomRadius={10}>
      <HStack mr="$2">
        {image}
        <VStack flexShrink={1} ml="$3" alignItems="flex-start" justifyContent="center">
          <Header
            variant="headerThree"
            color={'token.text-default'}
            ellipsizeMode="tail"
            numberOfLines={2}
            mb="$1"
          >
            {name}
          </Header>
          {!!description && (
            <Text fontSize={12} lineHeight={16} numberOfLines={2} ellipsizeMode="tail">
              {description}
            </Text>
          )}

          <BadgeContainer>
            {!isWalmartUser && !isLoadingUser && (
              <Button
                size="sm"
                h="$7.5"
                px="$7"
                py="0"
                onPress={onPartnershipActivate}
                variant="outline"
              >
                {activateButtonLabel}
              </Button>
            )}
            {isWalmartUser && !isLoadingUser && (
              <Badge variant="default-subtle" backgroundColor="#D1FAE5" iconName="checkFill">
                {linkedButtonLabel}
              </Badge>
            )}
          </BadgeContainer>
        </VStack>
      </HStack>
    </VStack>
  </Box>
);
