import { useEffect, useState } from 'react';

import { differenceInMinutes } from 'date-fns';
import { IntlFormatters, useIntl } from 'react-intl';

import { DeliveryStatus, RbiOrderStatus, useGetUserOrdersQuery } from 'generated/graphql-gateway';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { appendObjectToQueryString } from 'hooks/navigation/utils';
import { useIOSLiveActivities } from 'hooks/use-ios-live-activities';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { OrderStatus, ServiceMode, useOrderContext } from 'state/order';
import { isOrderTimeInInterval } from 'state/order/utils';
import { deliveryCompleteTimeout } from 'utils/delivery-complete-timeout';
import { getDeliveryHeading } from 'utils/get-delivery-heading';
import { routes } from 'utils/routing';
import { getServiceModeIcon } from 'utils/service-mode';

import { IOrderInProgressRecentOrder, IOrderStatusBarData } from './types';

const DELIVERY_COMPLETED = [
  DeliveryStatus.ORDER_DROPPED_OFF,
  DeliveryStatus.ORDER_ABANDONED,
  DeliveryStatus.ORDER_CANCELLED,
];

const NOTIFICATION_EXPIRATION_IN_MINUTES = 120;
const ORDER_PROGRESS_POLLING_DURATION_IN_MS = 10000;
const DELIVERY_COMPLETED_TIMEOUT_IN_MINUTES = 10;
const PICKUP_UPDATED_TIMEOUT_IN_MINUTES = 10;

const isPollingForDelivery = ({
  hasDelivery,
  isDeliveryOrderCompleted,
  deliveryStatus,
}: {
  hasDelivery: boolean;
  isDeliveryOrderCompleted: boolean;
  deliveryStatus: DeliveryStatus | undefined;
}) =>
  hasDelivery &&
  !isDeliveryOrderCompleted &&
  (!deliveryStatus || !DELIVERY_COMPLETED.includes(deliveryStatus));

const getStatusBarData = (
  serviceMode: ServiceMode,
  formatMessage: IntlFormatters['formatMessage'],
  isOrderBayEnabled: boolean
): IOrderStatusBarData | null => {
  const baseData = {
    icon: getServiceModeIcon(serviceMode),
    cta: { text: formatMessage({ id: 'seeDetails' }) },
  };

  const headingIdMapping = {
    [ServiceMode.DRIVE_THRU]: 'driveThruOrderPlaced',
    [ServiceMode.TAKEOUT]: 'pickUpOrderPlaced',
    [ServiceMode.EAT_IN]: 'dineInOrderPlaced',
    [ServiceMode.DELIVERY]: 'deliveryOrderPlaced',
    // TODO: BKPE-6812 ORDER_BAY_PILOT. Remove or consolidate this logic once the pilot is done
    [ServiceMode.CURBSIDE]: isOrderBayEnabled ? 'orderBayOrderPlaced' : 'curbsideOrderPlaced',
  };

  const headingId = headingIdMapping[serviceMode];

  return headingId
    ? {
        ...baseData,
        heading: formatMessage({ id: headingId }),
      }
    : null;
};

export const useTopOrderStatusBar = (): IOrderStatusBarData => {
  const fireOrderAhead = useFlag(LaunchDarklyFlag.FIRE_ORDER_AHEAD);
  const isOrderBayEnabled = useFlag(LaunchDarklyFlag.ENABLE_ORDER_BAY);
  const isMobileOrderForDriveThruLaneEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_MOBILE_ORDER_DRIVE_THRU_LANE
  );
  const isTopOrderStatusBarEnabled = useFlag(LaunchDarklyFlag.ENABLE_TOP_ORDER_STATUS_BAR);

  const { formatMessage } = useIntl();
  const { linkTo } = useNavigation();
  const { isAuthenticated, loading } = useAuthContext();
  const { curbsidePickupOrderId, isPreConfirmCurbside, serverOrder } = useOrderContext();
  const { updateActivity } = useIOSLiveActivities();
  const [shouldDisplayNotification, setShouldDisplayNotification] = useState<boolean>(true);
  const [currentOrderId, setCurrentOrderId] = useState<string>('');
  const [currentDeliveryStatus, setCurrentDeliveryStatus] = useState<DeliveryStatus>();
  const [queryWasExecuted, setQueryWasExecuted] = useState<boolean>(false);

  // start polling for recent user orders
  const { data, startPolling, stopPolling, refetch } = useGetUserOrdersQuery({
    variables: {
      limit: 1,
      orderStatuses: [
        RbiOrderStatus.INSERT_SUCCESSFUL,
        RbiOrderStatus.UPDATE_SUCCESSFUL,
        RbiOrderStatus.REFUND_SUCCESSFUL,
      ],
    },
    skip: !isAuthenticated,
  });

  useEffect(() => {
    if ((!loading && !isAuthenticated) || (!queryWasExecuted && data)) {
      setQueryWasExecuted(true);
    }
  }, [isAuthenticated, loading, data]);

  // Upon order confirmation, data from `useGetUseOrdersQuery` is not updated with newly placed order
  // Force a refetch to update query with new confirm order instead of previous order
  const isServerOrderSuccessful: boolean = serverOrder?.status === OrderStatus.INSERT_SUCCESSFUL;
  const isCurbsideOrderCompleted =
    serverOrder?.cart.serviceMode === ServiceMode.CURBSIDE && isServerOrderSuccessful;

  const order: IOrderInProgressRecentOrder =
    isCurbsideOrderCompleted && serverOrder
      ? { ...serverOrder, updatedAt: serverOrder?.createdAt || '' }
      : data?.userOrders?.orders?.[0];

  const orderServiceMode = order?.cart.serviceMode;
  const isCurbside = orderServiceMode === ServiceMode.CURBSIDE;
  const statusBarData = getStatusBarData(
    orderServiceMode as ServiceMode,
    formatMessage,
    isOrderBayEnabled
  );

  const isPickupTimedOut = () => {
    // Add the fire order delay to the total timeout
    const fireOrderInSeconds = order?.fireOrderIn ? order?.fireOrderIn + fireOrderAhead : 0;
    const fireOrderInMinutes = fireOrderInSeconds / 60;
    const timeOutWithDelay = PICKUP_UPDATED_TIMEOUT_IN_MINUTES + fireOrderInMinutes;

    return order && !order?.delivery && isOrderTimeInInterval(order?.updatedAt, timeOutWithDelay);
  };

  const isDeliveryOrderCompleted =
    !!order?.delivery &&
    DELIVERY_COMPLETED.includes(order.delivery.status) &&
    deliveryCompleteTimeout(order?.updatedAt, DELIVERY_COMPLETED_TIMEOUT_IN_MINUTES);

  const notificationIsExpired =
    order?.updatedAt &&
    Math.abs(differenceInMinutes(new Date(), new Date(order.updatedAt))) >
      NOTIFICATION_EXPIRATION_IN_MINUTES;

  // Refetch on curbside order completion
  useEffect(() => {
    if (isCurbsideOrderCompleted) {
      refetch();
    }
  }, [isCurbsideOrderCompleted, refetch]);

  // Update notification for new orders being placed
  useEffect(() => {
    const order = data?.userOrders?.orders?.[0];
    if (order && order.rbiOrderId !== currentOrderId) {
      setShouldDisplayNotification(true);
      setCurrentOrderId(order.rbiOrderId);
    }
  }, [currentOrderId, data, isCurbside]);

  // Update notification for delivery status changes
  useEffect(() => {
    const order = data?.userOrders?.orders?.[0];
    if (order?.delivery?.status && order?.delivery.status !== currentDeliveryStatus) {
      setShouldDisplayNotification(true);
      setCurrentDeliveryStatus(order?.delivery?.status);
    }
  }, [currentDeliveryStatus, data]);

  // Reset notification after curbside success
  useEffect(() => {
    // reactivate shouldDisplayNotification after closing first Curbside notification
    if (isServerOrderSuccessful) {
      setShouldDisplayNotification(true);
    }
  }, [isServerOrderSuccessful]);

  if (
    !isTopOrderStatusBarEnabled ||
    !isAuthenticated ||
    !shouldDisplayNotification ||
    !statusBarData
  ) {
    return {
      queryWasExecuted,
      icon: '',
      heading: '',
      cta: {
        text: '',
      },
    };
  }

  if (
    isPollingForDelivery({
      hasDelivery: !!order?.delivery,
      isDeliveryOrderCompleted,
      deliveryStatus: order?.delivery?.status,
    })
  ) {
    startPolling(ORDER_PROGRESS_POLLING_DURATION_IN_MS);
  }

  const orderId = isPreConfirmCurbside() ? curbsidePickupOrderId : currentOrderId;

  // CURBSIDE - Before order is commited on confirming arrival , selecting "I'm here!"
  if (isPreConfirmCurbside()) {
    return {
      ...statusBarData,
      heading: formatMessage({ id: 'curbsideOrderSaved' }),
      cta: {
        action: () =>
          linkTo(
            appendObjectToQueryString(`${routes.orderConfirmation}/${orderId}`, { showToast: true })
          ),
        text: formatMessage({ id: 'tapWhenHere' }),
      },
      queryWasExecuted,
    };
  }

  // CURBSIDE - After order is placed
  if (isCurbside && isPickupTimedOut()) {
    return {
      ...statusBarData,
      heading: formatMessage({
        // TODO: BKPE-6812 ORDER_BAY_PILOT. Remove or consolidate this logic once the pilot is done
        id: isOrderBayEnabled ? 'orderBayOrderPlaced' : 'curbsideOrderPlaced',
      }),
      cta: {
        ...statusBarData.cta,
        action: () => linkTo(`${routes.orderConfirmation}/${orderId}`),
      },
      queryWasExecuted,
    };
  }

  if (notificationIsExpired || order?.status === RbiOrderStatus.REFUND_SUCCESSFUL) {
    return {
      queryWasExecuted,
      icon: '',
      heading: '',
      cta: {
        text: '',
      },
    };
  }

  // PICKUP
  if (order && statusBarData && isPickupTimedOut()) {
    // DRIVE_THRU when Mobile Pickup is Enabled
    if (orderServiceMode === ServiceMode.DRIVE_THRU && isMobileOrderForDriveThruLaneEnabled) {
      statusBarData.heading = formatMessage({ id: 'driveThruMobileOrderPlaced' });
    }
    return {
      ...statusBarData,
      cta: {
        ...statusBarData.cta,
        action: () => linkTo(`${routes.orderConfirmation}/${orderId}`),
      },
      queryWasExecuted,
    };
  }

  // DELIVERY
  if (order?.delivery && !isDeliveryOrderCompleted) {
    const deliveryStatus = order.delivery.status;
    const headingId = getDeliveryHeading({ deliveryStatus });
    if (order.rbiOrderId && deliveryStatus) {
      updateActivity(order, false);
    }
    return {
      ...statusBarData,
      heading: formatMessage({ id: headingId }),
      cta: {
        ...statusBarData.cta,
        action: () => linkTo(`${routes.orderConfirmation}/${orderId}`),
      },
      queryWasExecuted,
    };
  }

  // Stop polling for recent user orders
  stopPolling();
  return {
    queryWasExecuted,
    icon: '',
    heading: '',
    cta: {
      text: '',
    },
  };
};
