import React, { useState } from 'react';

import { useIntl } from 'react-intl';

import { TextInputMask } from 'components/ucl/text-input';
import { loyaltyCrownCardNumberMask } from 'utils/masks';
import noop from 'utils/noop';

type AddGiftCardInputProps = {
  label?: string;
  initialValue?: string;
  onChange?: (value: string) => void;
  errorMessage?: string;
  onFocus?: () => void;
  testID?: string;
};

function AddGiftCardInput({
  initialValue = '',
  label = '',
  onChange = noop,
  onFocus = noop,
  errorMessage = '',
  testID: dataTestId,
}: AddGiftCardInputProps) {
  const [value, setValue] = useState(initialValue);
  const { formatMessage } = useIntl();

  function handleOnChange(changedValue: string) {
    setValue(changedValue);
    onChange(changedValue);
  }

  return (
    <TextInputMask
      aria-label={formatMessage({ id: 'crownCardNumber' })}
      label={label}
      value={value}
      onChangeText={handleOnChange}
      errorMessage={errorMessage}
      onFocus={onFocus}
      autoFocus
      required
      testID={dataTestId}
      keyboardType="numeric"
      mask={loyaltyCrownCardNumberMask}
    />
  );
}

export default AddGiftCardInput;
