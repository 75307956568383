import React from 'react';

import useProviders from '../../providers';

type Props = React.PropsWithChildren<{
  onErrorBoundaryRequestReload: () => void;
}>;

export default function ReduceProviders({ children, onErrorBoundaryRequestReload }: Props) {
  const providers = useProviders({
    onErrorBoundaryRequestReload,
  });

  return (
    <>
      {providers.reduceRight((acc, Comp) => {
        return <Comp>{acc}</Comp>;
      }, children)}
    </>
  );
}
