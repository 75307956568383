import { merge } from 'lodash-es';

import { sharedPrimitives } from '../shared';

const colorTokens = {
  'background-active': '#D62300',
  'background-badge': '#502314',
  'background-badge-rewards': '#502314',
  'background-badge-rewards-locked': '#F5EBDC',
  'background-button-primary-default': '#D62300',
  'background-button-primary-hover': '#A71B00',
  'background-button-primary-pressed': '#8A1600',
  'background-button-secondary-hover': '#D623001A',
  'background-button-secondary-pressed': '#F0CDBB',
  'background-checked': '#502314',
  'background-default': '#F5EBDC',
  'background-footer': '#502314',
  'background-hero-light': '#F5EBDC',
  'background-hero-reversed': '#502314',
  'background-loader-indicator-primary': '#D62300',
  'background-skeleton-loader-cta-start': '#D623004D',
  'background-skeleton-loader-cta-end': '#D623001A',
  'background-switch-track': '#502314',
  'border-color-active': '#D62300',
  'border-color-badge': '#502314',
  'border-color-button-secondary': '#D62300',
  'border-color-checked': '#502314',
  'border-color-focus': '#502314',
  'border-color-hover': '#502314',
  'border-color-offer-applied': '#FF8732',
  'icon-active': '#D62300',
  'icon-button-primary-reversed': '#502314',
  'icon-button-secondary': '#D62300',
  'icon-button-tertiary-default': '#502314',
  'icon-button-tertiary-hover': '#D62300',
  'icon-button-tertiary-pressed': '#D62300',
  'icon-default': '#502314',
  'icon-form-input-action': '#502314',
  'icon-reversed': '#F5EBDC',
  'icon-rewards': '#502314',
  'icon-rewards-locked': '#FFAA00',
  'text-active': '#D62300',
  'text-button-primary-reversed': '#502314',
  'text-button-secondary': '#D62300',
  'text-button-tertiary-default': '#502314',
  'text-button-tertiary-hover': '#D62300',
  'text-default': '#502314',
  'text-hero-light': '#502314',
  'text-link-default': '#502314',
  'text-link-hover': '#D62300',
  'text-link-reversed': '#F5EBDC',
  'text-reversed': '#F5EBDC',
  'text-rewards': '#502314',
  'text-rewards-locked': '#F5EBDC',
};

// @todo update with color from Design
// http://mcg.mbitson.com/#!?plk=%23f25600&bk=%23502314&th=%23c8102e&themename=mcgtheme
// Based on bbqBrown
const primary = {
  50: '#eae5e3',
  100: '#cbbdb9',
  200: '#a8918a',
  300: '#85655b',
  400: '#6a4437',
  500: '#502314',
  600: '#491f12',
  700: '#401a0e',
  800: '#37150b',
  900: '#270c06',
};

// Based on fieryRed
const secondary = {
  50: '#fae5e0',
  100: '#f3bdb3',
  200: '#eb9180',
  300: '#e2654d',
  400: '#dc4426',
  500: '#d62300',
  600: '#d11f00',
  700: '#cc1a00',
  800: '#c61500',
  900: '#bc0c00',
};

// Based on crunchyGreen
const tertiary = {
  50: '#e3f1e7',
  100: '#badbc3',
  200: '#8cc39b',
  300: '#5eab73',
  400: '#3c9955',
  500: '#198737',
  600: '#167f31',
  700: '#12742a',
  800: '#0e6a23',
  900: '#085716',
};
// /@todo

export const bkPrimitives = merge({}, sharedPrimitives, {
  colors: {
    primary,
    secondary,
    tertiary,
    bbqBrown: '#502314',
    mayoEggWhite: 'rgb(245,235,220)',
    fieryRed: '#D62300',
    crunchyGreen: 'rgb(25,135,55)',
    flamingOrange: 'rgb(255,135,50)',
    meltyYellow: 'rgb(255,170,0)',
    fieryRedOpacity10: '#D623001A',
    fieryRedDarken10: '#8A1600',
    token: colorTokens,
  },
  fontConfig: {
    Flame: {
      400: {
        normal: 'Flame-Regular',
        // There is no italic version of this font
        // Setting to the same as normal so that we don't break iOS
        italic: 'Flame-Regular',
      },
      700: {
        normal: 'Flame-Bold',
        // There is no italic version of this font
        // Setting to the same as normal so that we don't break iOS
        italic: 'Flame-Bold',
      },
    },
    'Flame-Bold': {
      // Force bold font regardless of what font-weight is set
      400: {
        normal: 'Flame-Bold',
        // There is no italic version of this font
        // Setting to the same as normal so that we don't break iOS
        italic: 'Flame-Bold',
      },
      700: {
        normal: 'Flame-Bold',
        // There is no italic version of this font
        // Setting to the same as normal so that we don't break iOS
        italic: 'Flame-Bold',
      },
    },
    'Flame-Sans': {
      400: {
        normal: 'Flame-RegularSans',
        // There is no italic version of this font
        // Setting to the same as normal so that we don't break iOS
        italic: 'Flame-RegularSans',
      },
      // We might need to change this in the future, but design has said
      // 1) There is no existing Flame-BoldSans font file
      // 2) They sometimes use Flame-Regular as the bold variant
      700: {
        normal: 'Flame-Regular',
        italic: 'Flame-Regular',
      },
    },
  },
  fonts: {
    heading: 'Flame',
    body: 'Flame-Sans',
    alternative: 'Flame-Bold',
  },
});
