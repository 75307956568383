import { Dispatch, SetStateAction } from 'react';

import { IntlFormatters } from 'react-intl';

import { IAppliedOffer, IOffersFragment } from 'generated/graphql-gateway';
import {
  IncentiveEvaluationMap,
  LoyaltyOfferWithInlineAlertMessage,
} from 'state/loyalty/hooks/types';
import { ICart } from 'state/loyalty/in-restaurant-redemption';
import { LoyaltyOffer } from 'state/loyalty/types';
import { ServiceMode } from 'state/service-mode/types';

export type ReduceOffers = ({
  cmsOffers,
  baseOffers,
  appliedOffers,
  inRestaurantRedemptionCart,
  checkLoyaltyOfferAvailability,
  getIncentiveUnavailableMessage,
  enableSortOffersForServiceMode,
  setOffersFeedbackMap,
  appliedFilters,
  selectedServiceMode,
  formatters,
}: {
  cmsOffers: LoyaltyOffer[];
  personalizedOffers: LoyaltyOffer[];
  baseOffers: IOffersFragment[];
  appliedOffers: IAppliedOffer[];
  inRestaurantRedemptionCart: ICart;
  checkLoyaltyOfferAvailability: (offer: LoyaltyOffer) => boolean;
  getIncentiveUnavailableMessage: (incentive: LoyaltyOffer) => string | undefined;
  enableSortOffersForServiceMode: boolean;
  setOffersFeedbackMap: Dispatch<SetStateAction<IncentiveEvaluationMap | null>>;
  appliedFilters: IOffersFilters;
  selectedServiceMode: ServiceMode | null;
  formatters: IntlFormatters;
}) => LoyaltyOfferWithInlineAlertMessage[][];

export interface ILoyaltyOffersParams {
  offerEngineId?: string;
}

export enum OffersServiceMode {
  PICKUP_ONLY = 'pickUpOnly',
  DELIVERY_ONLY = 'deliveryOnly',
}

export enum OfferFiltersActionType {
  TOGGLE_PICKUP,
  TOGGLE_DELIVERY,
  TOGGLE_INSTORE,
  SELECT_PICKUP,
  SELECT_DELIVERY,
}

export type OfferFilterAction = {
  type: OfferFiltersActionType;
};

export interface IOffersFilters {
  [OffersServiceMode.PICKUP_ONLY]: boolean;
  [OffersServiceMode.DELIVERY_ONLY]: boolean;
  inStoreEnabled: boolean;
}
