import React from 'react';

import LottieView from 'lottie-react-native';

import { StaticImages, useStaticLottieUri } from 'components/picture/static-image';

export const ResetLocationAnimation = ({ onComplete }: { onComplete?: () => void }) => {
  const uri = useStaticLottieUri(StaticImages.DotLoader);

  if (!uri) {
    return null;
  }

  return (
    <LottieView
      testID="reset-location-loading-animation"
      source={{ uri }}
      autoPlay
      onAnimationFinish={() => onComplete?.()}
      style={{ width: 50, height: 50 }}
    />
  );
};
