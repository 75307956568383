import React from 'react';

import { Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { FavoriteIcon } from 'components/favorite-icon';
import { useEnableMapListExperiment } from 'pages/store-locator/use-enable-map-list-experiment';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { StoreCardSeparator } from '../store-card-separator';

import { StoreCardActionsContainer } from './store-card-actions.styled';
import { StoreCardButton } from './store-card-button';

interface StoreCardActionsProps {
  onDiagnosticsClick?: () => void;
  onFavoritesClick?: () => void;
  onInfoPress?: () => void;
  isUpdatingFavorites?: boolean;
  isFavorite?: boolean;
  isActive?: boolean;
  containerStyle?: any;
}

export const StoreCardActions: React.FC<StoreCardActionsProps> = ({
  onDiagnosticsClick,
  onFavoritesClick,
  onInfoPress,
  isUpdatingFavorites,
  isFavorite,
  isActive,
  containerStyle,
}) => {
  const { formatMessage } = useIntl();
  const enableFavorites = useFlag(LaunchDarklyFlag.ENABLE_FAVORITE_STORES);
  const { isAuthenticated } = useAuthContext();
  const enableMapListExperiment = useEnableMapListExperiment();

  return (
    <StoreCardActionsContainer {...containerStyle}>
      {onDiagnosticsClick && (
        <StoreCardButton
          testID="store-favorite-button"
          accessibilityLabel="Open a diagnostic modal for this store"
          onPress={onDiagnosticsClick}
          icon={<Icon variant="settings" />}
        />
      )}
      {onDiagnosticsClick && onFavoritesClick && <StoreCardSeparator />}
      {onInfoPress && isActive && (
        <StoreCardButton
          accessibilityLabel={formatMessage({ id: 'info' })}
          onPress={onInfoPress}
          icon={<Icon size="$5" variant="info" aria-hidden />}
          isDisabled={!isActive}
        />
      )}
      {isAuthenticated && enableFavorites && onFavoritesClick && (
        <StoreCardButton
          accessibilityLabel={
            isFavorite
              ? formatMessage({ id: 'removeFavorite' })
              : formatMessage({ id: 'setRestaurantAsFavorite' })
          }
          onPress={onFavoritesClick}
          icon={<FavoriteIcon loading={isUpdatingFavorites} isFavorite={isFavorite} />}
          isDisabled={isUpdatingFavorites || (enableMapListExperiment && !isActive)}
        />
      )}
    </StoreCardActionsContainer>
  );
};
