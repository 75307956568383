import { useCallback } from 'react';

import { ItemAvailabilityStatus } from 'enums/menu';
import useFeatureMenuAddOn from 'hooks/use-feature-menu-add-on';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMapToFrontendCartEntry } from 'state/order/hooks/use-map-to-frontend-cart-entry';
import { routes } from 'utils/routing';

/**
 *
 * Returns a function to reorder a previous order.
 */
export const useHandleReorder = ({
  addToCart,
  navigate,
  linkTo,
  setPendingReorder,
  setReordering,
  storeHasSelection,
  // TODO: BKPE-1956 - unavailableCartEntries
  setUnavailableCartEntries,
  setReorderedOrderId,
  cartEntries,
}) => {
  const mapBackendToFrontend = useMapToFrontendCartEntry();
  const enableAddExtras = useFlag(LaunchDarklyFlag.ENABLE_ADD_EXTRAS);
  const { sections: addOnSections } = useFeatureMenuAddOn();
  const hasAddons = addOnSections.length > 0;

  return useCallback(
    async (order, url = routes.orders) => {
      if (!order || !storeHasSelection) {
        // if there's no order or no order or store selection, navigate to where was initially intended
        return navigate(url);
      }
      setReordering(true);
      setReorderedOrderId(order.rbiOrderId);
      const { reorderCartEntries } = order.cart;

      const unavailableEntries = [];
      let containsExtras = false;

      for (const entry of reorderCartEntries) {
        // Map backend entry to frontend
        const { cartEntry: frontendEntry, status } = await mapBackendToFrontend(entry, cartEntries);
        // If this is an extra we want to avoid adding it to the cart or unavailable items.
        // Simply mark this order as having an extra and go to the next entry
        if (status === ItemAvailabilityStatus.CART_EXTRA) {
          containsExtras = true;
          continue;
        }
        // Check if the item is available
        if (status === ItemAvailabilityStatus.AVAILABLE) {
          addToCart(frontendEntry);
        } else {
          unavailableEntries.push(frontendEntry);
        }
      }

      setReordering(false);
      setReorderedOrderId(null);
      setPendingReorder(null);

      const hasUnavailableEntries = unavailableEntries.length > 0;

      // this is needed to prioritize the unavailable item modal
      if (hasUnavailableEntries) {
        setUnavailableCartEntries(unavailableEntries);
        navigate(url);
      } else if (enableAddExtras && hasAddons && containsExtras) {
        // If the order had extras go to the extras modal in the cart
        linkTo(routes.cartAddExtras);
      } else {
        navigate(routes.cart);
      }
    },
    [
      addToCart,
      cartEntries,
      enableAddExtras,
      hasAddons,
      linkTo,
      mapBackendToFrontend,
      navigate,
      setPendingReorder,
      setReorderedOrderId,
      setReordering,
      setUnavailableCartEntries,
      storeHasSelection,
    ]
  );
};
