import React from 'react';

import { Badge, Icon, Text, VStack } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { ICartEntry } from '@rbi-ctg/menu';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import Modal, { ModalContent, ModalSize } from 'components/modal';
import Picture from 'components/picture';
import { Props as DialogProps } from 'hooks/use-dialog-modal';
import { selectors, useAppSelector } from 'state/global-state';
import { isOfferType } from 'state/order/utils';
import { useUIContext } from 'state/ui';
import { primitive } from 'styles/constants/primitives';

import {
  Container,
  FooterContainer,
  Header,
  HeaderContainer,
  Item,
  ItemContainer,
  ItemName,
  ModalMessage,
  PointLabel,
  TileImageWrapper,
} from './styled';

export interface IRemoveUnavailableItemsModalProps {
  unavailableCartEntries: ICartEntry[];
  canDismiss?: boolean;
}

export const RemoveUnavailableItemsModal: React.FC<React.PropsWithChildren<
  DialogProps<IRemoveUnavailableItemsModalProps>
>> = ({ onConfirm, onDismiss, unavailableCartEntries, heading, body, canDismiss = true }) => {
  const { formatMessage } = useIntl();
  const { buildImageUrl } = useUIContext();
  const message = body?.length
    ? body
    : formatMessage({ id: 'someItemsAreUnavailableInSelectedStore' });
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);

  const defaultButtons = [
    { label: formatMessage({ id: 'removeItems' }), onClick: onConfirm },
    {
      label: formatMessage({ id: 'keepPreviousStore' }),
      onClick: onDismiss,
      variant: ActionButtonVariants.OUTLINE_V2,
    },
  ];

  const buttons = canDismiss
    ? defaultButtons
    : [{ label: formatMessage({ id: 'ok' }), onClick: onConfirm }];

  return (
    <Modal
      allowsDismiss={false}
      isErrorModal
      mParticleEventData={{
        modalAppearanceEventMessage: 'Items in order unavailable at selected store',
        modalMessage: message,
      }}
      onDismiss={onDismiss}
      size={ModalSize.REGULAR}
      backgroundColor={primitive.$white}
      header={
        <HeaderContainer>
          <Header nativeID="modal-heading" testID="modal-heading">
            {heading ? heading : formatMessage({ id: 'itemsUnavailable' })}
          </Header>
          <ModalMessage testID="modal-message">{message}</ModalMessage>
        </HeaderContainer>
      }
      footer={
        <FooterContainer>
          {buttons.map(button => (
            <ActionButton
              key={button.label}
              width={{
                base: 'full',
                lg: '1/2',
              }}
              onPress={button.onClick}
              variant={button.variant}
              marginTop="$4"
            >
              {button.label}
            </ActionButton>
          ))}
        </FooterContainer>
      }
    >
      <ModalContent paddingTop="$4">
        <Container>
          <ItemContainer>
            {unavailableCartEntries.map((cartEntry, index) => {
              const reward = appliedLoyaltyRewards[cartEntry.cartId];
              const isRewardCartEntry = reward?.timesApplied > 0;
              const isOfferCartEntry = isOfferType(cartEntry.type);

              return (
                <Item key={`${cartEntry.cartId}-${index}`}>
                  {cartEntry.image && (
                    <TileImageWrapper>
                      <Picture
                        alt={cartEntry.name}
                        image={cartEntry.image}
                        size={'full'}
                        buildImageUrl={buildImageUrl}
                      />
                    </TileImageWrapper>
                  )}
                  <VStack flex={1}>
                    {isOfferCartEntry && (
                      <Badge
                        marginBottom="$2"
                        width="$12"
                        flexGrow={0}
                        display="inline"
                        variant="default-subtle"
                      >
                        Offer
                      </Badge>
                    )}
                    {isRewardCartEntry && (
                      <PointLabel>
                        <Icon
                          width="$5"
                          height="$5"
                          color={primitive.bk.$meltyYellow}
                          variant="rewards"
                          size="$4"
                        />
                        <Text fontSize="md" bold marginLeft="$1">
                          {reward.pointCost}
                        </Text>
                      </PointLabel>
                    )}
                    <ItemName>{cartEntry.name}</ItemName>
                  </VStack>
                </Item>
              );
            })}
          </ItemContainer>
        </Container>
      </ModalContent>
    </Modal>
  );
};
