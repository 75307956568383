import * as SplashScreen from 'expo-splash-screen';
import registerRootComponent from 'expo/build/launch/registerRootComponent';

import { AppWithFatalHandler } from './App';

// call this immediately per docs
// @see https://docs.expo.dev/versions/latest/sdk/splash-screen/#splashscreenpreventautohideasync
SplashScreen.preventAutoHideAsync();

registerRootComponent(AppWithFatalHandler);
