import React, { useCallback } from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { ICartEntry } from '@rbi-ctg/menu';
import ActionButton, { ActionLink } from 'components/action-button';
import Modal, { ModalContent, ModalSize } from 'components/modal';
import Picture from 'components/picture';
import { useCart } from 'hooks/use-cart';
import { useIsDesktopLargeBreakpoint } from 'hooks/use-media-query';
import { useCartContext } from 'state/cart';
import { actions, useAppDispatch } from 'state/global-state';
import { IncentiveEvaluationMap } from 'state/loyalty/hooks/types';
import { LoyaltyAppliedOffer } from 'state/loyalty/types';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { useUIContext } from 'state/ui';
import { primitive } from 'styles/constants/primitives';
import { routes } from 'utils/routing';

import {
  Container,
  Header,
  Item,
  ItemContainer,
  ItemName,
  ModalMessage,
  TileImageWrapper,
} from './styled';
import theme from './theme';

interface IProps {
  isInCart?: boolean;
  items: ICartEntry[];
  onDismiss: (e: Event) => void;
  unavailableOffers?: IncentiveEvaluationMap;
  appliedOffers?: LoyaltyAppliedOffer[];
}

const OrderItemUnavailable = ({
  isInCart,
  items,
  onDismiss,
  unavailableOffers,
  appliedOffers,
}: IProps) => {
  const { formatMessage } = useIntl();
  const { resetStore } = useStoreContext();
  const { buildImageUrl } = useUIContext();
  const isDesktop = useIsDesktopLargeBreakpoint();
  const useClosestContext = isInCart ? useCartContext : useOrderContext;

  // TODO: BKPE-1956 - unavailableCartEntries
  const { setUnavailableCartEntries } = useClosestContext();
  const { emptyCart, removeFromCart, removeAllFromCart } = useCart();
  const dispatch = useAppDispatch();

  const handleRemoveUnavailableItems = useCallback(
    (e: any) => {
      removeAllFromCart(items);
      setUnavailableCartEntries([]);
      // Checking if there are applied offers to remove
      appliedOffers?.forEach((item: LoyaltyAppliedOffer) => {
        const idOfferToRemove = item?.id || '';
        if (unavailableOffers && idOfferToRemove in unavailableOffers) {
          dispatch(actions.loyalty.removeAppliedOffer(item));
          removeFromCart({ cartId: item?.cartId || '' });
        }
      });
      onDismiss(e);
    },
    [
      removeAllFromCart,
      items,
      setUnavailableCartEntries,
      appliedOffers,
      onDismiss,
      unavailableOffers,
      dispatch,
      removeFromCart,
    ]
  );

  const handleStartOver = useCallback(
    (e: any) => {
      setUnavailableCartEntries([]);
      emptyCart();
      resetStore();
      onDismiss(e);
    },
    [onDismiss, emptyCart, resetStore, setUnavailableCartEntries]
  );

  const modalHeading = formatMessage({ id: 'itemsUnavailable' });
  const modalBody = formatMessage({ id: 'someItemsAreUnavailableMessage' });

  return (
    <Modal
      allowsDismiss={false}
      onDismiss={handleStartOver}
      size={ModalSize.FULLSCREEN}
      isErrorModal
      // @ts-expect-error TS(2339) FIXME: Property 'modalBackgroundColor' does not exist on ... Remove this comment to see the full error message
      backgroundColor={isDesktop ? primitive.$white : theme.modalBackgroundColor}
      mParticleEventData={{
        modalAppearanceEventMessage: 'Items are unavailable',
        modalMessage: modalBody,
      }}
      header={
        <Header nativeID="modal-heading" testID="heading" borderBottomWidth={0}>
          {modalHeading}
        </Header>
      }
    >
      <ModalContent>
        <Container>
          <ModalMessage testID="body">{modalBody}</ModalMessage>
          <ItemContainer>
            {items.map((item, index) => (
              <Item key={`${item.cartId}-${index}`}>
                {item.image && (
                  <TileImageWrapper>
                    <Picture
                      alt=""
                      image={item.image}
                      size={'full'}
                      buildImageUrl={buildImageUrl}
                    />
                  </TileImageWrapper>
                )}
                <ItemName>{item.name}</ItemName>
              </Item>
            ))}
          </ItemContainer>
          <Box alignItems={'center'} flexDirection={'column'}>
            <ActionButton
              width={{
                base: 'full',
                lg: '1/2',
              }}
              onPress={handleRemoveUnavailableItems}
            >
              {formatMessage({ id: 'removeItems' })}
            </ActionButton>
            <ActionLink
              width={{
                base: 'full',
                lg: '1/2',
              }}
              variant={theme.emptyCartBtnVariant}
              to={routes.menu}
              onPress={handleStartOver}
              marginTop="$4"
            >
              {formatMessage({ id: 'emptyCart' })}
            </ActionLink>
          </Box>
        </Container>
      </ModalContent>
    </Modal>
  );
};

export default OrderItemUnavailable;
