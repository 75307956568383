import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { defaultTo, isEqualWith, isObject } from 'lodash-es';
import { useIntl } from 'react-intl';
import { isFalse } from 'utils';

import { IBaseProps } from '@rbi-ctg/frontend';
import { IRestaurant } from '@rbi-ctg/store';
import Dialog from 'components/dialog';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import useDialogModal from 'hooks/use-dialog-modal';
import useEffectOnce from 'hooks/use-effect-once';
import { useServiceModeStatusGenerator } from 'hooks/use-service-mode-status';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useRestaurantPosConnectivityStatus } from 'state/restaurant-connectivity-status';
import { useServiceModeContext } from 'state/service-mode';
import { splashScreenHiddenDeferred } from 'utils/hide-splash-screen';
import {
  isMobileOrderingAvailable,
  isRestaurantOpen,
  mergeRestaurantData,
  useGetRestaurantFn,
} from 'utils/restaurant';
import { routes } from 'utils/routing';
import { getLocalizedServiceModeCategory, isDelivery } from 'utils/service-mode';
import SessionStorage, { SessionStorageKeys } from 'utils/session-storage';
import { useMemoAll } from 'utils/use-memo-all';

import {
  ISelectStoreOptions,
  IStoreContext,
  OnConfirmStoreChangeParams,
  StorePermissions,
  createStoreProxyFromStore,
  curriedGetStoreStatusV1,
  useStore,
} from './hooks';
import { useStoreUtils } from './hooks/use-store-utils';

export * from './hooks/types';

// FIXME: temporal export to fix modal inside modal error, will be fixed when we make the refactor for store locator
export type OrderingUnavailable =
  | {
      bodyOverride?: string;
      headingOverride?: string;
    }
  | boolean;

export const StoreContext = React.createContext<IStoreContext>({} as IStoreContext);
export const useStoreContext = () => useContext(StoreContext);

export const StoreProvider = ({ children }: IBaseProps) => {
  // LD Flags
  const enableOrdering = defaultTo(useFlag(LaunchDarklyFlag.ENABLE_ORDERING), true);

  const getRestaurant = useGetRestaurantFn();
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const { pathname, params } = useRoute<{ 'store-number': string | null }>();
  const { serviceMode } = useServiceModeContext();
  const [orderingUnavailableOpen, _setOrderingUnavailableOpen] = useState<OrderingUnavailable>(
    false
  );

  const setOrderingUnavailableOpen = useCallback((arg: OrderingUnavailable) => {
    // there is a bug on iOS if you display a modal before splash screen is gone you can't get off of the splash screen
    splashScreenHiddenDeferred.promise.then(() => _setOrderingUnavailableOpen(arg));
  }, []);

  const restaurantsConfig = useConfigValue({ key: 'restaurants', defaultValue: {} });
  const validMobileOrderingEnvs = restaurantsConfig.validMobileOrderingEnvs ?? [];

  const [
    onConfirmStoreChangeParams,
    setOnConfirmStoreChangeParams,
  ] = useState<OnConfirmStoreChangeParams | null>(null);
  const [isSelectingStore, setIsSelectingStore] = useState(false);

  const clearOnConfirmStoreChangeParams = useCallback(() => {
    setOnConfirmStoreChangeParams(null);
  }, []);

  const {
    resetStore,
    setStore,
    store,
    storeProxy,
    selectUnavailableStore,
    onConfirmStoreChange,
  } = useStore();

  const curriedGetStoreStatus = curriedGetStoreStatusV1;

  const {
    fetchStore,
    email,
    isRestaurantAvailable,
    prices,
    isPosDataLoading,
    serviceModeStatus,
    refetchPosData,
    resetLastTimeStoreUpdated,
  } = useStoreUtils({
    resetStore,
    store: storeProxy,
  });

  const { generateServiceModeStatusForStore } = useServiceModeStatusGenerator();

  const getStoreStatusFlags = useCallback<IStoreContext['getStoreStatusFlags']>(
    (storeToCheck, selectedServiceMode) => {
      if (!storeToCheck) {
        return {
          isStoreOpenAndAvailable: false,
          isStoreOpenAndUnavailable: false,
          isStoreClosed: false,
          noStoreSelected: true,
        };
      }
      const serviceModeStatuses = generateServiceModeStatusForStore(
        createStoreProxyFromStore(storeToCheck)
      );
      const status = curriedGetStoreStatus({
        store: storeToCheck,
        serviceModeStatuses,
        selectedServiceMode,
      })();

      return {
        isStoreOpenAndAvailable: status === StorePermissions.OPEN_AND_AVAILABLE,
        isStoreOpenAndUnavailable: status === StorePermissions.OPEN_AND_UNAVAILABLE,
        isStoreClosed: status === StorePermissions.CLOSED,
        noStoreSelected: status === StorePermissions.NO_STORE_SELECTED,
      };
    },
    [generateServiceModeStatusForStore, curriedGetStoreStatus]
  );

  const {
    isStoreOpenAndAvailable,
    isStoreOpenAndUnavailable,
    isStoreClosed,
    noStoreSelected,
  } = useMemo(() => getStoreStatusFlags(store, serviceMode), [
    getStoreStatusFlags,
    store,
    serviceMode,
  ]);

  // If flags are enabled, refresh restaurant availability & poll for the selected store's availability to notify the user when the store becomes unavailable.

  const { isRestaurantPosOnline } = useRestaurantPosConnectivityStatus({
    storeId: storeProxy?.number ?? null,
  });

  const onConfirmLocateRestaurants = useCallback(() => {
    setOrderingUnavailableOpen(false);
    resetStore();

    if (pathname !== routes.storeLocator) {
      navigate(routes.storeLocator);
    }
  }, [navigate, pathname, resetStore, setOrderingUnavailableOpen]);

  const fetchAndSetStore = useCallback(
    async (storeId: string, hasSelection = true) => {
      setIsSelectingStore(true);
      try {
        const fetchedStore = await fetchStore(storeId);

        if (fetchedStore) {
          let formattedStore: IRestaurant = { ...fetchedStore };

          // add `hasSelection` if in store selection 1.0
          if (hasSelection) {
            formattedStore = { ...fetchedStore, hasSelection };
          }
          setStore({ ...formattedStore });
          setIsSelectingStore(false);
        }
      } catch {
        setIsSelectingStore(false);
      }
    },
    [fetchStore, setStore]
  );

  const [StoreChangeDialog, openStoreChangeDialog] = useDialogModal({
    onConfirm: onConfirmStoreChange,
    modalAppearanceEventMessage: 'Confirmation: Store change',
    showCancel: true,
  });

  const isAlphaBetaStoreOrderingEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_ALPHA_BETA_STORE_ORDERING
  );
  // ENABLE_DELIVERY_CHECKOUT_OUTSIDE_OPENING_HOURS
  const enableDeliveryOutsideOpeningHours = useFlag(
    LaunchDarklyFlag.ENABLE_DELIVERY_CHECKOUT_OUTSIDE_OPENING_HOURS
  );

  const selectStoreDialog = useCallback(
    async ({
      sanityStore,
      hasCartItems,
      callback,
      requestedServiceMode,
      unavailableCartEntries,
      skipRedirection = false,
    }: ISelectStoreOptions) => {
      // we do not care about POS if ordering is not enabled
      const rbiRestaurant = await getRestaurant(sanityStore.number ?? null);
      const isRestaurantPosAvailable = rbiRestaurant?.available;

      // Operating hours now come from our gql layer so we must merge the results
      // with the sanity store.
      // Default to the passed in sanity store if we cannot get a restaurant from
      // the gql layer.
      const newStore = rbiRestaurant
        ? mergeRestaurantData({ rbiRestaurant: rbiRestaurant as IRestaurant, sanityStore })
        : sanityStore;

      const selectedServiceMode = requestedServiceMode ?? serviceMode;
      const open =
        (isDelivery(selectedServiceMode) &&
          (isRestaurantOpen(newStore.deliveryHours) || enableDeliveryOutsideOpeningHours)) ||
        isRestaurantOpen(newStore.diningRoomHours) ||
        isRestaurantOpen(newStore.driveThruHours);

      // ensure store did not close between render and selection
      if (!open) {
        return setOrderingUnavailableOpen(true);
      }

      if (
        enableOrdering &&
        // Heartbeat is false
        (!isRestaurantPosAvailable ||
          // store has no mobile ordering available
          !isMobileOrderingAvailable(
            newStore as IRestaurant,
            isAlphaBetaStoreOrderingEnabled,
            validMobileOrderingEnvs
          ))
      ) {
        return setOrderingUnavailableOpen({
          bodyOverride: formatMessage({
            id: 'storeNoOrderingBody',
          }),
          headingOverride: formatMessage({
            id: 'storeNoOrderingHeading',
          }),
        });
      }

      if (isStoreOpenAndUnavailable) {
        return onConfirmStoreChange({ newStore, callback });
      }

      if (
        isEqualWith(storeProxy.physicalAddress, newStore.physicalAddress) &&
        requestedServiceMode === serviceMode
      ) {
        return callback();
      }

      if (!hasCartItems) {
        return onConfirmStoreChange({
          newStore,
          callback,
        });
      }
      if (unavailableCartEntries?.length) {
        // The StoreLocator should open a dialog when this state is set
        setOnConfirmStoreChangeParams({
          callback,
          newStore,
          unavailableCartEntries,
        });

        if (skipRedirection) {
          return;
        }

        // force closing delivery modal to pop item unavailable dialog
        return navigate(routes.storeLocator);
      }

      const hasShownStoreChangeDialog = SessionStorage.getItem(
        SessionStorageKeys.HAS_SHOWN_STORE_CHANGE_DIALOG
      );

      if (hasShownStoreChangeDialog) {
        return onConfirmStoreChange({
          newStore,
          callback,
        });
      }

      let bodyMessageId = 'changeStoreSelectedOfferAndItemDisclaimer';
      if (!hasShownStoreChangeDialog) {
        bodyMessageId = 'changeStoreMessage';
      }

      const changeStoreMessage = formatMessage(
        { id: bodyMessageId },
        {
          serviceMode:
            selectedServiceMode &&
            getLocalizedServiceModeCategory(selectedServiceMode, formatMessage),
        }
      );

      openStoreChangeDialog({
        newStore,
        callback,
        message: changeStoreMessage,
      });
      SessionStorage.setItem(SessionStorageKeys.HAS_SHOWN_STORE_CHANGE_DIALOG, true);
    },
    [
      enableDeliveryOutsideOpeningHours,
      enableOrdering,
      formatMessage,
      getRestaurant,
      isAlphaBetaStoreOrderingEnabled,
      isStoreOpenAndUnavailable,
      navigate,
      onConfirmStoreChange,
      setOrderingUnavailableOpen,
      openStoreChangeDialog,
      serviceMode,
      storeProxy.physicalAddress,
      validMobileOrderingEnvs,
    ]
  );

  const storeNumber = params['store-number'];
  useEffect(() => {
    if (storeNumber && storeNumber !== store?.number) {
      void fetchAndSetStore(String(storeNumber));
    }
  }, [fetchAndSetStore, store, storeNumber]);

  useEffectOnce(() => {
    // Fetch store on initial render if a store is selected
    if (!storeNumber && store && 'number' in store && store.number !== null) {
      const storeHasSelection = !!store && 'hasSelection' in store && store.hasSelection;
      fetchAndSetStore(store.number ?? '', storeHasSelection);
    }
  });

  useEffect(() => {
    // We need to explicitly check for false since a restaurant's availability always begins as null when refreshing/logging out.
    if (!noStoreSelected && isStoreOpenAndAvailable && isFalse(isRestaurantPosOnline)) {
      setOrderingUnavailableOpen(true);
    }
  }, [isRestaurantPosOnline, isStoreOpenAndAvailable, noStoreSelected, setOrderingUnavailableOpen]);

  const onDismissStoreUnavailable = useCallback(() => {
    setOrderingUnavailableOpen(false);
    resetStore();
  }, [resetStore, setOrderingUnavailableOpen]);

  const value = useMemoAll({
    email,
    isRestaurantAvailable,
    openOrderingUnavailableDialog: () => setOrderingUnavailableOpen(true),
    // FIXME: we need to export these 3 lines to make a temporal hack to solve modal inside modal not showed error on IOS
    orderingUnavailableOpen,
    onConfirmLocateRestaurants,
    onDismissStoreUnavailable,
    prices,
    isPosDataLoading,
    refetchPosData,
    selectStore: selectStoreDialog,
    fetchStore: fetchAndSetStore,
    selectUnavailableStore,
    serviceModeStatus,
    setStore,
    resetStore,
    resetLastTimeStoreUpdated,
    getStoreStatusFlags,
    isStoreOpenAndAvailable,
    isStoreOpenAndUnavailable,
    isStoreClosed,
    noStoreSelected,
    store: storeProxy,
    onConfirmStoreChange,
    onConfirmStoreChangeParams,
    clearOnConfirmStoreChangeParams,
    isSelectingStore,
    StoreChangeDialog,
  });

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ email: st... Remove this comment to see the full error message
    <StoreContext.Provider value={value}>
      {children}
      {orderingUnavailableOpen && (
        <Dialog
          showDialog
          heading={
            isObject(orderingUnavailableOpen) && orderingUnavailableOpen.headingOverride
              ? orderingUnavailableOpen.headingOverride
              : formatMessage({ id: 'storeClosed' })
          }
          body={
            isObject(orderingUnavailableOpen) && orderingUnavailableOpen?.bodyOverride
              ? orderingUnavailableOpen?.bodyOverride
              : formatMessage({
                  id: enableOrdering ? 'cannotPlaceOrder' : 'closeMessageToFindNearbyRestaurants',
                })
          }
          confirmButtonLabel={formatMessage({ id: 'findRestaurants' })}
          confirmDialog={onConfirmLocateRestaurants}
          onDismiss={onDismissStoreUnavailable}
          modalAppearanceEventMessage="Ordering is unavailable"
          isErrorModal
        />
      )}
    </StoreContext.Provider>
  );
};

export default StoreContext.Consumer;
