import { CartPaymentType } from 'generated/graphql-gateway';

export const isApplePay = (accountIdentifier?: string | null) =>
  accountIdentifier === CartPaymentType.APPLE_PAY;

export const isGooglePay = (accountIdentifier?: string | null) =>
  accountIdentifier === CartPaymentType.GOOGLE_PAY;

export const isPayPal = (accountIdentifier?: string | null) =>
  accountIdentifier === CartPaymentType.PAYPAL;

export const isCash = (accountIdentifier?: string | null) =>
  accountIdentifier === CartPaymentType.CASH;

export const isVenmo = (accountIdentifier?: string | null) =>
  accountIdentifier === CartPaymentType.VENMO;
