import React, { PropsWithChildren } from 'react';

import { ScrollView, ScrollViewProps } from 'react-native';
import {
  KeyboardAwareScrollView,
  KeyboardAvoidingView as NCKeyboardAvoidingView,
} from 'react-native-keyboard-controller';

import { useIsDesktopBreakpoint } from 'hooks/use-media-query';

interface KeyboardAwareViewProps extends ScrollViewProps {
  /**
   * Enables scroll behaviour while the fields are focused.
   * It is recomended to set `withScroll` to `true` if the view being wrapped
   * exceeds the screen vertical height
   *
   *
   * @type {boolean}
   * @memberof KeyboardAwareViewProps
   */
  withScroll?: boolean;
}

export const KeyboardAwareView: React.FC<
  KeyboardAwareViewProps & PropsWithChildren & React.RefAttributes<ScrollView>
> = ({ children, withScroll = false, showsVerticalScrollIndicator, ...rest }) => {
  const isDesktop = useIsDesktopBreakpoint();

  if (withScroll) {
    return (
      <KeyboardAwareScrollView
        showsVerticalScrollIndicator={showsVerticalScrollIndicator ?? isDesktop}
        {...rest}
      >
        {children}
      </KeyboardAwareScrollView>
    );
  }

  return (
    <NCKeyboardAvoidingView behavior="padding" style={{ flex: 1 }}>
      {children}
    </NCKeyboardAvoidingView>
  );
};
