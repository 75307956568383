import { Box, Icon, View, makeUclComponent } from '@rbilabs/universal-components';

import ActionButton from 'components/action-button';
import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

export const StyledLogo = View.withConfig({
  height: '50px',
  width: '50px',
  marginX: 'auto',
  padding: '$1.5',
});

export const StyledBox = Box.withConfig({
  padding: '$4',
});

export const StyledActionButton = makeUclComponent(ActionButton).withConfig({
  width: 'full',
  alignSelf: 'center',
  marginTop: '$4',
});

export const StyledBackIcon = Icon.withConfig({
  color: theme.token('icon-header-contrast'),
  size: '5',
  ...hiddenAccessibilityPlatformProps,
});

export const LogoContainer = Box.withConfig({
  marginRight: '$0.5',
  justifyContent: 'center',
});

export const BadgeContainer = Box.withConfig({
  height: '$10',
  paddingTop: '$3',
  justifyContent: 'flex-end',
});
