import 'react-native-get-random-values';
import './why-did-you-render';
import 'styles';

import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';

import { Event } from '@amplitude/analytics-types';
import { StatusBar } from 'expo-status-bar';
import { reloadAsync, updateId } from 'expo-updates';
import { Alert, LogBox, Text, View } from 'react-native';
import { v4 as uuidv4 } from 'uuid';

import { SplashScreen } from 'components/splash-screen/splash-screen';
import { appVersionWithBuildNumber } from 'utils/app-version-info';
import Cognito from 'utils/cognito/storage';
import crashlytics from 'utils/crashlytics';
import { initDatadog } from 'utils/datadog';
import { getDeviceId } from 'utils/device-id';
import { isWeb } from 'utils/environment';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import logger from 'utils/logger';

import CoreApp from './core-app';
import bindExceptionHandler from './exception-handler';
import { ExceptionHandler, GtmEvent, GtmEventBase } from './types';

// TODO figure out why this is needed for react native web to not fail in other files and fix it
Cognito.getItem('FAKE');

// TODO fix these as we will get better RUM stats if we do
LogBox.ignoreLogs([
  'DATADOG: An action event was dropped because either the `onPress` method arguments were undefined or they were missing the target information.',
  'DATADOG: A navigation change was detected but the RUM ViewEvent was dropped as the route was undefined.',
  /SerializableStateInvariantMiddleware took.*/,
]);

// this must be done immediately at app start to avoid missing logs
// we can't have the app failing to start if logging fails to init somehow.
try {
  try {
    initDatadog();
  } catch (error) {
    crashlytics().recordError(new Error(`Failed to init datadog logging! ${error?.toString()}`));
  }
} catch (error) {}

declare global {
  interface Window {
    dataLayer: (GtmEvent | (Event & GtmEventBase))[];
    LOADING_START_TIME: number;
    /**
     * window.Cypress exists when our app is running within Cypress
     * Places we currently check for this:
     * - src/state/graphql/links/index.tsx - avoid BatchHttpLink
     *
     * https://docs.cypress.io/faq/questions/using-cypress-faq.html#Is-there-any-way-to-detect-if-my-app-is-running-under-Cypress
     */
    Cypress?: any;
    // When run with cypress for cypress-v2 package,
    // we instantiate the LD client with predefined flags
    _initial_cypress_feature_flags?: object;
    // Skip the interval for checking for unavailable items.
    // Avoids race condition in cart with recorded tests
    _skipUnavailableItemsIntervalCheck?: boolean;
  }
}

// put keys unlikely to be the cause of the crash here
const DO_NOT_CLEAR_ON_CRASH_KEYS = [
  StorageKeys.LANGUAGE,
  StorageKeys.REGION,
  StorageKeys.USER_AUTH_TOKEN,
  StorageKeys.USER,
  StorageKeys.INTERNAL_BUILD_ONLY_ENV_PICKER_CONFIG, // in dev web this getting cleared prematruely due to forter.js issues causing an error boundary hit
];

// set to a time that seems like something wrong with storage could be the culprit (but user WILL lose some state)
// but if they come back a day later and crash maybe it's something else
const ERROR_BOUNDARY_HIT_AGAIN_CLEAR_STORAGE_MS = 1000 * 60 * 4;

const exceptionHandler: ExceptionHandler = (error, isFatal) => {
  // eliminate situation where app could error sooner than splash screen goes away
  SplashScreen.hide();

  const errorUuid = uuidv4();
  if (isFatal) {
    if (!__DEV__) {
      const lastErrorBoundaryCrash = LocalStorage.getItem(StorageKeys.LAST_ERROR_BOUNDARY_CRASH);
      LocalStorage.setItem(StorageKeys.LAST_ERROR_BOUNDARY_CRASH, Date.now());

      Alert.alert(
        'Something Went Wrong',
        'Please reload the app.',
        [
          {
            text: 'Reload',
            onPress: () => {
              if (
                lastErrorBoundaryCrash &&
                Date.now() - lastErrorBoundaryCrash < ERROR_BOUNDARY_HIT_AGAIN_CLEAR_STORAGE_MS
              ) {
                LocalStorage.clear({ excludeKeys: DO_NOT_CLEAR_ON_CRASH_KEYS });
                // TODO consider -  more extreme clear all storages we have with the user upon multiple repeat visits here (to avoid a need to reinstall app)
                // I'm not sure if it would hit here much if ever though...
              }

              // this way works to reload the app, replace with better way if discovered...
              isWeb ? window?.location?.reload() : reloadAsync();
            },
            style: 'default',
          },
        ],
        {
          cancelable: true,
          onDismiss: () => {},
        }
      );
      crashlytics().setAttribute('otaVersion', appVersionWithBuildNumber);
      crashlytics().setAttribute('updateId', updateId ?? 'UNKNOWN');
      crashlytics().setAttribute('errorUuid', errorUuid);
      crashlytics().recordError(error);
      crashlytics().sendUnsentReports();
    }
    const message = `ErrorBoundaryFatalError: ${error.message}`;
    logger.fatal({ error: { ...error, message }, errorUuid, isErrorBoundaryFatalError: true });
  } else {
    // Using warn, since non-fatal errors will be caught by the error boundary
    const message = `ErrorBoundaryNonFatalError: ${error.message}`;
    logger.warn({ error: { ...error, message }, errorUuid, isErrorBoundaryNonFatalError: true });
  }
};

bindExceptionHandler(exceptionHandler);

// not all crashes get handled by above so lets set here too to increase our likely-hood of getting the info
try {
  crashlytics().setAttribute('otaVersion', appVersionWithBuildNumber);
  crashlytics().setAttribute('updateId', updateId ?? 'UNKNOWN');
  getDeviceId().then(deviceId => crashlytics().setAttribute('deviceId', deviceId));
} catch (error) {
  logger.error({ error, message: 'Failed to set crashlytics attributes on app start' });
}

class FatalBootHandler extends React.Component<PropsWithChildren> {
  state = {
    didCrash: false,
    error: new Error(),
  };

  static getDerivedStateFromError(error: Error) {
    return { didCrash: true, error };
  }

  componentDidCatch(error: Error) {
    // eliminate situation where app could error sooner than splash screen goes away
    SplashScreen.hide();

    crashlytics().recordError(error);
    crashlytics().sendUnsentReports();
  }

  render() {
    return this.state.didCrash ? (
      <View
        style={{
          flex: 1,
          marginTop: '20%',
          marginLeft: 8,
          marginRight: 8,
          backgroundColor: Styles.color.background,
        }}
      >
        <Text style={{ textAlign: 'center', marginBottom: 16 }}>FATAL CRASH</Text>
        <Text style={{ fontWeight: 'bold', marginBottom: 16 }}>
          {this.state.error.name}: {this.state.error.message}
        </Text>
      </View>
    ) : (
      this.props.children
    );
  }
}

export const AppWithFatalHandler: React.FC = () => {
  const reloadCount = useRef(0);
  const [forcingReload, setForceReload] = useState(false);

  // If the app crashes and the error boundary is caught
  // our best attempt at recovering is simply to unmount the entire application
  // and remount it. This may have other downstream consequences...
  // We currently will try recovering the app three times:
  //
  // 1. The first time we just unmount and remount.
  // 2. If that crashes the app again, we clear local storage items and unmount/remount
  // 3. If taht crashes again, we force a true app crash to demand a full session reset
  const onErrorBoundaryRequestReload = useCallback(() => {
    reloadCount.current = reloadCount.current + 1;
    setForceReload(true);

    if (reloadCount.current > 1) {
      LocalStorage.clear({ excludeKeys: DO_NOT_CLEAR_ON_CRASH_KEYS });
    }

    setTimeout(() => setForceReload(false), 1);
  }, []);

  useEffect(() => {
    logger.info({ message: 'React app started', isAppStart: true });
  }, []);

  if (forcingReload) {
    return null;
  }

  if (reloadCount.current > 2) {
    throw new Error('Intentionally crashing the app. Error recovery failing.');
  }

  return (
    <FatalBootHandler>
      <StatusBar style="auto" translucent />
      <CoreApp onErrorBoundaryRequestReload={onErrorBoundaryRequestReload} />
    </FatalBootHandler>
  );
};
