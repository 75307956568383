import { Icon } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

export const StyledBackIcon = Icon.withConfig({
  color: theme.token('icon-header-contrast'),
  size: '5',
  ...hiddenAccessibilityPlatformProps,
});
